import React, { PureComponent } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { RootState } from '@state/types';
import { getActionErrors, getActionLoaders } from '../../../state/common/selectors';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import Button from './Button';
import { extractActionNameWithoutSuffix } from '../../../state/common/utils';
import { MARK_COMPLETE_ANIMATION_DURATION } from '../../../utils/taskUtils';
import t from '@i18n/t';

type OwnProps = {
  action?: any;
  color?: string;
  textColor?: string;
  onClick?: any;
  disabled?: boolean;
  filledBeforeAction?: boolean;
  filledAfterAction?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  withoutBorder?: boolean;
  text: string | any;
  icon?: string;
  callback?: Function;
  type?: 'button' | 'submit' | 'reset';
  minWidth?: number;
  style?: any;
  buttonRef?: any;
  progressIndicator?: boolean;
  successText?: string;
  successIcon?: string;
  errorText?: string;
  errorIcon?: string;
  loadingText?: string;
  confetti?: boolean;
};

type PropsFromState = {
  loading?: boolean;
  error?: string | boolean;
};

enum ButtonState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

type Props = OwnProps & PropsFromState;
type State = { buttonState: any };

class ButtonWithAnimation extends PureComponent<Props, State> {
  state: State = { buttonState: ButtonState.INITIAL };
  timer: any = undefined;

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { loading, callback, error } = this.props;
    const { buttonState } = this.state;

    if (prevProps.loading && !loading && buttonState !== ButtonState.INITIAL) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.setState({ buttonState: error ? ButtonState.ERROR : ButtonState.SUCCESS });
      this.timer = setTimeout(() => {
        this.setState({ buttonState: ButtonState.INITIAL });
        if (callback && buttonState === ButtonState.SUCCESS) {
          callback();
        }
      }, MARK_COMPLETE_ANIMATION_DURATION);
    }
  }

  getButtonProps = () => {
    const {
      filledAfterAction = false,
      filledBeforeAction = false,
      successText,
      successIcon,
      errorText,
      errorIcon,
      loadingText,
      color = colors.white,
      text,
      icon,
      textColor = colors.white
    } = this.props;
    let buttonLabel = '';
    let buttonIcon = '';
    let iconColor = '';
    let filled = false;
    let buttonColor = '';
    switch (this.state.buttonState) {
      case ButtonState.INITIAL: {
        buttonIcon = icon || '';
        buttonLabel = text;
        buttonColor = color;
        iconColor = textColor;
        filled = filledBeforeAction;
        break;
      }
      case ButtonState.LOADING: {
        buttonLabel = loadingText || t.LOADING;
        iconColor = textColor;
        break;
      }
      case ButtonState.SUCCESS: {
        buttonIcon = successIcon || 'done';
        buttonLabel = successText || t.SUCCESS;
        iconColor = textColor;
        filled = filledAfterAction;
        break;
      }
      case ButtonState.ERROR: {
        buttonIcon = errorIcon || 'clear';
        buttonLabel = errorText || t.ERROR;
        buttonColor = !filledAfterAction ? colors.lightNegative : colors.negative;
        iconColor = !filledAfterAction ? colors.negative : colors.white;
        break;
      }
    }
    return {
      text: buttonLabel,
      color: buttonColor,
      icon: buttonIcon,
      iconColor,
      filled
    };
  };

  render() {
    const {
      textColor,
      color = colors.primaryPurple,
      onClick = () => {},
      className,
      disabled = false,
      size = 'md',
      style,
      withoutBorder,
      type = 'button',
      buttonRef,
      progressIndicator
    } = this.props;

    const { buttonState } = this.state;
    const isInitial = buttonState === ButtonState.INITIAL;
    const isLoading = buttonState === ButtonState.LOADING;

    const buttonProps = this.getButtonProps();

    return (
      <>
        <StyledButton
          style={style}
          buttonRef={buttonRef}
          textColor={textColor}
          filled={buttonProps.filled}
          icon={buttonProps.icon || undefined}
          color={buttonProps.color || color}
          className={className}
          withoutBorder={withoutBorder}
          size={size}
          onClick={() => {
            if (!isInitial) return;
            if (progressIndicator) {
              this.setState({ buttonState: ButtonState.LOADING });
            }
            onClick();
          }}
          disabled={isInitial ? false : disabled}
          type={type}
          iconColor={buttonProps.iconColor}
          renderAdditionalContent={() => <Progress style={{ width: isLoading && progressIndicator ? '100%' : '0' }} />}
        >
          {buttonProps.text}
        </StyledButton>
      </>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  if (!props.action) return {};

  const actionName = extractActionNameWithoutSuffix(props.action.request);
  const loaders = getActionLoaders(state);
  const errors = getActionErrors(state);
  return {
    loading: loaders[actionName],
    error: errors[actionName]
  };
};

export default connect(mapStateToProps, null)(ButtonWithAnimation);

const StyledButton = styled(Button)`
  padding: ${props => (props.size === 'sm' ? '6px 10px' : props.size === 'md' ? '8px 15px' : '15px 30px')} !important;
  min-width: 116px;
  position: relative;
  transition: all 0.4s ease-in-out;
  i {
    margin-right: 5px;
  }
`;

const Progress = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: ${colors.positive};
  opacity: 0.3;
  transition: width 0.4s ease;
`;
