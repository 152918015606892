import React, { FC, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { FlexRow, Row } from '../layoutUtils';
import { SmallText } from '../../../utils/typography';
import appColors from '../../../utils/colors';
import { editorBgColorPalette, editorTextColorPalette, pickerColors } from '../../../utils/colorUtils';
import styled, { withTheme } from 'styled-components';
import { RootState } from '@state/types';
import { connect } from 'react-redux';
import t from '../../../i18n/t';
import { commitmentApi } from '../../../api';
import { getId } from '../../../state/semester/selectors';

type OwnProps = {
  theme: any;
  selectedColor: string;
  additionalColors?: string[];
  defaultColor?: string;
  defaultSelected?: boolean;
  className?: string;
  onSave?: (e: unknown, isDefault?: boolean) => void;
  onChange?: (color?: string) => any;
  isEditor?: boolean;
  isBgColorPalette?: boolean;
};

type PropsFromState = {
  semesterId: number | undefined;
};

type Props = PropsFromState & OwnProps;

const ColorPicker: FC<Props> = ({
  theme,
  onChange,
  selectedColor,
  defaultColor,
  semesterId,
  className,
  onSave,
  isEditor,
  isBgColorPalette,
  defaultSelected
}) => {
  const getColors = () => {
    const group = paletteGroups.find(group => group.name === activeTab);
    if (group) return group.colors;

    return [];
  };

  const getActiveTab = () => {
    const selectedGroup = paletteGroups.find(g => g.colors.find(c => c === selectedColor.toUpperCase()));
    if (selectedGroup) {
      return selectedGroup.name;
    }

    return t.PASTEL;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab());
  const [activeColor, setActiveColor] = useState(selectedColor);
  const [usedColors, setUsedColors] = useState<string[]>([]);

  useEffect(() => {
    if (activeColor !== selectedColor) {
      setActiveColor(selectedColor);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (semesterId) {
      commitmentApi.getUsedColors(semesterId).then(res => {
        setUsedColors(res.data || []);
      });
    }
  }, [semesterId]);

  const renderPalette = (colors: any) => {
    return (
      <ColorWrapper>
        {colors.map(c => (
          <ColorItem
            key={c}
            size={20}
            color={c}
            borderRadius={4}
            used={isEditor ? false : usedColors.includes(c)}
            selected={activeColor.toUpperCase() === c}
            onClick={() => {
              setActiveColor(c);
              if (onSave) {
                onSave(c, defaultColor ? c === defaultColor : undefined);
              }
            }}
          />
        ))}
      </ColorWrapper>
    );
  };

  const renderEditorPalette = () => {
    const colors = isBgColorPalette ? editorBgColorPalette : editorTextColorPalette;
    return (
      <EditorPaletteWrapper>
        {colors.map(c => (
          <ColorItem
            key={c}
            size={20}
            color={c}
            borderRadius={4}
            used={false}
            selected={activeColor.toUpperCase() === c}
            onClick={() => {
              setActiveColor(c);
              if (onSave) {
                onSave(c);
              }
            }}
            border={c === theme.background}
          />
        ))}
      </EditorPaletteWrapper>
    );
  };

  const renderCustomPicker = () => {
    return (
      <>
        <CustomPickerWrapper>
          <Label color={theme.textColor}>{t.CHOOSE_CUSTOM_COLOR}</Label>
          <CustomPicker
            disableAlpha
            color={activeColor}
            onChange={color => {
              setActiveColor(color.hex);
              onChange && onChange(color.hex);
            }}
          />
        </CustomPickerWrapper>
      </>
    );
  };

  const paletteNames = paletteGroups.map(group => group.name);

  return (
    <Container className={className}>
      <Tabs>
        {paletteNames.map(name => (
          <Label paletteName active={name === activeTab} onClick={() => setActiveTab(name)} key={name}>
            {name}
          </Label>
        ))}
      </Tabs>
      {renderPalette(getColors())}
      {defaultColor && (
        <CustomPickerWrapper>
          <Row style={{ marginBottom: 5 }}>
            <ColorItem
              size={20}
              color={defaultColor}
              borderRadius={4}
              used={false}
              selected={defaultSelected !== undefined ? defaultSelected : activeColor.toUpperCase() === defaultColor}
              onClick={() => {
                setActiveColor(defaultColor);
                if (onSave) {
                  onSave(defaultColor, true);
                }
              }}
            />
            <SmallText style={{ margin: '2px 0 0 5px' }}>{t.DEFAULT}</SmallText>
          </Row>
        </CustomPickerWrapper>
      )}
      {isEditor ? renderEditorPalette() : renderCustomPicker()}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  semesterId: getId(state)
});

export default connect(mapStateToProps, {})(withTheme(ColorPicker));

type ColorItemProps = {
  size: number;
  color: string;
  used: boolean;
  selected: boolean;
  borderRadius?: number;
  border?: boolean;
};

const usedColorMark = `
  &:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #EC132A;
    border-radius: 50%;
    top: -3px;
    right: -3px;
  }
`;

const selectedColorMark = `
  &:before {
  font-family: 'Material Icons';
    font-size: 14px;
    line-height: 20px;
    color: ${appColors.white};
    content: 'check';
  }
`;

export const ColorItem = styled.span<ColorItemProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : '50%')};
  background-color: ${props => props.color};
  cursor: pointer;
  position: relative;
  text-align: center;
  transition: box-shadow 0.2s ease;
  box-sizing: border-box;
  ${props => props.border && `border: 1px solid ${props.theme.backgroundDark}`};

  &:hover {
    box-shadow: 0px 1px 1px ${props => props.theme.boxShadow}, 0px 3px 8px ${props => props.theme.boxShadow},
      0px 3px 3px ${props => props.theme.boxShadow};
  }

  ${props => (props.used ? usedColorMark : '')}

  ${props => (props.selected ? selectedColorMark : '')}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColorWrapper = styled.div`
  // margin: 5px 3px 5px 0px;
  margin: 5px 0px 10px 0px;
  display: grid;
  border-radius: 5px;
  border: 0;

  grid-gap: 5px;
  grid-template-columns: repeat(6, 20px);
`;

const Label = styled(SmallText)<{ active?: boolean; paletteName?: boolean; color?: string }>`
  margin-right: 10px;
  margin-bottom: 4px;
  color: ${props => (props.color ? props.color : props.active ? props.theme.primary : props.theme.textStrongColor)};
  ${props => (props.paletteName ? 'cursor: pointer' : 'margin-top: 10px;')};
`;

const CustomPicker = styled(ChromePicker)`
  width: ${6 * (20 + 5)}px !important;
  margin-top: 8px;
  box-shadow: none !important;
  font-family: 'Quicksand', sans-serif;
  > div:nth-child(2) {
    padding: 16px 0px !important;
    background: ${({ theme }) => theme.background};
  }
`;

const Tabs = styled(FlexRow)``;

const CustomPickerWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.borderColor};
  padding-top: 5px;
  box-sizing: border-box;
`;

const EditorPaletteWrapper = styled(ColorWrapper)`
  border-top: 1px solid ${props => props.theme.borderColor};
  margin: 0px;
  border-radius: 0px;
  padding: 10px 0px 2px 0px;
`;

export const paletteGroups = [
  {
    name: t.PASTEL,
    colors: pickerColors.slice(0, 18)
  },
  {
    name: t.BRIGHT,
    colors: pickerColors.slice(18, 36)
  },
  {
    name: t.DARK,
    colors: pickerColors.slice(36, 54)
  }
];
