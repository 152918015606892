import React, { PureComponent } from 'react';
import StepDialog, { ComponentProps } from '../StepDialog';
import { BadgeStep, QuizQuestion, submitStepAnswer, Substeps } from '../helpers';
import CheckBox from '../../CheckBox';
import styled from 'styled-components';
import t from '@i18n/t';
import { Column } from '../../layoutUtils';
import colors from '@utils/colors';

enum QuizOption {
  TIME_TO_WASTE = 'TIME_TO_WASTE',
  CALCULATE_BETWEEN = 'CALCULATE_BETWEEN',
  TASK_CUSHION_NEGATIVE = 'TASK_CUSHION_NEGATIVE',
  INCREASE_WITH_START_DATE = 'INCREASE_WITH_START_DATE',
  INCREASE_BY_DELETING = 'INCREASE_BY_DELETING'
}

const correctAnswers = [
  QuizOption.TIME_TO_WASTE,
  QuizOption.CALCULATE_BETWEEN,
  QuizOption.INCREASE_WITH_START_DATE,
  QuizOption.INCREASE_BY_DELETING
];

type Props = ComponentProps;

type State = {
  selectedAnswers: QuizOption[];
  submitting: boolean;
};

class CushionStepDialog extends PureComponent<Props, State> {
  readonly state: State = { selectedAnswers: [], submitting: false };

  toggleAnswer = option => {
    const index = this.state.selectedAnswers.indexOf(option);
    const selectedAnswers = [...this.state.selectedAnswers];
    if (index !== -1) {
      selectedAnswers.splice(index, 1);
    } else {
      selectedAnswers.push(option);
    }
    this.setState({ selectedAnswers });
  };

  checkAnswers = () => {
    const { selectedAnswers } = this.state;
    if (selectedAnswers.length !== correctAnswers.length) return false;
    return correctAnswers.every(a => selectedAnswers.includes(a));
  };

  renderDescription = completed => (
    <>
      <Column>
        <QuizQuestion>
          {t.CUSHION_STEP_QUIZ}
          <span style={{ color: colors.positive }}> {completed && `(${t.COMPLETED})`}</span>
        </QuizQuestion>
        {Object.entries(QuizOption).map(([key, value]) => (
          <Option
            key={key}
            label={t[value]}
            checked={completed ? correctAnswers.includes(value) : this.state.selectedAnswers.includes(value)}
            onChange={completed ? undefined : () => this.toggleAnswer(value)}
            breakLine
            disabled={completed}
          />
        ))}
      </Column>
    </>
  );

  submitAnswer = () => {
    const answersCorrect = this.checkAnswers();
    submitStepAnswer(
      Substeps.CUSHION_QUIZ,
      BadgeStep.CUSHION_STEP,
      submitting => this.setState({ submitting }),
      answersCorrect,
      this.props.checkSubstep
    );
  };

  render() {
    const { onClose, completed: stepCompleted, userProgress } = this.props;
    const { submitting } = this.state;
    const quizCompleted = stepCompleted || (userProgress && userProgress.substeps.includes(Substeps.CUSHION_QUIZ));
    return (
      <StepDialog
        {...this.props}
        renderDescription={() => this.renderDescription(quizCompleted)}
        quiz
        quizCompleted={quizCompleted}
        correct={this.checkAnswers()}
        submitting={submitting}
        action={quizCompleted ? onClose : this.submitAnswer}
      />
    );
  }
}

export default CushionStepDialog;

const Option = styled(CheckBox)<{ disabled: boolean; checked: boolean }>`
  > span {
    color: ${props => props.disabled && (props.checked ? props.theme.textStrongColor : props.theme.textColor)};
  }
  margin-bottom: 5px;
`;
