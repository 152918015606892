import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';
import { CalendarEvent } from 'shovel-lib/types';
import { RootState } from '@state/types';
import { SourceEvent, SourceEventType } from './types';
import * as actions from './actions';
import { getType } from 'typesafe-actions';
import unpackEvent from '@utils/unpackEvent';

type EventRange = {
  start?: Moment | Date | string | null;
  end?: Moment | Date | string | null;
};

const getRange = (state: RootState, range: EventRange) => {
  const start = range?.start || state.event.start;
  const end = range?.end || state.event.end;

  return { start: start ? moment(start).toDate() : null, end: end ? moment(end).toDate() : null };
};

const separateAndUnpackHolidays = (sourceEvents: SourceEvent[], start: Date, end: Date) => {
  const { holidays, events } = sourceEvents.reduce(
    ({ holidays, events }, event) =>
      event.type === SourceEventType.HOLIDAY
        ? { holidays: [...holidays, ...unpackEvent(event, [], start, end)], events }
        : { holidays, events: [...events, event] },
    { holidays: [], events: [] } as { holidays: (CalendarEvent | SourceEvent)[]; events: (CalendarEvent | SourceEvent)[] }
  );

  return [holidays, events];
};

type NonStateEvents = {
  nonStateEvents?: SourceEvent[];
};

const getNonStateEvents = (state: RootState, props: NonStateEvents) => props.nonStateEvents;

const unpackEventsInRange = createSelector(
  [(state: RootState) => state.event.sourceEvents, getRange, getNonStateEvents],
  (_sourceEvents = [], { start, end }, nonStateEvents = []) => {
    if (!start || !end) {
      return [];
    }

    const sourceEvents = [..._sourceEvents, ...nonStateEvents];
    const [holidays, events] = separateAndUnpackHolidays(sourceEvents, start, end);

    return [
      ...holidays,
      // @ts-ignore
      ...events.reduce((dtos, event) => [...dtos, ...unpackEvent(event, holidays, start, end)], [])
    ];
  }
);

const getSourceEvents = (state: RootState) => state.event.sourceEvents;

const sourceEventsLoading = (state: RootState) => {
  const sourceEventsType = getType(actions.sourceEvents.request).split('_REQUEST')[0];
  const createEventType = getType(actions.createEvent.request).split('_REQUEST')[0];
  return !!state.common.loaders[sourceEventsType] || !!state.common.loaders[createEventType];
};

export { unpackEventsInRange, sourceEventsLoading, getSourceEvents };
