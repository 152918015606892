import React, { PureComponent } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { connect } from 'react-redux';

import CheckBox from '../CheckBox';
import { RootState } from '@state/types';
import { closeConfirmationDialog } from '../../../state/common/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { getConfirmationDialogState } from '../../../state/common/selectors';
import { LargeText, RegularText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import t from '../../../i18n/t';
import { ConfirmationDialogState } from '../../../state/common/types';
import { commonPopupCSS, confirmationModalStyle } from '../../../utils/popupUtils';
import { Column, ConfirmAndCancelButtons, Row } from '../layoutUtils';
import Button from '../buttons/Button';
import ButtonWithLoader from '../buttons/ButtonWithLoader';

type PropsFromState = ConfirmationDialogState;

type PropsFromDispatch = {
  closeDialog: typeof closeConfirmationDialog;
};

type Props = PropsFromState & PropsFromDispatch;

type State = {
  readonly confirmationEnabled: boolean;
};

class ConfirmationDialog extends PureComponent<Props, State> {
  state: State = {
    confirmationEnabled: true
  };

  toggleConfirmationCheck = () => {
    this.setState(state => {
      return { confirmationEnabled: !state.confirmationEnabled };
    });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.understandCheck !== prevProps.understandCheck) {
      this.setState({ confirmationEnabled: !this.props.understandCheck });
    }
  }

  renderConfirmButton = () => {
    const { renderCustomOkButton, action, closeDialog, warning, okLabel } = this.props;
    if (renderCustomOkButton) {
      return <>{renderCustomOkButton()}</>;
    }

    return (
      <Button
        disabled={!this.state.confirmationEnabled}
        onClick={() => {
          action && action();
          closeDialog();
        }}
        color={warning ? colors.negative : ''}
        withoutBorder={warning}
        filled={!warning}
      >
        {okLabel || (warning ? t.DELETE : t.OK)}
      </Button>
    );
  };

  render() {
    const { opened } = this.props;

    if (!opened) return null;

    const {
      action,
      loadingAction,
      title,
      message,
      understandCheck = false,
      closeDialog,
      okLabel,
      onCancel,
      cancelLabel,
      callback,
      warning = false,
      hideCancel = false,
      additionalAction,
      additionalActionLabel,
      className
    } = this.props;
    return (
      <Wrapper>
        <Popup
          open={opened}
          modal
          onClose={closeDialog}
          contentStyle={confirmationModalStyle}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          className={className}
        >
          <Container className="confirmation-dialog" data-cy={'confirmationDialog'}>
            <Content>
              <Title strong>{title}</Title>
              <Message>{message}</Message>
              <UnderstandCheck>
                {understandCheck && (
                  <CheckBox
                    onChange={this.toggleConfirmationCheck}
                    label={t.I_UNDERSTAND}
                    checked={this.state.confirmationEnabled}
                  />
                )}
              </UnderstandCheck>
            </Content>
            <ConfirmAndCancelButtons
              style={{ paddingTop: 10, justifyContent: 'space-between', flexDirection: 'row-reverse' }}
            >
              <Row>
                {!hideCancel && (
                  <Button
                    withoutBorder
                    onClick={() => {
                      onCancel?.();
                      closeDialog();
                    }}
                  >
                    {cancelLabel || t.CANCEL}
                  </Button>
                )}
                {loadingAction ? (
                  <ButtonWithLoader
                    onClick={() => {
                      action?.();
                      !loadingAction && closeDialog();
                    }}
                    action={loadingAction}
                    callback={() => {
                      closeDialog();
                      callback?.();
                    }}
                    color={warning ? colors.negative : undefined}
                    withoutBorder={warning}
                    filled={!warning}
                    disabled={!this.state.confirmationEnabled}
                    text={okLabel || (warning ? t.DELETE : t.OK)}
                  />
                ) : (
                  this.renderConfirmButton()
                )}
              </Row>
              {additionalAction && additionalActionLabel && (
                <Button
                  onClick={() => {
                    additionalAction();
                    closeDialog();
                  }}
                  color={colors.primaryPurple}
                  withoutBorder
                >
                  {additionalActionLabel}
                </Button>
              )}
            </ConfirmAndCancelButtons>
          </Container>
        </Popup>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ common }: RootState) => getConfirmationDialogState(common);

const mapDispatchProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeDialog: closeConfirmationDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchProps)(ConfirmationDialog);

const Wrapper = styled(Column)`
  .popup-content {
    ${commonPopupCSS}
  }
  .repeat-pattern-confirmation-overlay {
    z-index: 9999 !important;
  }

  .popup-overlay {
    background: ${({ theme }) => theme.modalOverlayColor} !important;
    z-index: 9999 !important;
  }
`;

const Container = styled(Column)`
  width: 100%;
  flex: 1;
  justify-content: space-between;
`;

const Content = styled(Column)`
  padding: 0 20px;
`;

const Title = styled(LargeText)`
  display: block;
  margin-bottom: 20px;
  text-align: center;
`;

const Message = styled(RegularText)`
  display: block;
`;

const UnderstandCheck = styled.div`
  margin-top: 10px;
`;
