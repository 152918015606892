import { getType } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { DATE_FORMAT, now, takeLatestDate, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { CalendarEvent, CalendarEventType } from 'shovel-lib/types';
import { RootState } from '@state/types';
import { CalendarView } from './types';
import {
  addDraggingClass,
  calculateStudyAndExtraTimeEvents,
  createPlannableStudyBlocksFilter,
  extraTimeFilter,
  isCommitmentOrEvent,
  removeDraggingClasses,
  studyTimeFilter
} from '../../utils/calendar/calendarUtils';
import {
  IS_PLANNABLE,
  IS_PLANNABLE_OVERDUE,
  PLANNABLE_EVENT_CLASS_NAME,
  PLANNABLE_EVENT_GROUP_ID,
  PLANNABLE_OVERDUE_EVENT_CLASS_NAME
} from '../../utils/calendar/calendarConstants';
import {
  toBackgroundAwakeTime,
  toCalendarEvent,
  toExtraTimeEvent,
  toGoogleEventColorizeAsCourse,
  toStudyTimeEvent,
  toTaskEvent,
  toWarningAwakeTimeOverlapEvent
} from '../../utils/calendar/calendarEventConverters';
import { CalendarMode } from '../common/types';
import { createCommuteEvents, reduceArrayToObject } from '../../utils/reducerHelpers';
import { createEvent, deleteEvent, updateEvent, sourceEvents } from '../events/actions';
import { calendarData, movePlannedTask, planTask, resizePlannedTask } from './actions';
import { extractActionNameWithoutSuffix } from '../common/utils';
import { initialMonthFilterState } from '../settings/types';
import { MonthFilterT } from '../settings/types';
import { getGoogleEvents } from '../googleCalendar/actions';
import { groupByDueDate } from '../../utils/taskUtils';
import * as calendarActions from './actions';
import { termActions } from '../terms';
import unpackEvent from '@utils/unpackEvent';
import { isMonthFilterActive } from './utils';
import {
  constructStudyOrExtraTime,
  extractFreeTimeBlocks,
  minutesPopulatedWithEvents
} from 'shovel-lib/utils/calendarFreeBlocksUtils';

const getCalendar = (state: RootState) => {
  const { dialog, ...data } = state.calendar;
  return { ...data, newEvents: state.event.events };
};

const getCalendarLoaderInfo = ({ calendar: { courses, activities, semesterInfo, view } }: RootState) => {
  return {
    view,
    courses,
    activities,
    semesterInfo
  };
};

const isCommitmentsLoading = ({ common }: RootState) =>
  common.loaders[extractActionNameWithoutSuffix(calendarActions.commitmentsData.request)];

const isCourseSyncInProgress = ({ common }: RootState) =>
  common.loaders[extractActionNameWithoutSuffix(termActions.createBatchCourses.request)];

const getTasksHeaderInfo = ({
  calendar: { datesContainingTasks, startDate, endDate, tasksOnDay, closeActivePopup, view, cyclicDayCount }
}: RootState) => {
  return {
    datesContainingTasks,
    startDate,
    endDate,
    tasksOnDay,
    closeActivePopup,
    view,
    cyclicDayCount
  };
};

const getFilterFunction = (filter: MonthFilterT, isFilterActive: boolean) => {
  return event => {
    const isExam = event.type === 'EXAM';
    const isTask = event.type === CalendarEventType.TASK || isExam;
    return (
      !isFilterActive ||
      event.type === CalendarEventType.HOLIDAY ||
      (filter.customEvents && event.type === CalendarEventType.EVENT) ||
      (filter.exams ? isExam : filter.tasks && isTask) ||
      (filter.activityCorrelationIds.length > 0 && filter.activityCorrelationIds.includes(event.correlationId)) ||
      (filter.courseCorrelationIds.length > 0 && filter.courseCorrelationIds.includes(event.correlationId))
    );
  };
};

const getCalendarDialog = ({ calendar }: RootState) => calendar.dialog;

const getDefaultEventColor = ({ calendar }: RootState) => calendar.eventColor;

const separateAwakeTimesAndEvents = (
  acc: { events: CalendarEvent[]; awakeTimeEvents: CalendarEvent[] },
  sequence: CalendarEvent[],
  defaultEventColor: string,
  userSettings: any,
  theme: any
) => {
  if (sequence.length > 0) {
    if (sequence[0].type === CalendarEventType.AWAKE_TIME) {
      acc.awakeTimeEvents = [
        ...acc.awakeTimeEvents,
        ...sequence.map(event => toCalendarEvent(event, defaultEventColor, userSettings, theme))
      ];
    } else {
      acc.events = [...acc.events, ...sequence.map(event => toCalendarEvent(event, defaultEventColor, userSettings, theme))];
    }
  }
  return acc;
};

const getSemesterEventsAndAwakes = createSelector(
  [
    (state: RootState) => state.event.events,
    (state: RootState) => state.calendar.eventColor,
    (state: RootState) => state.settings.userSettings,
    (_, props) => props?.theme
  ],
  (stateEvents, defaultEventColor, userSettings, theme) => {
    return Object.values(stateEvents).reduce(
      (result: { events: CalendarEvent[]; awakeTimeEvents: CalendarEvent[] }, currentValue: any) =>
        separateAwakeTimesAndEvents(result, currentValue, defaultEventColor, userSettings, theme),
      { events: [], awakeTimeEvents: [] }
    );
  }
);

const getPlannedEvents = createSelector(
  [
    (state: RootState) => state.calendar.plannedEvents,
    (state: RootState) => state.calendar.eventColor,
    (state: RootState) => state.settings.userSettings,
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(calendarData.request)],
    (_, props) => props?.theme
  ],
  (plannedEventsObject, defaultEventColor, userSettings, dataLoading, theme) => {
    return dataLoading
      ? []
      : Object.values(plannedEventsObject).map((event: any) =>
          toCalendarEvent(event, defaultEventColor, userSettings, theme)
        );
  }
);

const getTaskEvents = createSelector(
  [(state: RootState) => state.calendar.datesContainingTasks, (_, props) => props?.theme],
  (datesContainingTasks, theme) => {
    return Object.values(datesContainingTasks || []).reduce(
      (acc: CalendarEvent[], events: any) => [...acc, ...events.map(task => toTaskEvent(task, theme))],
      []
    );
  }
);

const getDatesContainingTasks = (state: RootState) => state.calendar.datesContainingTasks;

const getCommuteTimes = createSelector(
  [
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(sourceEvents.request)],
    getSemesterEventsAndAwakes,
    (state: RootState) => state.settings.userSettings,
    (_, props) => props?.theme
  ],
  (eventsListLoading, stateEvents, userSettings, theme) => {
    return eventsListLoading
      ? []
      : createCommuteEvents(
          stateEvents.events.filter(e => isCommitmentOrEvent(e.type)),
          userSettings,
          theme
        );
  }
);

const getFreeTimeEvents = createSelector(
  [
    (state: RootState) => state.calendar.semesterInfo,
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(calendarData.request)],
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(sourceEvents.request)],
    (state: RootState) => state.calendar.googleEvents,
    getSemesterEventsAndAwakes,
    getPlannedEvents,
    getCommuteTimes
  ],
  (semesterInfo, calendarDataLoading, eventsListLoading, googleEvents, stateEvents, plannedEvents, commuteTimes) => {
    if (!semesterInfo || calendarDataLoading || eventsListLoading || stateEvents.awakeTimeEvents.length === 0) return [];
    const { firstAwakeTime, lastAwakeTime } = (stateEvents.awakeTimeEvents as CalendarEvent[]).reduce(
      (acc, curr) => {
        acc.firstAwakeTime = curr.start < acc.firstAwakeTime.start ? curr : acc.firstAwakeTime;
        acc.lastAwakeTime = curr.end < acc.lastAwakeTime.end ? curr : acc.lastAwakeTime;
        return acc;
      },
      {
        firstAwakeTime: stateEvents.awakeTimeEvents[0] as CalendarEvent,
        lastAwakeTime: stateEvents.awakeTimeEvents[0] as CalendarEvent
      }
    );

    const calendarStart =
      firstAwakeTime && semesterInfo.calendarMinDate.isAfter(firstAwakeTime.start)
        ? toMomentDate(firstAwakeTime.start)
        : semesterInfo.calendarMinDate;
    const calendarEnd =
      lastAwakeTime && semesterInfo.calendarMaxDate.isBefore(lastAwakeTime.end)
        ? toMomentDate(lastAwakeTime.end)
        : semesterInfo.calendarMaxDate;

    return calculateStudyAndExtraTimeEvents(
      {
        calendarStartDate: calendarStart,
        calendarEndDate: calendarEnd
      },
      semesterInfo,
      [...stateEvents.events, ...plannedEvents, ...commuteTimes, ...googleEvents],
      stateEvents.awakeTimeEvents
    );
  }
);

const getFreeTimeEventsWeek = createSelector(
  [
    (state: RootState) => state.calendar.semesterInfo,
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(calendarData.request)],
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(sourceEvents.request)],
    (state: RootState) => state.calendar.googleEvents,
    (state: RootState, props: any) => getSemesterEventsAndAwakes?.(state, props),
    (state: RootState, props: any) => getPlannedEvents?.(state, props),
    (state: RootState, props: any) => getCommuteTimes?.(state, props),
    (state: RootState, props: any) => getTaskEvents?.(state, props),
    (state: RootState) => state.calendar.startDate,
    (state: RootState) => state.calendar.endDate,
    (state: RootState) => state.calendar.view === CalendarView.MONTH
  ],
  (
    semesterInfo,
    calendarDataLoading,
    eventsListLoading,
    googleEvents,
    stateEvents,
    plannedEvents,
    commuteTimes,
    taskEvents,
    startDate,
    endDate,
    isMonthView
  ) => {
    if (
      calendarDataLoading ||
      eventsListLoading ||
      !semesterInfo ||
      stateEvents.awakeTimeEvents.length === 0 ||
      !startDate ||
      !endDate ||
      isMonthView
    )
      return [];

    const start = toMomentDate(startDate);
    const end = toMomentDate(endDate);

    const calendarStart = start.isBefore(semesterInfo.calendarMinDate) ? semesterInfo.calendarMinDate : start;

    const calendarEnd = end.isAfter(semesterInfo.calendarMaxDate) ? semesterInfo.calendarMaxDate : end;

    if (calendarEnd.isBefore(calendarStart)) return [];

    const populatedMinutes = minutesPopulatedWithEvents(
      [
        ...stateEvents.events,
        ...plannedEvents,
        ...commuteTimes,
        ...googleEvents,
        ...taskEvents.filter(e => e.type !== CalendarEventType.TASK)
      ],
      stateEvents.awakeTimeEvents,
      calendarStart,
      calendarEnd
    );

    return extractFreeTimeBlocks(populatedMinutes).map(block =>
      constructStudyOrExtraTime(block, semesterInfo, calendarStart.toDate(), now())
    );
  }
);

const getCalendarEvents = createSelector(
  [
    (state: RootState) => state.calendar.semesterInfo,
    (state: RootState) => state.calendar.isDragging,
    (state: RootState) => state.calendar.draggingTask,
    (state: RootState) => state.calendar.eventColor,
    (state: RootState, props: any) => props?.mode === CalendarMode.AWAKE,
    (state: RootState) => state.calendar.view,
    (state: RootState) => state.calendar.googleEvents,
    getSemesterEventsAndAwakes,
    (state: RootState) => state.calendar.plannedEvents,
    getTaskEvents,
    getCommuteTimes,
    getFreeTimeEventsWeek,
    (state: RootState) => state.course.googleCalendarId,
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(calendarData.request)],
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(sourceEvents.request)],
    (state: RootState) => state.common.loaders[extractActionNameWithoutSuffix(getGoogleEvents.request)],
    (state: RootState) => state.settings.userSettings?.monthFilter || initialMonthFilterState,
    (_, props: any) => props?.theme,
    (state: RootState) => state.calendar.courses,
    (state: RootState) => state.calendar.activities,
    (state: RootState) => state.settings.userSettings
  ],
  (
    semesterInfo,
    isDragging,
    draggingTask,
    defaultEventColor,
    isAwakeMode,
    view,
    googleEvents,
    stateEvents,
    plannedTaskEvents,
    taskEvents,
    commuteTimes,
    freeTimeEvents,
    courseGoogleCalendarId,
    calendarDataLoading,
    eventsListLoading,
    googleEventsLoading,
    monthFilter,
    theme,
    courses,
    activities,
    userSettings
  ) => {
    if (calendarDataLoading || eventsListLoading || googleEventsLoading || !semesterInfo) return [];

    const isMonthView = view === CalendarView.MONTH;
    const isFilterActive = isMonthFilterActive(monthFilter);

    let eventsFilter;
    if (isMonthView) {
      eventsFilter = getFilterFunction(monthFilter, isFilterActive);
    } else {
      eventsFilter = event => {
        return userSettings.showFlags || event.type !== CalendarEventType.TASK;
      };
    }

    const { events, awakeTimeEvents } = stateEvents;

    if (isAwakeMode) {
      return awakeTimeEvents;
    }

    const availableDaysForPlanning: { [key: string]: CalendarEvent } = {};
    const availableDaysForPlanningOverdue: { [key: string]: CalendarEvent } = {};

    let plannedEvents = eventsListLoading
      ? []
      : Object.values(plannedTaskEvents)
          .filter((event: any) => toMomentDate(event.end).diff(event.start, 'minutes') > 0)
          .map((event: any) => toCalendarEvent(event, defaultEventColor, userSettings, theme));

    if (isDragging && draggingTask) {
      const { taskId, dueDate } = draggingTask;

      const plannableStudyBlocksFilter = createPlannableStudyBlocksFilter(
        takeLatestDate(semesterInfo.calendarMinDate, now()),
        dueDate
      );

      if (!isMonthView) {
        freeTimeEvents.forEach(event => {
          removeDraggingClasses(event);
          if (plannableStudyBlocksFilter(event) === IS_PLANNABLE) {
            addDraggingClass(event, PLANNABLE_EVENT_GROUP_ID, PLANNABLE_EVENT_CLASS_NAME);
          } else if (plannableStudyBlocksFilter(event) === IS_PLANNABLE_OVERDUE) {
            addDraggingClass(event, PLANNABLE_EVENT_GROUP_ID, PLANNABLE_OVERDUE_EVENT_CLASS_NAME);
          }
        });
      }
    }

    // needed for rendering sleep times if there are no awake times in the week
    const awakeTimes =
      awakeTimeEvents.length > 0
        ? awakeTimeEvents
        : [
            {
              start: semesterInfo.calendarMaxDate.toDate(),
              end: semesterInfo.calendarMaxDate.toDate(),
              type: CalendarEventType.AWAKE_TIME,
              classNames: ['awake-time-event'],
              startAsDate: semesterInfo.calendarMaxDate.toDate(),
              endAsDate: semesterInfo.calendarMaxDate.toDate()
            }
          ];

    let filteredEvents = events.filter(eventsFilter);
    if (isMonthView) {
      plannedEvents = plannedEvents.map(e => ({ ...e, display: 'background' }));
    }
    filteredEvents = [...filteredEvents, ...plannedEvents];
    const filteredTaskEvents = taskEvents.filter(eventsFilter);
    const filteredCommuteTimes = !isMonthView ? commuteTimes : [];
    const extraTime = !isMonthView ? freeTimeEvents.filter(extraTimeFilter).map(toExtraTimeEvent) : [];
    const studyTime = !isMonthView ? freeTimeEvents.filter(studyTimeFilter).map(toStudyTimeEvent) : [];
    const coursesByCalendarId = reduceArrayToObject([...courses, ...activities], 'googleCalendarId');
    const filteredGoogleEvents = (isMonthView && isFilterActive && !monthFilter.googleEvents ? [] : googleEvents).map(e =>
      // @ts-ignore
      toGoogleEventColorizeAsCourse(e, coursesByCalendarId[e.googleCalendarId], userSettings, theme)
    );
    // if it is week or cyclic view then group of tasks with same dueDate display as one, with common cal event props, list of tasks as extendedProps
    const tasks = isMonthView
      ? filteredTaskEvents
      : Object.entries(groupByDueDate(filteredTaskEvents)).map(([key, value], index) => {
          return (value as CalendarEvent[])[0].type === CalendarEventType.TASK
            ? {
                //@ts-ignore
                backgroundColor: value[0].backgroundColor,
                borderColor: null,
                busy: false,
                classNames: ['task-event'],
                colorHex: null,
                correlationId: null,
                display: 'list-item',
                editable: false,
                //@ts-ignore
                end: value[0].end,
                id: index,
                start: key,
                tasks: value,
                textColor: null,
                title: '',
                type: CalendarEventType.TASK
              }
            : (value as CalendarEvent[])[0];
        });

    return [
      ...Object.values(availableDaysForPlanning),
      ...Object.values(availableDaysForPlanningOverdue),
      ...awakeTimes.map(e => toBackgroundAwakeTime(e, theme?.sleepTimeBackgroundColor)),
      ...filteredEvents.map(e => {
        if (e.type === CalendarEventType.HOLIDAY) {
          return e;
        }
        if (!isWrappedWithAwakeTime(awakeTimes, e)) {
          return toWarningAwakeTimeOverlapEvent(e);
        }
        return e;
      }),
      ...filteredGoogleEvents,
      ...filteredCommuteTimes.map(e => (!isWrappedWithAwakeTime(awakeTimes, e) ? toWarningAwakeTimeOverlapEvent(e) : e)),
      ...tasks,
      ...studyTime,
      ...extraTime
    ];
  }
);

const isWrappedWithAwakeTime = (awakeTimes: any[], event: any) => {
  return awakeTimes.some(
    at => at.startAsDate.getTime() <= event.startAsDate.getTime() && at.endAsDate.getTime() >= event.endAsDate.getTime()
  );
};

const isOverlapped = (plannedEvent: CalendarEvent, events: CalendarEvent[]) => {
  return events
    .filter(e => e.id !== plannedEvent.id && e.busy)
    .some(
      e =>
        (e.start <= plannedEvent.start && e.end > plannedEvent.start) ||
        (e.start < plannedEvent.end && e.end >= plannedEvent.end) ||
        (plannedEvent.start <= e.start && plannedEvent.end >= e.end)
    );
};

const getHolidays = createSelector(
  [(state: RootState) => state.event.events],
  events =>
    Object.values(events).reduce(
      (holidays: CalendarEvent[], events: CalendarEvent[]) =>
        events.length > 0 && events[0].type === CalendarEventType.HOLIDAY ? [...holidays, events[0]] : holidays,
      []
    ) as CalendarEvent[]
);

const getCalendarInfo = ({ calendar }: RootState) => {
  return calendar.semesterInfo;
};

const getCalendarView = ({ calendar }: RootState) => {
  return calendar.view;
};

const getDraggingTask = ({ calendar }: RootState) => calendar.draggingTask;

const getCommitments = (state: RootState) => {
  const { activities, courses } = state.calendar;
  return { activities, courses };
};

const getCommitmentsState = (state: RootState) => ({
  ...getCommitments(state),
  eventColor: state.calendar.eventColor
});

const getEventError = ({ common }: RootState) => {
  const updateEventType = getType(updateEvent.failure).split('_FAILURE')[0];
  return common.errors[updateEventType];
};

const eventsLoading = ({ common }: RootState) => {
  const updateEventType = getType(updateEvent.request).split('_REQUEST')[0];
  const createEventType = getType(createEvent.request).split('_REQUEST')[0];
  const deleteEventType = getType(deleteEvent.request).split('_REQUEST')[0];
  return common.loaders[createEventType] || common.loaders[updateEventType] || common.loaders[deleteEventType];
};

const plannedUpdateInProgress = ({ common }: RootState) => {
  const planTaskType = getType(planTask.request).split('_REQUEST')[0];
  const movePlannedTaskType = getType(movePlannedTask.request).split('_REQUEST')[0];
  const resizePlannedTaskType = getType(resizePlannedTask.request).split('_REQUEST')[0];
  return common.loaders[movePlannedTaskType] || common.loaders[resizePlannedTaskType] || common.loaders[planTaskType];
};

const isCalendarDataLoading = (state: RootState) =>
  state.common.loaders[extractActionNameWithoutSuffix(calendarData.request)];

const getMonthFilterState = ({ calendar, settings }: RootState) => ({
  filter: settings.userSettings?.monthFilter || initialMonthFilterState,
  activities: calendar.activities,
  courses: calendar.courses
});

const getCalendarStartDate = ({ calendar }: RootState) => calendar.startDate;
const getCalendarEndDate = ({ calendar }: RootState) => calendar.endDate;

const getGoogleCalendars = ({ calendar }: RootState) => {
  return [...calendar.googleCalendars];
};

export const getConnectedGoogleCalendars = createSelector(
  [
    ({ calendar }: RootState) => calendar.googleCalendars,
    ({ calendar }: RootState) => calendar.courses,
    ({ calendar }: RootState) => calendar.activities
  ],
  (googleCalendars, courses, activities) => {
    return googleCalendars.map(gc => {
      const connectedCourse =
        courses.find(c => c.googleCalendarId === gc.googleCalendarId) ??
        activities.find(c => c.googleCalendarId === gc.googleCalendarId);
      return connectedCourse
        ? {
            ...gc,
            connectedCourseName: connectedCourse.name
          }
        : gc;
    });
  }
);

const getEventApplyDialogState = ({ calendar }: RootState) => calendar.eventApplyDialog;

const isApplyDialogOpen = ({ calendar }: RootState) => calendar.eventApplyDialog.open;

const isPlanBlockDialogOpen = ({ calendar }: RootState) => calendar.planBlockDialog.opened;

const getPlanBlockDialogState = ({ calendar }: RootState) => calendar.planBlockDialog;

export const getImportCoursePreview = ({ calendar }: RootState) => calendar.importCourseDialog;

const isPlanSidebarCollapsed = ({ calendar }: RootState) => calendar.planSidebarCollapsed;

const getCyclicDayCount = ({ calendar }: RootState) => calendar.cyclicDayCount;

const getAllCourses = ({ calendar }: RootState) => calendar.courses;

const getAllActivities = ({ calendar }: RootState) => calendar.activities;

const getPlannedEvent = ({ calendar }: RootState, id: number) => calendar.plannedEvents[id];

const getClassTimes = createSelector(
  [
    (state: RootState) => state.event.sourceEvents,
    (state: RootState, props: any) =>
      [...state.calendar.courses, ...state.calendar.activities].find(c => c.id === props.courseId),
    (state: RootState, props: any) => !!state.calendar.courses.find(c => c.id === props.courseId),
    (_, props: any) => props?.theme
  ],
  (sourceEvents, course, isCourse, theme) => {
    if (!course) return { colorHex: undefined, classes: {} };
    return {
      colorHex: course.colorHex,
      isCourse,
      classes: sourceEvents
        .filter(e => e.correlationId === course.correlationId)
        .flatMap(c => unpackEvent(c))
        .map(c => c.start)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce((acc, datetime) => {
          const date = toMomentDate(datetime).format(DATE_FORMAT);
          const times = acc[date] || [];
          times.push(datetime);
          acc[date] = times;
          return acc;
        }, {})
    };
  }
);

export {
  getCalendar,
  getCalendarLoaderInfo,
  getCommitments,
  getTasksHeaderInfo,
  getCalendarDialog,
  getCalendarEvents,
  getCalendarView,
  getCalendarInfo,
  getDraggingTask,
  getCommitmentsState,
  getEventError,
  eventsLoading,
  getCalendarStartDate,
  getCalendarEndDate,
  getHolidays,
  isCalendarDataLoading,
  getMonthFilterState,
  getGoogleCalendars,
  getEventApplyDialogState,
  getPlanBlockDialogState,
  isApplyDialogOpen,
  plannedUpdateInProgress,
  getFreeTimeEvents,
  isPlanSidebarCollapsed,
  getCyclicDayCount,
  getAllCourses,
  getAllActivities,
  getClassTimes,
  getTaskEvents,
  getPlannedEvent,
  isCommitmentsLoading,
  isCourseSyncInProgress,
  getSemesterEventsAndAwakes,
  getCommuteTimes,
  getPlannedEvents,
  getDatesContainingTasks,
  getDefaultEventColor,
  isPlanBlockDialogOpen
};
