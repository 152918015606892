import { RootState } from '@state/types';
import { extractActionNameWithoutSuffix } from '../common/utils';
import { getWorkload } from './actions';
import { createSelector } from 'reselect';
import { CourseType, TaskState } from 'shovel-lib/types';
import { SidebarItemName, SidebarItemType } from './types';

export const isWorkloadLoading = ({ common }: RootState) =>
  common.loaders[extractActionNameWithoutSuffix(getWorkload.request)];

export const getWorkloadState = createSelector(
  [
    ({ workload }: RootState) => workload.tasks,
    state => state.workload.selectedItemId,
    state => state.workload.selectedItemType,
    state => state.workload.selectedItemCourseId
  ],
  (tasks, selectedItemId, selectedItemType, selectedItemCourseId) => {
    const sectionFilter = t =>
      !t.deleted &&
      selectedItemId !== SidebarItemName.TRASH &&
      (!selectedItemId || // all tasks section selected
      (selectedItemType === SidebarItemType.SECTION &&
        selectedItemId === SidebarItemName.COURSE &&
        !!t.courseId &&
        t.courseType === CourseType.COURSE) || // all courses selected
      (selectedItemType === SidebarItemType.SECTION &&
        selectedItemId === SidebarItemName.ACTIVITY &&
        !!t.courseId &&
        t.courseType === CourseType.ACTIVITY) || // all activities selected
      (selectedItemType === SidebarItemType.SECTION && selectedItemId === SidebarItemName.RANDOM && !t.courseId) || // random (no course) section
      ((selectedItemType === SidebarItemType.COURSE || selectedItemType === SidebarItemType.ACTIVITY) &&
        t.courseId === selectedItemId) || // a course or activity is selected
        (selectedItemType === SidebarItemType.TASK_CATEGORY &&
          filterByTaskCategory(t, selectedItemId, selectedItemCourseId))); // task category is selected

    return {
      tasks: tasks.filter(t =>
        selectedItemId === SidebarItemName.TRASH ? t.deleted : t.state !== TaskState.COMPLETED && sectionFilter(t)
      ),
      completedTasks: tasks.filter(t => t.state === TaskState.COMPLETED && sectionFilter(t))
    };
  }
);

const filterByTaskCategory = (task: any, selectedItemId: string | number, selectedItemCourseId?: string | number) => {
  if (selectedItemId.toString().includes('uncategorized')) {
    return !task.taskCategory && task.courseId === selectedItemCourseId;
  }
  return task.taskCategory?.id === selectedItemId;
};

export const getNewWorkloadTasks = ({ workload }: RootState) => workload.newWorkloadTasks;

export const getSelectedCourseId = ({ workload }: RootState) =>
  workload.selectedItemType === SidebarItemType.COURSE || workload.selectedItemType === SidebarItemType.ACTIVITY
    ? (workload.selectedItemId as number)
    : undefined;

export const getSelectedItemInfo = ({ workload }: RootState) => {
  const {
    selectedItemId,
    selectedItemType,
    selectedItemColor,
    selectedItemName,
    loading,
    expandedItemType,
    expandedItemId,
    selectedItemCourseId
  } = workload;
  return {
    loading,
    selectedItemId,
    selectedItemType,
    selectedItemName,
    selectedItemColor,
    expandedItemType,
    expandedItemId,
    selectedItemCourseId
  };
};
