import { VisibleTaskState } from './types';
import {
  addOrUpdateSubtask,
  deleteSubtask,
  deleteTask,
  getManageTask,
  moveToTodo,
  saveManageTask,
  updateTaskStatus
} from './actions';
import { getType } from 'typesafe-actions';
import { RootState } from '@state/types';

const getVisibleTaskState = ({ task }: { task: VisibleTaskState }) => {
  return task;
};

const getClassAppearancesState = ({ task }: { task: VisibleTaskState }) => {
  const { classAppearancesOnDate } = task;
  return { classAppearancesOnDate };
};

const getManageError = ({ common }: { common: any }) => {
  const getManageTaskType = getType(getManageTask.failure).split('_FAILURE')[0];
  const saveManageTaskType = getType(saveManageTask.failure).split('_FAILURE')[0];
  const moveToTodoType = getType(moveToTodo.failure).split('_FAILURE')[0];
  const updateTaskStatusType = getType(updateTaskStatus.failure).split('_FAILURE')[0];
  const deleteTaskType = getType(deleteTask.failure).split('_FAILURE')[0];
  const addOrUpdateSubtaskType = getType(addOrUpdateSubtask.failure).split('_FAILURE')[0];
  const deleteSubtaskType = getType(deleteSubtask.failure).split('_FAILURE')[0];

  return (
    common.errors[getManageTaskType] ||
    common.errors[saveManageTaskType] ||
    common.errors[updateTaskStatusType] ||
    common.errors[deleteTaskType] ||
    common.errors[addOrUpdateSubtaskType] ||
    common.errors[deleteSubtaskType] ||
    common.errors[moveToTodoType]
  );
};

const getCurrentManagedTask = ({ task }: { task: VisibleTaskState }) => {
  return task.manage;
};

const getTasksDialogState = ({ task }: { task: VisibleTaskState }) => {
  return task.tasksModal;
};

const isLoading = (state: RootState) => state.task.loading;

const getNewCreateTaskDialogState = (state: RootState) => state.task.newCreateTaskDialog;

const getRepeatPatternDialogState = (state: RootState) => state.task.repeatPatternDialog;

const getNewEstDialogState = (state: RootState) => state.task.newEstDialog;

const isRepeatDialogOpen = (state: RootState) => state.task.repeatPatternDialog.open;

export {
  getVisibleTaskState,
  getClassAppearancesState,
  getCurrentManagedTask,
  getManageError,
  isLoading,
  getTasksDialogState,
  getNewCreateTaskDialogState,
  getRepeatPatternDialogState,
  getNewEstDialogState,
  isRepeatDialogOpen
};
