import React from 'react';
import styled, { css } from 'styled-components';
import { WeekdayStr } from 'rrule';
import { CalendarEvent, CalendarEventType, SemesterCalendarInfo } from 'shovel-lib/types';
import { DATE_FORMAT, isSameDate, now, timeDiffInMinutes, toMomentDate, toMomentUtcDate } from 'shovel-lib/utils/timeUtils';
import { Moment } from 'moment';

import { CalendarView } from '../../state/calendar/types';
import t from '../../i18n/t';
import {
  calculationPrecondition,
  constructStudyOrExtraTime,
  extractFreeTimeBlocks,
  minutesPopulatedWithEvents
} from 'shovel-lib/utils/calendarFreeBlocksUtils';
import {
  IS_PLANNABLE,
  IS_PLANNABLE_OVERDUE,
  PLANNABLE_EVENT_CLASS_NAME,
  PLANNABLE_EVENT_GROUP_ID,
  PLANNABLE_OVERDUE_EVENT_CLASS_NAME
} from './calendarConstants';
import { capitalize } from '../textUtils';
import { Draggable } from '@fullcalendar/interaction';
import { CourseSource } from '../../state/ics/reducers';
import { calendarStylingType } from '@state/settings/types';
import { OptionType } from '../../components/common/MoreOptionDropdown';

const LOCAL_WEEK_FORMAT = 'YYYY-w';

export type PlannedTaskMarkers = {
  [key: string]: number;
};

const toPlannableMonthDay = (date: Moment) => ({
  start: date.startOf('day').toDate(),
  end: date.add(1, 'days').toDate(),
  groupId: PLANNABLE_EVENT_GROUP_ID,
  display: 'background'
});

export const reduceEventsToDaysMap = (acc: any, event: any) => {
  const startDate = toMomentDate(event.start);
  if (!acc[startDate.format(DATE_FORMAT)]) {
    acc[startDate.format(DATE_FORMAT)] = toPlannableMonthDay(startDate);
  }
  const endDate = toMomentDate(event.end);
  if (!isSameDate(startDate, endDate)) {
    acc[endDate.format(DATE_FORMAT)] = toPlannableMonthDay(endDate);
  }
  return acc;
};

export const weeksBetweenDays = (start: Moment, end: Moment) => {
  if (start.isAfter(end)) return [];

  const weeks: string[] = [];
  // todo check should this be isoWeek
  // todo maybe WEEK_FORMAT (YYYY-W) should be replaced with YYYY-w
  const date = start.clone().startOf('week');
  while (!date.isAfter(end)) {
    const week = date.format(LOCAL_WEEK_FORMAT);
    if (!weeks.includes(week)) {
      weeks.push(week);
    }
    date.add(1, 'week');
  }
  return weeks;
};

export const datesWithinRange = (start: Moment, end: Moment) => {
  if (start.isAfter(end)) return [];

  const dates: string[] = [];
  const date = start.clone().startOf('day');
  while (!date.isAfter(end)) {
    dates.push(date.format(DATE_FORMAT));
    date.add(1, 'day');
  }
  return dates;
};

export const toDateInRequest = (date: string | Date) =>
  toMomentDate(date)
    .clone()
    .format(DATE_FORMAT);

export const isImportedEvent = (type: any) =>
  [CourseSource.MOODLE, CourseSource.BRIGHTSPACE, CourseSource.CANVAS].includes(type);

export const isCommitmentOrEvent = (type: CalendarEventType) =>
  isImportedEvent(type) || [CalendarEventType.COURSE, CalendarEventType.ACTIVITY, CalendarEventType.EVENT].includes(type);

export const isCommitmentOrEventOrAwake = (type: CalendarEventType) =>
  isCommitmentOrEvent(type) || type === CalendarEventType.AWAKE_TIME;

export const isCommute = (type: CalendarEventType) =>
  [CalendarEventType.COMMUTE_BEFORE, CalendarEventType.COMMUTE_AFTER].includes(type);

export const calculateStudyAndExtraTimeEvents = (
  calendarInfo: { calendarStartDate: Moment; calendarEndDate: Moment },
  semesterCalendarInfo: SemesterCalendarInfo,
  events: CalendarEvent[],
  awakeTimeEvents: CalendarEvent[]
) => {
  const { calendarStartDate, calendarEndDate } = calendarInfo;
  const { calendarMinDate, calendarMaxDate } = semesterCalendarInfo;

  const from = calendarStartDate.isAfter(calendarMinDate) ? calendarStartDate : calendarMinDate;
  const to = calendarEndDate.isBefore(calendarMaxDate) ? calendarEndDate : calendarMaxDate;

  if (!calculationPrecondition(from, to, awakeTimeEvents)) return [];

  const populatedMinutes = minutesPopulatedWithEvents(events, awakeTimeEvents, from, to);

  return extractFreeTimeBlocks(populatedMinutes).map(block =>
    constructStudyOrExtraTime(block, semesterCalendarInfo, from.toDate(), now())
  );
};

export const createDateRangeFilter = (from: Moment, to: Moment) => (e: CalendarEvent) => {
  const eventStart = toMomentDate(e.start);
  const eventEnd = toMomentDate(e.end);
  return (
    eventStart.isBetween(from, to, undefined, '[]') ||
    eventEnd.isBetween(from, to, undefined, '[]') ||
    from.isBetween(eventStart, eventEnd, undefined, '[]')
  );
};

export const awakeTimesFilter = (e: CalendarEvent) => e.type === CalendarEventType.AWAKE_TIME;

export const studyTimeFilter = (e: CalendarEvent) => e.type === CalendarEventType.STUDY_TIME;

export const extraTimeFilter = (e: CalendarEvent) => e.type === CalendarEventType.EXTRA_TIME;

/**
 * Plannable study block is study time event which meets these conditions:
 * - is in future and is visible on calendar
 * - is before dragged task due date
 * - doesn't have dragged task already planned
 *
 * @param from    - start date of the current calendar view or now (latest between these two)
 * @param to      - due date of the task
 */
export const createPlannableStudyBlocksFilter = (from: Moment, to: Moment) => (e: CalendarEvent) => {
  // if (!to.isAfter(from)) return false;

  const isStudy = e.type === CalendarEventType.STUDY_TIME;

  const eventStart = toMomentUtcDate(e.start);
  const eventEnd = toMomentUtcDate(e.end);
  // if task does not have dueDate than study block should be colored purple
  const isInsideGivenRange = to
    ? eventStart.isBetween(from, to, undefined, '[]') ||
      eventEnd.isBetween(from, to, undefined, '[]') ||
      to.isBetween(eventStart, eventEnd, undefined, '[]')
    : true;

  if (isStudy && eventEnd.isAfter(from)) {
    return isInsideGivenRange ? IS_PLANNABLE : IS_PLANNABLE_OVERDUE;
  }
  return null;
};

export const toUpdateEventDto = (calendarInfo: any, newEnd?: any) => {
  const {
    id,
    start,
    end,
    title,
    allDay,
    extendedProps: {
      colorHex,
      description,
      commuteBefore,
      commuteAfter,
      location,
      type,
      isRecurring,
      originalEventStart,
      repeat
    }
  } = calendarInfo;

  return {
    id,
    title,
    start,
    end: newEnd || end,
    description,
    commuteAfter,
    commuteBefore,
    location,
    type,
    allDay,
    repeat,
    isRecurring,
    originalEventStart,
    colorHex
  };
};

export const shouldResetSliders = (event: CalendarEvent, eventFromState: CalendarEvent) => {
  const duration = timeDiffInMinutes(event.start, event.end);
  const totalPlanned = eventFromState.plannedTasks!.map(t => t.plannedMinutes).reduce((a: number, b: number) => a + b, 0);
  return duration < totalPlanned;
};

export const getFirstTwoDayLetters = (date: Date | string) =>
  toMomentDate(date)
    .format('dd')
    .toUpperCase() as WeekdayStr;

export const draggableStyle = css`
  cursor: grab;
  transition: box-shadow 0.2s ease;

  &:active {
    cursor: grabbing;
  }
  &:hover {
    box-shadow: 0px 2px 2px ${props => props.theme.boxShadow}, 0px 0px 2px ${props => props.theme.boxShadow};
  }
`;

export const PlannedTimeOverlay = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${props => props.percent}%;
`;

export const calendarViewsHeaderDateFormat = new Map([
  [CalendarView.MONTH, 'ddd'],
  [CalendarView.LIST, 'ddd DD'],
  [CalendarView.WEEK, 'ddd DD'],
  [CalendarView.CYCLIC, 'ddd DD']
]);

export const mapToPlanBlockEvent = (event: any) => {
  const {
    id,
    start,
    end,
    extendedProps: { type, plannedTasks, passed, isRecurring, isLms, dayOfWeek, repeat }
  } = event;
  return {
    id: id !== '' ? id : undefined,
    start,
    end,
    type,
    isLms,
    plannedTasks: plannedTasks || [],
    passed,
    isRecurring,
    dayOfWeek,
    repeat
  };
};

export const getEventContextMenuOptions = (
  calendarEvent: any,
  { openPlanBlockDialog, deletePlannedTask, deleteEvent, duplicatePlannedTask }
) => {
  const event = mapToPlanBlockEvent(calendarEvent);
  let options: OptionType[] = [];
  switch (event.type) {
    case CalendarEventType.PLANNED_TASK: {
      options = [
        {
          value: t.DUPLICATE,
          label: t.DUPLICATE,
          icon: 'content_copy',
          onClick: () => duplicatePlannedTask(Number(event.id))
        },
        {
          value: t.UNPLAN,
          label: t.UNPLAN,
          icon: 'event_busy',
          onClick: () => deletePlannedTask(Number(event.id))
        }
      ];
      break;
    }
    case CalendarEventType.STUDY_TIME: {
      options = [
        {
          value: t.PLAN_THIS_BLOCK,
          label: t.PLAN_THIS_BLOCK,
          icon: 'calendar_today',
          onClick: () => openPlanBlockDialog({ event })
        }
      ];
      break;
    }
    default: {
      options = [{ value: t.DELETE, label: t.DELETE, icon: 'delete_outline', onClick: () => deleteEvent(event) }];
      break;
    }
  }
  return options;
};

export const removeDraggingClasses = event => {
  event.groupId = '';
  const classIndex = event.classNames.indexOf(PLANNABLE_EVENT_CLASS_NAME);
  const classIndexOverdue = event.classNames.indexOf(PLANNABLE_OVERDUE_EVENT_CLASS_NAME);

  if (classIndex !== -1) {
    event.classNames.splice(classIndex, 1);
  }
  if (classIndexOverdue !== -1) {
    event.classNames.splice(classIndexOverdue, 1);
  }
};

export const addDraggingClass = (event, groupId, className) => {
  event.groupId = groupId;
  event.classNames = [...(event.classNames || []), className];
};

export const getRecurrenceText = options => {
  if (!options) return null;
  if (options.interval === 0) return t.ON_SELECTED_DAYS;

  let text =
    !options.interval || options.interval === 1
      ? t.WEEKLY
      : `${t.REPEATS_EVERY} ${options.interval} ${t.WEEKS.toLowerCase()}`;
  if (options.onDays) {
    const locale = toMomentDate().localeData();
    const allWeekdays = [...locale.weekdaysMin()];
    const shiftedWeekdays = allWeekdays.splice(0, locale.firstDayOfWeek());
    const weekdays = allWeekdays.concat(shiftedWeekdays).filter(d => options.onDays.map(capitalize).includes(d));

    if (weekdays.length > 0) {
      text += ` ${t.ON} ${weekdays.join(', ')}`;
    }
  }
  return text;
};

/**
 * Repeat pattern has changed if there wasn't a pattern and was added, or there was a pattern but was removed
 * or there was a pattern but some of the values were changed
 * No need to check if old and new are both undefined
 */
export const hasRepeatPatternChanged = (oldRepeat, newRepeat) =>
  !!oldRepeat !== !!newRepeat ||
  (oldRepeat &&
    newRepeat &&
    (oldRepeat.interval !== newRepeat.interval ||
      oldRepeat.onDays?.length !== newRepeat.onDays?.length ||
      //@ts-ignore
      (oldRepeat.onDays && !oldRepeat.onDays.every(d => newRepeat.onDays?.includes(d))) ||
      oldRepeat.includeDays?.length !== newRepeat.includeDays?.length ||
      //@ts-ignore
      (oldRepeat.includeDays && !oldRepeat.includeDays.every(d => newRepeat.includeDays?.includes(d))) ||
      oldRepeat.untilOption !== newRepeat.untilOption ||
      !toMomentDate(oldRepeat.until).isSame(newRepeat.until, 'date')));

export const extendDraggableItem = (draggableItem: Draggable) => {
  draggableItem.dragging.autoScroller.edgeThreshold = 80;
  draggableItem.dragging.autoScroller.maxVelocity = 1200;
};

export const calendarStylingOptions = theme => ({
  [calendarStylingType.WHITE]: {
    name: t.WHITE,
    backgroundFunction: color => theme.background
  },
  [calendarStylingType.GRAY]: {
    name: t.GRAY,
    backgroundFunction: color => theme.activitiesColor
  },
  [calendarStylingType.LIGHT]: {
    name: '10%',
    backgroundFunction: theme.commitmentColorFunction
  },
  [calendarStylingType.FULL]: {
    name: '20%',
    backgroundFunction: theme.courseColorFunction
  }
});
