import styled from 'styled-components';
import { RegularText } from '@utils/typography';
import t from '@i18n/t';

export enum badgeNames {
  ROCKSTAR_ROOKIE = 'ROCKSTAR_ROOKIE',
  SCHEDULE_ALCHEMIST = 'SCHEDULE_ALCHEMIST',
  TASK_NINJA = 'TASK_NINJA',
  GOD_OF_TIME = 'GOD_OF_TIME',
  PLANNING_GRADUATE = 'PLANNING_GRADUATE',
  ACE_OF_INITIATIVE = 'ACE_OF_INITIATIVE',
  PRECISION_UNICORN = 'PRECISION_UNICORN'
}

export enum BadgeStep {
  QUICK_SETUP_STEP = 'QUICK_SETUP_STEP',
  SCHEDULE_STEP = 'SCHEDULE_STEP',
  TIME_NEEDED_STEP = 'TIME_NEEDED_STEP',
  START_AHEAD_STEP = 'START_AHEAD_STEP',
  CREATE_TASK_STEP = 'CREATE_TASK_STEP',
  PLAN_TASK_STEP = 'PLAN_TASK_STEP',
  TRACK_PROGRESS_STEP = 'TRACK_PROGRESS_STEP',
  CUSHION_STEP = 'CUSHION_STEP',
  STUDY_TIME_STEP = 'STUDY_TIME_STEP',
  AWAKE_TIME_STEP = 'AWAKE_TIME_STEP'
}

export enum Substeps {
  QUICK_SETUP = 'QUICK_SETUP',
  SCHEDULE_VIDEO = 'SCHEDULE_VIDEO',
  SCHEDULE_CLASS = 'SCHEDULE_CLASS',
  SCHEDULE_ACTIVITY = 'SCHEDULE_ACTIVITY',
  TIME_NEEDED_VIDEO = 'TIME_NEEDED_VIDEO',
  TIME_NEEDED_QUIZ = 'TIME_NEEDED_QUIZ',
  START_AHEAD_VIDEO = 'START_AHEAD_VIDEO',
  START_AHEAD_QUIZ = 'START_AHEAD_QUIZ',
  CREATE_TASK_VIDEO = 'CREATE_TASK_VIDEO',
  CREATE_TASK_CATEGORY = 'CREATE_TASK_CATEGORY',
  CREATE_TASK = 'CREATE_TASK',
  PLAN_TASK_VIDEO = 'PLAN_TASK_VIDEO',
  PLAN_TASK = 'PLAN_TASK',
  PROGRESS_VIDEO = 'PROGRESS_VIDEO',
  ADD_PROGRESS = 'ADD_PROGRESS',
  COMPLETE_TASK = 'COMPLETE_TASK',
  CUSHION_VIDEO = 'CUSHION_VIDEO',
  CUSHION_QUIZ = 'CUSHION_QUIZ',
  STUDY_TIME_VIDEO = 'STUDY_TIME_VIDEO',
  SET_STUDY_TIME = 'SET_STUDY_TIME',
  AWAKE_TIME_VIDEO = 'AWAKE_TIME_VIDEO',
  SET_AWAKE_TIME = 'SET_AWAKE_TIME'
}

export const allBadges = [
  {
    key: badgeNames.ROCKSTAR_ROOKIE,
    name: t[badgeNames.ROCKSTAR_ROOKIE],
    icon: '🤘',
    color: '#EAF1F3',
    steps: [BadgeStep.QUICK_SETUP_STEP],
    givesFreeTrial: false,
    quote: '' // t[`${badgeNames.ROCKSTAR_ROOKIE}_QUOTE`],
  },
  {
    key: badgeNames.SCHEDULE_ALCHEMIST,
    name: t[badgeNames.SCHEDULE_ALCHEMIST],
    icon: '✨',
    color: '#5873FF',
    steps: [BadgeStep.SCHEDULE_STEP],
    givesFreeTrial: false,
    quote: t[`${badgeNames.SCHEDULE_ALCHEMIST}_QUOTE`]
  },
  {
    key: badgeNames.TASK_NINJA,
    name: t[badgeNames.TASK_NINJA],
    icon: '⚔️',
    color: '#272E46',
    steps: [BadgeStep.CREATE_TASK_STEP, BadgeStep.TIME_NEEDED_STEP, BadgeStep.START_AHEAD_STEP],
    givesFreeTrial: true,
    quote: t[`${badgeNames.TASK_NINJA}_QUOTE`]
  },
  {
    key: badgeNames.PLANNING_GRADUATE,
    name: t[badgeNames.PLANNING_GRADUATE],
    icon: '🎓',
    color: '#90F1F8',
    steps: [BadgeStep.PLAN_TASK_STEP],
    givesFreeTrial: true,
    quote: t[`${badgeNames.PLANNING_GRADUATE}_QUOTE`]
  },
  {
    key: badgeNames.ACE_OF_INITIATIVE,
    name: t[badgeNames.ACE_OF_INITIATIVE],
    icon: '♠️',
    color: '#B6EBCB',
    steps: [BadgeStep.TRACK_PROGRESS_STEP],
    givesFreeTrial: true,
    quote: t[`${badgeNames.ACE_OF_INITIATIVE}_QUOTE`]
  },
  {
    key: badgeNames.PRECISION_UNICORN,
    name: t[badgeNames.PRECISION_UNICORN],
    icon: '🦄',
    color: '#F9D9FC',
    steps: [BadgeStep.AWAKE_TIME_STEP, BadgeStep.STUDY_TIME_STEP],
    givesFreeTrial: false,
    quote: t[`${badgeNames.PRECISION_UNICORN}_QUOTE`]
  },
  {
    key: badgeNames.GOD_OF_TIME,
    name: t[badgeNames.GOD_OF_TIME],
    icon: '⏳',
    color: '#FAF69D',
    steps: [BadgeStep.CUSHION_STEP],
    givesFreeTrial: false,
    quote: t[`${badgeNames.GOD_OF_TIME}_QUOTE`]
  }
];

export const submitStepAnswer = (substep, step, setSubmitting, correct, checkSubstep) => {
  setSubmitting(true);
  setTimeout(() => {
    setSubmitting(false);
    if (correct) {
      checkSubstep({ step, substep });
    }
  }, 800);
};

export const QuizQuestion = styled(RegularText)`
  margin-bottom: 5px;
  color: ${props => props.theme.textStrongColor};
`;

export const FAQExamples = [
  {
    label: 'Lorem ipsum dolor sit amet?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus adipiscing pharetra ullamcorper sodales euismod Vestibulum, in in mollis erat see more'
  },
  {
    label: 'Lorem ipsum dolor sit ametp haretra ullamcorper?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus adipiscing pharetra ullamcorper sodales euismod Vestibulum, in in mollis erat see more'
  },
  {
    label: 'Lorem ipsum?',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus adipiscing pharetra ullamcorper sodales euismod Vestibulum, in in mollis erat see more'
  }
];

export const CONFETTI_CONFIG: object = {
  angle: 90,
  spread: 50,
  startVelocity: 50,
  elementCount: 200,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: '9px',
  height: '9px',
  colors: [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548'
  ]
};

/**
 * rounds number to provided number of decimals
 */
export const roundNumber = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
