import { eventsNetwork } from './network';
import { ApiResponse } from 'apisauce';

export interface EventNotificationsApi {
  deleteUser: () => Promise<ApiResponse<void>>;

  setUserTimezone: (timezone: string) => Promise<ApiResponse<void>>;

  changeUserActiveSemester: (semesterId: number) => Promise<ApiResponse<void>>;
}

const BASE_URL = '/users';

const deleteUser = () => eventsNetwork.delete<void>(`${BASE_URL}`);

const setUserTimezone = (timezone: string) => eventsNetwork.post<void>(`${BASE_URL}/set-timezone`, { timezone });

const changeUserActiveSemester = (semesterId: number) =>
  eventsNetwork.put<void>(`${BASE_URL}/change-active-semester`, { semesterId });

export const eventNotificationsApi: EventNotificationsApi = {
  deleteUser,
  setUserTimezone,
  changeUserActiveSemester
};
