import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { Column, FlexColumn, Row } from '../../common/layoutUtils';
import { LargeText, RegularText, SmallText } from '../../../utils/typography';
import t from '../../../i18n/t';
import { FieldArray, Formik, FormikProps, getIn } from 'formik';
import ScrollGradient from '../../common/ScrollGradient';
import { ReadingSourceInfoDto, TaskCategoryDto, ThinCourseDto } from 'shovel-lib/types';
import { array as yupArray, object as yupObject, string as yupString } from 'yup';
import { FormFieldName } from '../../../utils/enums';
import { bindActionCreators, Dispatch } from 'redux';
import { taskActions } from '../../../state/task';
import { connect } from 'react-redux';
import Button from '../../common/buttons/Button';
import RepeatTaskRow from './RepeatTaskRow';
import { openConfirmationDialog } from '../../../state/common/actions';
import { DEFAULT_TASK_CATEGORY_EMOJI } from '../../../utils/constants/task';
import InfoBox from '../../common/InfoBox';
import { secondsToDurationInMinutes } from '../../../utils/filters';
import ButtonWithLoader from '@components/common/buttons/ButtonWithLoader';
import { DialogActions } from '@components/common/ModalDialog';

type OwnProps = {
  tasks: any;
  course: ThinCourseDto;
  category?: TaskCategoryDto;
  readingSource?: ReadingSourceInfoDto;
  taskNamesCopied?: boolean;
  theme: any;
  previous: any;
  close: Function;
  afterSubmit?: Function;
  isCalendarDialog?: boolean;
  planTaskEvent?: any;
  customTableStyle?: any;
};

type PropsFromDispatch = {
  createTasks: typeof taskActions.createTasks.request;
  openConfirmationDialog: typeof openConfirmationDialog;
};

type Props = PropsFromDispatch & OwnProps;

const validationSchema = yupObject().shape({
  tasks: yupArray().of(
    yupObject().shape({
      name: yupString().required(t.REQUIRED)
    })
  )
});

class CreateRepeatTasks extends PureComponent<Props> {
  //TODO: add column description when we will have rte in repeat
  getColumns = () => {
    if (!this.props.tasks.find(t => t[FormFieldName.READING_SOURCE])) {
      return [t.DUE, t.TASK_NAME, t.START_AHEAD, t.TASK_ESTIMATE];
    }

    return [t.DUE, t.TASK_NAME, t.START_AHEAD, t.PAGES, t.TASK_ESTIMATE];
  };

  goBack = dirty => {
    if (dirty) {
      this.props.openConfirmationDialog({
        title: t.WARNING,
        message: t.GO_BACK_DIALOG_TEXT,
        action: this.props.previous,
        understandCheck: false
      });
    } else {
      this.props.previous();
    }
  };

  getEstimateForReadingSource = pageRanges => {
    const { readingSource } = this.props;
    if (readingSource) {
      let sum = 0;
      pageRanges.forEach(page => (sum = page.from && page.to ? sum + (page.to - page.from + 1) : sum + page.pages));
      return (readingSource?.secondsPerPage / 60) * sum;
    }
    return 0;
  };

  hasErrors = errors => {
    // errors.tasks[0]  !== null is because of copied tasks
    return errors.tasks?.length > 0 && errors.tasks[0] !== null;
  };

  isTouched = touched => {
    // touched.tasks[0] !== null is because of copied tasks
    return touched.tasks && touched.tasks[0] !== null;
  };

  render() {
    const {
      tasks,
      course,
      category,
      theme,
      taskNamesCopied,
      readingSource,
      planTaskEvent,
      isCalendarDialog,
      customTableStyle
    } = this.props;
    const columns = this.getColumns();
    const ButtonsWrapper = isCalendarDialog ? DialogActions : DialogButtons;
    const buttonsWrapperProps = isCalendarDialog ? { position: 'flex-end' } : {};
    return (
      <Container>
        <Title>
          {t.CREATING_TASKS}
          {course && (
            <>
              {`${t.IN} `}
              <span style={{ color: course.colorHex }}>{course.name}</span>
            </>
          )}
          {category && (
            <>
              <span>{` | ${category.emoji || DEFAULT_TASK_CATEGORY_EMOJI}`}</span>
              <span style={{ color: theme.textStrongColor }}>{category.name}</span>
            </>
          )}
          {readingSource && (
            <>
              <span style={{ color: theme.textStrongColor }}>{` | ${readingSource.name} `}</span>
              <span>{`(${secondsToDurationInMinutes(readingSource.secondsPerPage)})`}</span>
            </>
          )}
        </Title>
        <Formik
          initialValues={{ tasks }}
          validationSchema={validationSchema}
          onSubmit={values => {
            this.props.createTasks({
              tasks: values.tasks,
              colorHex: this.props.course?.colorHex,
              courseCorrelationId: this.props.course?.correlationId,
              title: this.props.course?.name,
              planTaskEvent
            });
            this.props.afterSubmit?.();
          }}
        >
          {({ values, errors, setFieldValue, dirty, touched, handleSubmit }: FormikProps<{ tasks: any }>) => {
            return (
              <>
                <Hint
                  message={t.NO_TASK_NAME_HINT}
                  /*@ts-ignore*/
                  visible={!taskNamesCopied || (this.hasErrors(errors) && this.isTouched(touched))}
                  calendarDialog={isCalendarDialog}
                />

                <Table style={customTableStyle || {}}>
                  {values.tasks.length === 0 ? (
                    <NoTasks>{t.NO_TASKS}</NoTasks>
                  ) : (
                    <>
                      <Header>
                        {columns.map((column, index) => (
                          <HeaderColumn key={index}>
                            <SmallText>{column}</SmallText>
                          </HeaderColumn>
                        ))}
                      </Header>
                      <TableData flex>
                        <FieldArray
                          name="tasks"
                          render={arrayHelpers => {
                            return (
                              <>
                                {values.tasks.map((task, index) => (
                                  <RepeatTaskRow
                                    key={index}
                                    task={task}
                                    index={index}
                                    touched={getIn(touched, `tasks.${index}.${FormFieldName.NAME}`)}
                                    error={getIn(errors, `tasks.${index}.${FormFieldName.NAME}`)}
                                    setFieldValue={setFieldValue}
                                    deleteTask={arrayHelpers.remove}
                                    insertTask={arrayHelpers.insert}
                                    getEstimateForReadingSource={this.getEstimateForReadingSource}
                                    visibleHint={!taskNamesCopied || (this.hasErrors(errors) && this.isTouched(touched))}
                                  />
                                ))}
                              </>
                            );
                          }}
                        />
                      </TableData>
                    </>
                  )}
                </Table>
                <ButtonsWrapper {...buttonsWrapperProps}>
                  <Button onClick={() => this.goBack(dirty)} size={isCalendarDialog ? undefined : 'lg'} withoutBorder>
                    {t.BACK}
                  </Button>
                  <ButtonWithLoader
                    onClick={handleSubmit}
                    size={isCalendarDialog ? undefined : 'lg'}
                    filled
                    disabled={values.tasks.length === 0}
                    action={taskActions.createTasks}
                    callback={this.props.close}
                    text={t.CREATE}
                    minWidth={103}
                    style={{ marginLeft: 5 }}
                  />
                </ButtonsWrapper>
              </>
            );
          }}
        </Formik>
      </Container>
    );
  }
}

const mapStateToDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createTasks: taskActions.createTasks.request,
      openConfirmationDialog: openConfirmationDialog
    },
    dispatch
  );
export default connect(null, mapStateToDispatch)(withTheme(CreateRepeatTasks));

const Container = styled(FlexColumn)`
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  position: relative;
  padding-top: 15px;
`;

const Title = styled(LargeText)`
  display: inline-flex;
  padding-left: 35px;
  box-sizing: border-box;
  white-space: pre;
`;

const Table = styled(FlexColumn)`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const HeaderColumn = styled(Column)`
  position: relative;
  padding: 10px 0;
  text-transform: uppercase;
`;

export const Header = styled(Row)`
  align-items: stretch;
  margin-left: 25px;
  margin-right: 20px;
  box-sizing: border-box;
  > * {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    flex: 1;
    flex-shrink: 0;
  }
`;

const TableData = styled(ScrollGradient)`
  overflow: auto;
`;

const Hint = styled(InfoBox)<{ visible?: boolean; calendarDialog?: boolean }>`
  width: fit-content;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  margin: ${props => (props.visible ? '25px auto 30px auto' : `${props.calendarDialog ? 0 : 15}px auto 0px auto`)};
`;

const NoTasks = styled(RegularText)`
  margin: auto;
`;

const DialogButtons = styled(Row)`
  justify-content: flex-end;
`;
