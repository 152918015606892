import { CalendarEvent, CalendarEventType, CreateEventDto, HolidayType } from 'shovel-lib/types';
import { now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import colors, { getTextColor } from '../colors';
import { COMMITMENT_EVENT_CLASS_NAME, CUSTOM_EVENT_CLASS_NAME, SLEEP_TIME_DARK_BACKGROUND_COLOR } from './calendarConstants';
import t from '../../i18n/t';
import { lightTheme } from '../../assets/themes';
import { CourseSource } from '../../state/ics/reducers';
import { calendarStylingOptions, isImportedEvent } from '../../utils/calendar/calendarUtils';
import { calendarStylingType } from '@state/settings/types';

export const toBackgroundAwakeTime = (event: CalendarEvent, backgroundColor: string, dark?: boolean): CalendarEvent => ({
  ...event,
  type: CalendarEventType.SLEEP_TIME,
  display: 'inverse-background',
  groupId: -1,
  title: t.ADJUST_AWAKE_TIME,
  backgroundColor: dark ? SLEEP_TIME_DARK_BACKGROUND_COLOR : `${backgroundColor}4D`,
  classNames: ['sleep-time-event']
});

export const toTaskEvent = (task: any, theme: any = lightTheme) => {
  const isExam = task.duration > 0;
  const momentStart = toMomentDate(task.dueDate);
  const momentEnd = toMomentDate(task.dueDate).add(isExam ? task.duration : 1, isExam ? 'minutes' : 'second');

  const colorHex = task.colorHex || colors.randomTaskColor;
  return {
    id: task.taskId,
    title: task.title,
    start: momentStart.toISOString(),
    end: momentEnd.toISOString(),
    startAsDate: momentStart.toDate(),
    endAsDate: momentEnd.toDate(),
    groupId: `${task.id}`,
    //@ts-ignore
    type: isExam ? 'EXAM' : CalendarEventType.TASK,
    display: 'list-item',
    backgroundColor: isExam ? theme.commitmentColorFunction(colorHex) : theme.borderColor,
    textColor: colorHex,
    selectable: true,
    originalEventStart: momentStart.toISOString(),
    originalEventEnd: momentEnd.toISOString(),
    borderColor: colors.red,
    classNames: ['task-event', isExam ? 'exam-event' : ''],
    correlationId: task.courseCorrelationId,
    colorHex,
    priority: task.priority,
    busy: isExam,
    editable: false,
    task
  };
};

export const toGoogleEventColorizeAsCourse = (event: any, course: any, userSettings: any, theme: any = lightTheme) => {
  const colorHex = course?.colorHex || event.backgroundColor;
  const backgroundColor = calendarStylingOptions(theme)[userSettings.googleEventStyle.style].backgroundFunction(
    colorHex || ''
  );
  const textColor = colorHex || event.textColor;
  return {
    ...event,
    title: event.title || '(No title)',
    display: 'block',
    editable: false,
    selectable: true,
    groupId: event.id,
    type: CalendarEventType.GOOGLE_EVENT,
    classNames: ['google-event', course ? COMMITMENT_EVENT_CLASS_NAME : ''],
    textColor,
    backgroundColor,
    borderColor: theme.courseColorFunction(colorHex),
    courseTitle: course?.name
  };
};

export const toCalendarEvent = (
  event: CalendarEvent & { courseName?: string; isTaskCompleted?: boolean; isCustomColor?: boolean },
  defaultEventColor: string,
  userSettings: any,
  theme: any = lightTheme
) => {
  const momentStart = toMomentDate(event.start);
  const momentEnd = toMomentDate(event.end);
  const dayOfWeek = momentStart.format('dddd');

  const calendarStyles = calendarStylingOptions(theme);

  switch (event.type as any) {
    case CourseSource.CANVAS:
    case CourseSource.BRIGHTSPACE:
    case CourseSource.MOODLE:
    case CalendarEventType.COURSE:
    case CalendarEventType.ACTIVITY:
      const optionType =
        event.type === CalendarEventType.COURSE
          ? userSettings.courseEventStyle.style
          : userSettings.activityEventStyle.style;
      const backgroundColorFunction = calendarStyles[optionType].backgroundFunction;

      return {
        ...event,
        start: momentStart.toISOString(),
        end: !event.allDay
          ? momentEnd.toISOString()
          : momentEnd
              .add(1, 'day')
              .startOf('day')
              .toISOString(),
        startAsDate: momentStart.toDate(),
        endAsDate: momentEnd.toDate(),
        groupId: `${event.id}-${momentStart.toISOString()}`,
        display: 'block',
        dayOfWeek,
        //@ts-ignore
        isLms: !!event.lmsType,
        originalEventStart: event.start,
        originalEventEnd: event.end,
        textColor: event.colorHex,
        backgroundColor: backgroundColorFunction(event.colorHex || ''),
        borderColor: theme.commitmentBorderFunction(event.colorHex || ''),
        classNames: [
          COMMITMENT_EVENT_CLASS_NAME,
          event.commuteBefore ? 'has-commute-before' : '',
          event.commuteAfter ? 'has-commute-after' : ''
        ]
      };
    case CalendarEventType.HOLIDAY:
      const end = momentEnd.add(1, 'day').startOf('day');
      return {
        ...event,
        display: '',
        start: momentStart.toISOString(),
        end: end.toISOString(),
        startAsDate: momentStart.toDate(),
        endAsDate: end.toDate(),
        allDay: true,
        title: `${t.EMOJI_HOLIDAY} ${event.title}`,
        editable: false,
        dayOfWeek,
        textColor: colors.primaryPurple,
        backgroundColor: colors.primaryLight, //'rgba(208, 241, 229, 0.6)',
        borderColor: colors.primaryLight,
        classNames: ['holiday-event']
      };
    case CalendarEventType.PLANNED_TASK:
      const colorHex = event.textColor || colors.randomTaskColor;
      return {
        ...event,
        start: momentStart.toISOString(),
        end: momentEnd.toISOString(),
        startAsDate: momentStart.toDate(),
        endAsDate: momentEnd.toDate(),
        groupId: event.id,
        backgroundColor: calendarStyles[userSettings.plannedTaskEventStyle.style].backgroundFunction(colorHex || ''),
        dayOfWeek,
        display: 'block',
        borderColor: theme.commitmentBorderFunction(colorHex),
        textColor: colorHex,
        courseColor: event.textColor || colors.randomTaskColor,
        courseName: event.courseName || t.RANDOM,
        busy: true,
        isTaskCompleted: event.isTaskCompleted,
        classNames: ['planned-task-event']
      };
    case CalendarEventType.AWAKE_TIME:
      return {
        ...event,
        start: momentStart.toISOString(),
        end: momentEnd.toISOString(),
        startAsDate: momentStart.toDate(),
        endAsDate: momentEnd.toDate(),
        originalEventStart: event.start,
        originalEventEnd: event.end,
        textColor: colors.primaryPurple,
        dayOfWeek,
        groupId: `${event.id}-${momentStart.toISOString()}`,
        busy: false,
        backgroundColor: theme.awakeEventColor,
        borderColor: colors.primaryPurple,
        classNames: ['awake-time-event']
      };
    default:
      const eventEnd = !event.allDay ? momentEnd : momentEnd.add(1, 'day').startOf('day');
      const color = theme.eventColorFunction(event.isCustomColor ? event.colorHex : defaultEventColor);
      return {
        ...event,
        start: momentStart.toISOString(),
        end: eventEnd.toISOString(),
        startAsDate: momentStart.toDate(),
        endAsDate: momentEnd.toDate(),
        groupId: `${event.id}-${momentStart.toISOString()}`,
        display: 'block',
        dayOfWeek,
        borderColor: color,
        backgroundColor: color,
        colorHex: color,
        textColor: getTextColor(color),
        originalEventStart: event.start,
        originalEventEnd: eventEnd.toISOString(),
        classNames: [
          CUSTOM_EVENT_CLASS_NAME,
          event.commuteBefore ? 'has-commute-before' : '',
          event.commuteAfter ? 'has-commute-after' : ''
        ]
      };
  }
};

export const toWarningAwakeTimeOverlapEvent = (event: CalendarEvent) => ({
  ...event,
  overlapsWithSleepTime: true
});

export const toStudyTimeEvent = (event: CalendarEvent) => ({
  ...event,
  display: 'background',
  selectable: false,
  editable: false,
  classNames: [...(event.classNames || []), 'study-time-event']
});

export const toExtraTimeEvent = (event: CalendarEvent) => ({
  ...event,
  display: 'background',
  classNames: [...(event.classNames || []), 'extra-time-event']
});

export const createCommuteBefore = (event: CalendarEvent, userSettings: any, theme: any) => {
  const isLms = isImportedEvent(event.type);
  const isEvent = event.type === CalendarEventType.EVENT;
  const isCourse = event.type === CalendarEventType.COURSE;
  const optionType = isCourse || isLms ? userSettings.courseEventStyle.style : userSettings.activityEventStyle.style;
  const backgroundColorFunction = calendarStylingOptions(theme)[optionType].backgroundFunction;

  return {
    id: event.id,
    groupId: `${event.id}-${event.start}`,
    start: toMomentDate(event.start)
      .subtract(event.commuteBefore!, 'minutes')
      .toISOString(),
    end: event.start,
    startAsDate: toMomentDate(event.start)
      .subtract(event.commuteBefore!, 'minutes')
      .toDate(),
    // @ts-ignore
    endAsDate: event.startAsDate,
    type: CalendarEventType.COMMUTE_BEFORE,
    hasSidebar:
      isCourse || isLms ? userSettings.courseEventStyle.sidebar : !isEvent ? userSettings.activityEventStyle.sidebar : false,
    backgroundColor: isEvent ? event.backgroundColor : backgroundColorFunction(event.colorHex || ''),
    colorHex: event.colorHex,
    textColor: event.textColor,
    borderColor: isEvent ? event.borderColor : theme.commitmentBorderFunction(event.colorHex || ''),
    correlationId: event.correlationId,
    originalEventStart: event.start,
    classNames: ['commute-before', event.type === CalendarEventType.EVENT ? 'custom-event-commute' : ''],
    durationEditable: false,
    busy: event.busy
  };
};

export const createCommuteAfter = (event: CalendarEvent, userSettings: any, theme: any) => {
  const isLms = isImportedEvent(event.type);
  const isEvent = event.type === CalendarEventType.EVENT;
  const isCourse = event.type === CalendarEventType.COURSE;
  const optionType = isCourse || isLms ? userSettings.courseEventStyle.style : userSettings.activityEventStyle.style;
  const backgroundColorFunction = calendarStylingOptions(theme)[optionType].backgroundFunction;
  return {
    id: event.id,
    groupId: `${event.id}-${event.start}`,
    start: event.end,
    end: toMomentDate(event.end)
      .add(event.commuteAfter!, 'minutes')
      .toISOString(),
    // @ts-ignore
    startAsDate: event.endAsDate,
    endAsDate: toMomentDate(event.end)
      .add(event.commuteAfter!, 'minutes')
      .toDate(),
    type: CalendarEventType.COMMUTE_AFTER,
    hasSidebar:
      isCourse || isLms ? userSettings.courseEventStyle.sidebar : !isEvent ? userSettings.activityEventStyle.sidebar : false,
    backgroundColor: isEvent ? event.backgroundColor : backgroundColorFunction(event.colorHex || ''),
    colorHex: event.colorHex,
    textColor: event.textColor,
    borderColor: isEvent ? event.borderColor : theme.commitmentBorderFunction(event.colorHex || ''),
    correlationId: event.correlationId,
    originalEventStart: event.start,
    classNames: ['commute-after', event.type === CalendarEventType.EVENT ? 'custom-event-commute' : ''],
    durationEditable: false,
    busy: event.busy
  };
};

export const TermHolidayToCreateEvent = (holiday: HolidayType): CreateEventDto => ({
  info: {
    id: holiday.id,
    title: holiday.name,
    start: toMomentDate(holiday.starts).toDate(),
    end: toMomentDate(holiday.ends)
      .endOf('day')
      .toDate(),
    busy: holiday.deleteStudyTime || false,
    type: CalendarEventType.HOLIDAY,
    correlationId: holiday.correlationId
  }
});

export const EventToTermHoliday = (holiday: CalendarEvent): HolidayType => ({
  id: holiday.id!,
  name: holiday.title!,
  starts: holiday.start,
  ends: holiday.end,
  deleteStudyTime: holiday.busy || false,
  type: CalendarEventType.HOLIDAY,
  correlationId: holiday.correlationId!
});
