import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { LOGIN } from './utils/constants/routes';
import { RootState } from '@state/types';
import { getId, getSemesterAuth, getSemesterStatus } from './state/semester/selectors';
import { SemesterStatus, SemesterUser } from 'shovel-lib/types';

type OwnProps = {
  path: string;
  isPrivate: boolean;
};

type PropsFromState = {
  auth?: SemesterUser;
  semesterId?: number;
  semesterStatus: SemesterStatus | null;
};

type Props = OwnProps & PropsFromState & any;

class ConditionalRoutes extends Component<Props> {
  render() {
    const { auth, location, children, redirectTo, isPrivate, semesterStatus, semesterId } = this.props;

    const isLoggedIn = !!auth && !!auth.email;

    const shouldRender = isLoggedIn === isPrivate;

    if (!shouldRender) {
      const defaultPathname = semesterStatus === SemesterStatus.CREATED ? `/${semesterId}/term` : `/${semesterId}/home`;
      return (
        <Redirect
          to={
            redirectTo
              ? {
                  pathname: location.pathname
                }
              : {
                  pathname: isLoggedIn ? defaultPathname : LOGIN,
                  state: { from: location }
                }
          }
        />
      );
    }

    return children;
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: getSemesterAuth(state),
  semesterId: getId(state),
  semesterStatus: getSemesterStatus(state)
});

export default connect(mapStateToProps, {})(ConditionalRoutes);
