import { RootState } from '@state/types';

const getAuth = (state: RootState) => {
  return state.auth;
};

const getOrganizationRegistrationLink = (state: RootState) => {
  return state.auth.organizationRegistrationLink;
};

export { getAuth, getOrganizationRegistrationLink };
