import React, { createRef, PureComponent, RefObject } from 'react';
import { Quill } from 'react-quill';
import MagicUrl from 'quill-magic-url';
import 'react-quill/dist/quill.snow.css';
import { EMOJI_PICKER, SUBTITLE_BE_MAX_LENGTH, SUBTITLE_MAX_LENGTH } from '@utils/constants/task';
import { withTheme } from 'styled-components';
import { RegularText } from '@utils/typography';
import TippyTooltip from '../tooltip/TippyTooltip';
import t from '@i18n/t';
import { ClickableLink, editorFormats } from '@utils/editorHelpers';
import EmojiPicker from '../emoji/EmojiPicker.loader';
import { CustomLinkPopup, CustomToolbarColorPicker, CustomToolbarDropdownComponent } from './EditorToolbarComponents';
import MaterialIcon from '../icons/MaterialIcon';
import { isMobile } from '@utils/screenUtils';
import { SelectedOptionWrapper, Container, Error, CustomEditor, LINK_POPUP_WIDTH } from './Editor.styles';

type Props = {
  value?: string;
  placeholder?: string;
  save?: any;
  onChange: any;
  theme: any;
  scrollToBottom?: any;
  className?: any;
};

type State = {
  text?: string;
  error: string;
  emojiOpen: boolean;
  headerOpen: boolean;
  alignOpen: boolean;
  textColorOpen: boolean;
  bgColorOpen: boolean;
  isFocused: boolean;
};

Quill.register('formats/link', ClickableLink, true);
Quill.register('modules/magicUrl', MagicUrl);
// quick fix for crashing task dialog
// Quill.register('modules/clink', quill => {
//   let currentLink: any = null;
//   quill.container.addEventListener('mouseover', evt => {
//     if (evt.target.tagName === 'A') {
//       currentLink = evt.target;
//       currentLink!.setAttribute('contenteditable', false);
//     } else if (currentLink) {
//       currentLink.removeAttribute('contenteditable');
//       currentLink = null;
//     }
//   });
// });

class Editor extends PureComponent<Props, State> {
  reactQuillRef;
  headerRef: RefObject<any> = createRef();
  alignRef: RefObject<any> = createRef();
  colorPickerRef: RefObject<any> = createRef();
  bgColorPickerRef: RefObject<any> = createRef();
  linkPopupRef: RefObject<any> = createRef();

  formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'align',
    'link',
    'color',
    'background',
    'indent'
  ];

  state: State = {
    text: this.props.value || '',
    error: '',
    emojiOpen: false,
    headerOpen: false,
    alignOpen: false,
    textColorOpen: false,
    bgColorOpen: false,
    isFocused: false
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.value !== this.props.value) {
      this.setState({ text: this.props.value });
    }
  }

  resetError = () => {
    if (this.state.error) {
      this.setState({ error: '' });
    }
  };

  handleKeyDown = event => {
    //there is check for BE limit also
    //because we cannot be sure with just text length limit that on BE with all tags and styles limit wouldn't be reached
    const isLimitReached =
      this.reactQuillRef.getEditor().getLength() > SUBTITLE_MAX_LENGTH ||
      this.reactQuillRef.getEditor().getContents().length > SUBTITLE_BE_MAX_LENGTH;
    if (this.reactQuillRef.getEditor().getLength() > SUBTITLE_MAX_LENGTH && event.key !== 'Backspace') {
      event.preventDefault();
      this.setState({ error: t.MAX_CHAR_ERROR });
    } else {
      this.resetError();
    }
  };

  checkCopiedTextLength = (delta, editor) => {
    const ops = delta.ops;
    const previousTextLength = ops[0].retain || 0;
    let copiedTextLength = 0;
    const startIndex = ops.length === 1 ? 0 : 1;
    ops.forEach((op, index) => {
      if (index >= startIndex) {
        copiedTextLength += op.insert ? op.insert.length : 0;
      }
    });
    if (copiedTextLength + previousTextLength > SUBTITLE_MAX_LENGTH) {
      this.setState({ error: t.MAX_CHAR_ERROR });
      const updatedOps = previousTextLength === 0 ? [{ delete: copiedTextLength }] : [ops[0], { delete: copiedTextLength }];
      this.reactQuillRef.getEditor().updateContents(updatedOps);
    }
  };

  togglePopup = (popupName, value: boolean, focus?: boolean) => {
    switch (popupName) {
      case editorFormats.HEADER:
        this.setState({ headerOpen: value });
        break;
      case editorFormats.ALIGN:
        this.setState({ alignOpen: value });
        break;
      case editorFormats.COLOR:
        this.setState({ textColorOpen: value });
        //this is because when is clicked outside of picker, editor loses focus, toolbar disappears and tippy stays without ref
        //focus && this.reactQuillRef.getEditor().focus();
        break;
      case editorFormats.BACKGROUND:
        this.setState({ bgColorOpen: value });
        //focus && this.reactQuillRef.getEditor().focus();
        break;
      default:
        this.setState({ emojiOpen: value });
        break;
    }
  };

  insertEmoji = emoji => {
    const editor = this.reactQuillRef.getEditor();
    let selectionRange = editor.getSelection();
    if (editor.getLength() >= SUBTITLE_MAX_LENGTH) {
      return;
    }
    //if editor loses focus, put emoji at the end
    if (!selectionRange) {
      this.reactQuillRef.getEditor().focus();
      selectionRange = this.reactQuillRef.getEditor().getSelection();
    }
    const cursorPosition = selectionRange.index;
    this.reactQuillRef.getEditor().insertText(cursorPosition, emoji);
    this.togglePopup('emojiOpen', false);
  };

  format = (format: string, style: any, range?: boolean) => {
    this.reactQuillRef.getEditor().format(format, style);
    // uncomment in case that custom picker is needed
    // if (range) {
    //   this.reactQuillRef.getEditor().setSelection();
    // }
  };

  handleSelectionChange = (editor, range) => {
    if (range) {
      const format = this.reactQuillRef.getEditor().getFormat(range);
      this.headerRef.current.setSelectedFormat(format.header ? format.header.toString() : '');
      this.alignRef.current.setSelectedFormat(format.align ? format.align.toString() : '');
      const textColor = Array.isArray(format.color) ? format.color[0] : format.color;
      const bgColor = Array.isArray(format.background) ? format.background[0] : format.background;
      this.colorPickerRef.current.setColor(textColor || '');
      this.bgColorPickerRef.current.setColor(bgColor || '');
      //scroll to bottom if text is added/inserted at the end of editor (plus one is for empty char that editor adds)
      if (range.index + 1 >= editor.getLength()) {
        this.props.scrollToBottom?.();
      }
    }
  };

  handleChange = (content, delta, source, editor) => {
    this.checkCopiedTextLength(delta, editor);
    const html = editor.getHTML().toString();
    const previousText = this.state.text || '';
    this.setState({ text: html });
    if (html.length !== previousText.length) {
      this.props.onChange(html);
    }
  };

  renderCustomToolbar = () => {
    const isDisabled = !this.state.isFocused;
    return (
      <div id="toolbar">
        <CustomToolbarDropdownComponent
          ref={this.headerRef}
          setFormat={this.format}
          toggle={this.togglePopup}
          isOpen={this.state.headerOpen}
          renderTarget={(selectedOption, isDefaultOption) => {
            const target = (
              <SelectedOptionWrapper isDefaultOption={isDefaultOption} disabled={isDisabled}>
                <RegularText className={'header-label'}>{selectedOption?.label}</RegularText>
                <MaterialIcon name={'keyboard_arrow_down'} />
              </SelectedOptionWrapper>
            );
            return (
              <button
                onClick={() => this.togglePopup(editorFormats.HEADER, !this.state.headerOpen)}
                className={'header-btn'}
                disabled={isDisabled}
              >
                {isDisabled ? target : <TippyTooltip target={target} content={t.SIZE} />}
              </button>
            );
          }}
        />
        <TippyTooltip target={<button className="ql-bold" disabled={isDisabled} />} content={t.BOLD} />
        <TippyTooltip target={<button className="ql-italic" disabled={isDisabled} />} content={t.ITALIC} />
        <TippyTooltip target={<button className="ql-underline" disabled={isDisabled} />} content={t.UNDERLINE} />
        <TippyTooltip target={<button className="ql-strike" disabled={isDisabled} />} content={t.STRIKE} />
        <TippyTooltip target={<button className="ql-list" value="ordered" disabled={isDisabled} />} content={t.NUM_LIST} />
        <TippyTooltip target={<button className="ql-list" value="bullet" disabled={isDisabled} />} content={t.BULLET_LIST} />
        {!isMobile && (
          <>
            <TippyTooltip target={<button className="ql-blockquote" disabled={isDisabled} />} content={t.QUOTE} />
            <TippyTooltip target={<button className="ql-link" disabled={isDisabled} />} content={t.INSERT_LINK} />
          </>
        )}
        <CustomToolbarColorPicker
          ref={this.colorPickerRef}
          onClick={this.format}
          theme={this.props.theme}
          open={this.state.textColorOpen}
          toggle={this.togglePopup}
          disabled={isDisabled}
        />
        <CustomToolbarColorPicker
          ref={this.bgColorPickerRef}
          onClick={this.format}
          isBgColor
          theme={this.props.theme}
          open={this.state.bgColorOpen}
          toggle={this.togglePopup}
          disabled={isDisabled}
        />
        <CustomToolbarDropdownComponent
          ref={this.alignRef}
          isOpen={this.state.alignOpen}
          renderTarget={(selectedOption, isDefaultOption) => {
            const target = (
              <SelectedOptionWrapper align isDefaultOption={isDefaultOption} disabled={isDisabled}>
                {selectedOption.icon}
              </SelectedOptionWrapper>
            );
            return (
              <button onClick={() => this.togglePopup(editorFormats.ALIGN, !this.state.alignOpen)} disabled={isDisabled}>
                {isDisabled ? target : <TippyTooltip target={target} content={t.ALIGN} />}
              </button>
            );
          }}
          isAlign
          setFormat={this.format}
          toggle={this.togglePopup}
        />

        {!isMobile && (
          <EmojiPicker
            target={
              <button className={'emoji-picker'} onClick={() => this.togglePopup('emojiOpen', true)} disabled={isDisabled}>
                {EMOJI_PICKER}
              </button>
            }
            visible={this.state.emojiOpen}
            onSelect={emoji => this.insertEmoji(emoji)}
            onClickOutside={() => this.togglePopup('emojiOpen', false)}
            controlled
            theme={this.props.theme}
            tooltip={t.INSERT_EMOJI}
            disabled={isDisabled}
          />
        )}
        <TippyTooltip target={<button className="ql-clean" disabled={isDisabled} />} content={t.CLEAR_FORMAT} />
      </div>
    );
  };

  setLinkPopupPosition = () => {
    const editorWidth = document.getElementsByClassName('ql-editor')[0].clientWidth;
    const position = this.reactQuillRef.getEditor().getBounds(this.reactQuillRef.getEditor().getSelection());
    const { top } = position;
    const left = position.left > editorWidth - LINK_POPUP_WIDTH ? editorWidth - LINK_POPUP_WIDTH : position.left;
    this.linkPopupRef.current.setPosition({ left, top });
  };

  renderEditor = () => {
    const { save, placeholder, value } = this.props;
    const { text } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <CustomEditor
          ref={el => (this.reactQuillRef = el)}
          tabIndex={1}
          theme="snow"
          value={text}
          placeholder={placeholder}
          onChange={(content, delta, source, editor) => this.handleChange(content, delta, source, editor)}
          onKeyDown={this.handleKeyDown}
          onBlur={(previousRange, source, editor) => {
            if (source === 'user') {
              this.state.isFocused && !this.linkPopupRef.current.state.position && this.setState({ isFocused: false });
              if (value !== editor.getHTML().toString()) {
                save?.(editor.getHTML().toString());
              }
            }
          }}
          onChangeSelection={(range, source, editor) => this.handleSelectionChange(editor, range)}
          onFocus={(range, source, editor) => !this.state.isFocused && this.setState({ isFocused: true })}
          modules={{
            // clink: true,
            magicUrl: {
              // Regex used to check URLs during typing
              //   const urlRegex = ;
              urlRegularExpression: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/i,
              // Regex used to check URLs on paste
              globalRegularExpression: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/gi
            },
            toolbar: {
              container: '#toolbar',
              handlers: {
                link: this.setLinkPopupPosition
              }
            },
            clipboard: {
              matchVisual: false
            }
          }}
          formats={this.formats}
        />
        <CustomLinkPopup ref={this.linkPopupRef} setFormat={this.format} />
      </div>
    );
  };

  render() {
    const { error, isFocused } = this.state;
    return (
      <Container error={!!error} isFocused={isFocused} className={this.props.className}>
        {this.renderCustomToolbar()}
        {this.renderEditor()}
        <Error>{this.state.error}</Error>
      </Container>
    );
  }
}

export default withTheme(Editor);
