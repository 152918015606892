import { RootState } from '@state/types';
import { extractActionNameWithoutSuffix } from '../common/utils';
import { setupTerm } from './actions';

const getCurrentTermStep = (state: RootState) => {
  return state.terms.termStep;
};

const getCountries = (state: RootState) => {
  return state.terms.countries;
};

const getTimezones = (state: RootState) => {
  return state.terms.timezones;
};

const isSetupInProgress = (state: RootState) => {
  const setupTermType = extractActionNameWithoutSuffix(setupTerm.request);
  return state.common.loaders[setupTermType];
};
const getSetupTermError = (state: RootState) => {
  const setupTermType = extractActionNameWithoutSuffix(setupTerm.request);
  return state.common.errors[setupTermType];
};

const getAwakeTime = ({ terms }: RootState) => terms.awakeTime;

export { getCurrentTermStep, getCountries, getTimezones, isSetupInProgress, getSetupTermError, getAwakeTime };
