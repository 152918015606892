import { createSelector } from 'reselect';
import { TimerStatus } from 'shovel-lib/types';
import { RootState } from '@state/types';

export const getActiveTimer = ({ timer }: RootState) => {
  const { plannedTaskId, masterTaskId, status, totalTimePassed, title } = timer;
  return !plannedTaskId || status === TimerStatus.INITIAL
    ? undefined
    : {
        status,
        plannedTaskId,
        masterTaskId,
        title,
        timePassedInSeconds: totalTimePassed
      };
};

export const getPulsingTaskId = ({ timer }) => (timer.status === TimerStatus.PAUSED ? timer.plannedTaskId : undefined);
export const getTimerTaskId = ({ timer }) => (timer.status !== TimerStatus.INITIAL ? timer.plannedTaskId : undefined);

export const getActiveTimerInfo = createSelector(
  [(state: RootState) => state.timer.status, (state: RootState) => state.timer.plannedTaskId],
  (status, plannedTaskId) => ({
    status,
    plannedTaskId
  })
);
