import { initialMonthFilterState, MonthFilterT } from '@state/settings/types';
import { PileStateOptions, SetAwakeTimesRequest } from 'shovel-lib/types';
import { Theme } from '../assets/themes';
import { GroupByField } from '../components/course/types';
import { AppTheme } from './theme/types';

const REFRESH_TOKEN_KEY = 'refreshToken';
const USER_ID_KEY = 'id';
const DO_NOT_SHOW_WARNING_ON_TASK_STATUS_CHANGE = '@@shovel/DNSWOTSC';
const CALENDAR_CONFIG_SLAT_HEIGHT = 'calendarSlatHeight';
const CALENDAR_DEFAULT_SLAT_HEIGHT = 1.6;
const CUSHION_WARNING_PERCENTAGE = 'cushionWarningPercentage';
const DEFAULT_CUSHION_WARNING_PERCENTAGE = 40;
const THEME_KEY = 'THEME';
const FCM_TOKEN_KEY = 'fcmToken';
const SIDEBAR_COLLAPSED = 'SIDEBAR_COLLAPSED';
export const CUSHION_SIDEBAR_COLLAPSED = 'CUSHION_SIDEBAR_COLLAPSED';
const COURSES_EXPANDED = 'COURSES_EXPANDED';
const ACTIVITIES_EXPANDED = 'ACTIVITIES_EXPANDED';
const GOOGLE_CALENDARS_EXPANDED = 'GOOGLE_CALENDARS_EXPANDED';
const ONBOARDING_BAR_EXPANDED = 'ONBOARDING_BAR_EXPANDED';
const AWAKE_TIME_VALUES_KEY = 'AWAKE_TIME_VALUES';
const BADGE_STEP_OBVIOUS_MIKE = 'BADGE_STEP_OBVIOUS_MIKE';
const SHOW_ONBOARDING_TOUR = 'SHOW_ONBOARDING_TOUR';
const GROUP_WORKLOAD_BY = 'GROUP_WORKLOAD_BY';
const HIDDEN_WORKLOAD_COLUMNS = 'HIDDEN_WORKLOAD_COLUMNS';
const SHOW_COMPLETED_WORKLOAD = 'SHOW_COMPLETED_WORKLOAD';
const WORKLOAD_COLUMNS_WIDTH = 'WORKLOAD_COLUMNS_WIDTH';
const WORKLOAD_COLUMNS_ORDER = 'WORKLOAD_COLUMNS_ORDER';
const PLAN_SIDEBAR_COLLAPSED = 'PLAN_SIDEBAR_COLLAPSED';
const PLAN_BLOCK_DD_SELECTION = 'PLAN_BLOCK_DD_SELECTION';
const CALENDAR_VIEW = 'CALENDAR_VIEW';
const ACCESSIBILITY_KEY = '@@shovel/ACCESSIBILITY';
const CURRENT_VERSION_KEY = '@@shovel/CURRENT_VERSION';
const APP_THEME_KEY = '@@shovel/APP_THEME';
const MONTH_FILTER_KEY = '@@shovel/MONTH_FILTER';
const PILE_FILTER_KEY = '@@shovel/PILE_FILTER';
const PILE_SORT_KEY = '@@shovel/PILE_SORT';
const COURSES_WEBSITE_LINK_SHOWN_KEY = '@@shovel/COURSES_WEBSITE_LINK_SHOWN';

export interface Storage {
  getRefreshToken: () => string | null;
  setRefreshToken: (token: string) => void;
  removeRefreshToken: () => void;

  getUserId: () => string | null;
  setUserId: (userId: number) => void;
  removeUserId: () => void;

  getDoNotShowWarningForTaskChange: () => boolean;
  setDoNotShowWarningForTaskChange: (value: boolean) => void;

  getCalendarSlatHeightConfig: () => number;
  setCalendarSlatHeightConfig: (value: number) => void;

  getCushionWarningPercentage: () => number;
  setCushionWarningPercentage: (percentage: number) => void;
  removeCushionWarningPercentage: () => void;

  getTheme: () => Theme;
  setTheme: (theme: Theme) => void;

  getFcmToken: () => string | null;
  setFcmToken: (token: string) => void;
  removeFcmToken: () => void;

  getSidebarCollapsed: () => boolean;
  setSidebarCollapsed: (expanded: boolean) => void;

  getCushionSidebarCollapsed: () => boolean;
  setCushionSidebarCollapsed: (expanded: boolean) => void;

  getCoursesExpanded: () => boolean;
  setCoursesExpanded: (expanded: boolean) => void;

  getActivitiesExpanded: () => boolean;
  setActivitiesExpanded: (expanded: boolean) => void;

  getGoogleCalendarsExpanded: () => boolean;
  setGoogleCalendarsExpanded: (expanded: boolean) => void;

  getOnboardingBarExpanded: () => boolean;
  setOnboardingBarExpanded: (expanded: boolean) => void;

  getAwakeTimeValues: () => SetAwakeTimesRequest;
  setAwakeTimeValues: (values: SetAwakeTimesRequest) => void;

  getBadgeStepObviousMike: () => boolean;
  setBadgeStepObviousMike: () => void;
  clearBadgeStepObviousMike: () => void;

  getShowOnboardingBar: () => boolean;
  setShowOnboardingBar: (value: boolean) => void;
  clearShowOnboardingBar: () => void;

  getGroupWorkloadBy: () => GroupByField;
  setGroupWorkloadBy: (value: GroupByField) => void;

  getHiddenWorkloadColumns: () => string[];
  setHiddenWorkloadColumns: (columns: string[]) => void;

  getShowCompletedWorkload: () => boolean;
  setShowCompletedWorkload: (value: boolean) => void;

  getWorkloadColumnsWidth: () => any;
  setWorkloadColumnsWidth: (value: any) => void;

  getWorkloadColumnsOrder: () => any;
  setWorkloadColumnsOrder: (value: any) => void;

  getPlanSidebarCollapsed: () => boolean;
  setPlanSidebarCollapsed: (expanded: boolean) => void;

  getDeviceType: () => string | null;
  getQuillDefaultValue: () => string;

  getPlanBlockDDSelection: () => any;
  setPlanBlockDDSelection: (option: any) => void;

  getCalendarView: () => any;
  setCalendarView: (view: any) => void;

  isAccessibilityOn: () => boolean;
  setAccessibilityOn: (value: boolean) => void;

  getCurrentVersion: () => string;
  setCurrentVersion: (value: string) => void;

  getAppTheme: () => AppTheme;
  setAppTheme: (theme: AppTheme) => void;

  getMonthFilter: () => MonthFilterT;
  saveMonthFilter: (filter: MonthFilterT) => void;

  getPileFilter: () => any;
  savePileFilter: (filter: any) => void;

  getPileSort: () => any;
  savePileSort: (sort: any) => void;

  isCoursesWebsiteLinkShown: () => boolean;
  setCoursesWebsiteLinkShown: () => void;
}

const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_KEY, token);
const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY);

const getUserId = () => localStorage.getItem(USER_ID_KEY);
const setUserId = (userId: number) => localStorage.setItem(USER_ID_KEY, userId.toString());
const removeUserId = () => localStorage.removeItem(USER_ID_KEY);

// todo use later
const getDoNotShowWarningForTaskChange = () =>
  (localStorage.getItem(DO_NOT_SHOW_WARNING_ON_TASK_STATUS_CHANGE) || 'false') === 'true';

// todo use later
const setDoNotShowWarningForTaskChange = (value: boolean) =>
  localStorage.setItem(DO_NOT_SHOW_WARNING_ON_TASK_STATUS_CHANGE, value.toString());

const getCalendarSlatHeightConfig = () => {
  const height = localStorage.getItem(CALENDAR_CONFIG_SLAT_HEIGHT);
  return height ? Number.parseFloat(height) : CALENDAR_DEFAULT_SLAT_HEIGHT;
};

const setCalendarSlatHeightConfig = (height: number) => localStorage.setItem(CALENDAR_CONFIG_SLAT_HEIGHT, height.toString());

const getCushionWarningPercentage = () =>
  localStorage.getItem(CUSHION_WARNING_PERCENTAGE)
    ? parseInt(localStorage.getItem(CUSHION_WARNING_PERCENTAGE)!)
    : DEFAULT_CUSHION_WARNING_PERCENTAGE;
const setCushionWarningPercentage = (percentage: number) =>
  localStorage.setItem(CUSHION_WARNING_PERCENTAGE, percentage.toString());
const removeCushionWarningPercentage = () => localStorage.removeItem(CUSHION_WARNING_PERCENTAGE);

const getTheme = () => {
  const theme = localStorage.getItem(THEME_KEY);
  return theme === 'dark' ? 'dark' : 'light';
};
const setTheme = (theme: Theme) => localStorage.setItem(THEME_KEY, theme);

const getFcmToken = () => {
  return localStorage.getItem(FCM_TOKEN_KEY);
};
const setFcmToken = (token: string) => localStorage.setItem(FCM_TOKEN_KEY, token);
const removeFcmToken = () => localStorage.removeItem(FCM_TOKEN_KEY);

const getSidebarCollapsed = () => {
  const expanded = localStorage.getItem(SIDEBAR_COLLAPSED);
  return JSON.parse(expanded || 'false');
};
const setSidebarCollapsed = (expanded: boolean) => localStorage.setItem(SIDEBAR_COLLAPSED, expanded.toString());

const getCushionSidebarCollapsed = () => {
  const expanded = localStorage.getItem(CUSHION_SIDEBAR_COLLAPSED);
  return JSON.parse(expanded || 'false');
};
const setCushionSidebarCollapsed = (expanded: boolean) =>
  localStorage.setItem(CUSHION_SIDEBAR_COLLAPSED, expanded.toString());

const getActivitiesExpanded = () => {
  const expanded = localStorage.getItem(ACTIVITIES_EXPANDED);
  return JSON.parse(expanded || 'true');
};
const setActivitiesExpanded = (expanded: boolean) => localStorage.setItem(ACTIVITIES_EXPANDED, expanded.toString());

const getCoursesExpanded = () => {
  const expanded = localStorage.getItem(COURSES_EXPANDED);
  return JSON.parse(expanded || 'true');
};
const setCoursesExpanded = (expanded: boolean) => localStorage.setItem(COURSES_EXPANDED, expanded.toString());

const getGoogleCalendarsExpanded = () => {
  const expanded = localStorage.getItem(GOOGLE_CALENDARS_EXPANDED);
  return JSON.parse(expanded || 'true');
};
const setGoogleCalendarsExpanded = (expanded: boolean) =>
  localStorage.setItem(GOOGLE_CALENDARS_EXPANDED, expanded.toString());

const getOnboardingBarExpanded = () => {
  const expanded = localStorage.getItem(ONBOARDING_BAR_EXPANDED);
  return JSON.parse(expanded || 'true');
};
const setOnboardingBarExpanded = (expanded: boolean) => localStorage.setItem(ONBOARDING_BAR_EXPANDED, expanded.toString());

const getAwakeTimeValues = () => JSON.parse(localStorage.getItem(AWAKE_TIME_VALUES_KEY) || 'null');
const setAwakeTimeValues = (values: SetAwakeTimesRequest) =>
  localStorage.setItem(AWAKE_TIME_VALUES_KEY, JSON.stringify(values));

const getBadgeStepObviousMike = () => JSON.parse(localStorage.getItem(BADGE_STEP_OBVIOUS_MIKE) || 'false');
const setBadgeStepObviousMike = () => localStorage.setItem(BADGE_STEP_OBVIOUS_MIKE, 'true');
const clearBadgeStepObviousMike = () => localStorage.removeItem(BADGE_STEP_OBVIOUS_MIKE);

const getShowOnboardingBar = () => JSON.parse(localStorage.getItem(SHOW_ONBOARDING_TOUR) || 'true');
const setShowOnboardingBar = (value: boolean) => localStorage.setItem(SHOW_ONBOARDING_TOUR, value.toString());
const clearShowOnboardingBar = () => localStorage.removeItem(SHOW_ONBOARDING_TOUR);

const getGroupWorkloadBy = () => {
  const groupBy = localStorage.getItem(GROUP_WORKLOAD_BY);
  const groupByFields = Object.values(GroupByField);
  if (groupBy === undefined || groupBy === null || !groupByFields.includes(groupBy as GroupByField)) {
    return GroupByField.ALL;
  }
  return groupBy as GroupByField;
};
const setGroupWorkloadBy = (value: GroupByField) => localStorage.setItem(GROUP_WORKLOAD_BY, value);

const getHiddenWorkloadColumns = () => JSON.parse(localStorage.getItem(HIDDEN_WORKLOAD_COLUMNS) || '["details"]');
const setHiddenWorkloadColumns = (columns: string[]) =>
  localStorage.setItem(HIDDEN_WORKLOAD_COLUMNS, JSON.stringify(columns));

const getShowCompletedWorkload = () => JSON.parse(localStorage.getItem(SHOW_COMPLETED_WORKLOAD) || 'true');
const setShowCompletedWorkload = (value: boolean) => localStorage.setItem(SHOW_COMPLETED_WORKLOAD, value.toString());

const getWorkloadColumnsWidth = () => JSON.parse(localStorage.getItem(WORKLOAD_COLUMNS_WIDTH) || '{}');
const setWorkloadColumnsWidth = (value: any) => localStorage.setItem(WORKLOAD_COLUMNS_WIDTH, JSON.stringify(value));

const getWorkloadColumnsOrder = () => {
  const columns = JSON.parse(localStorage.getItem(WORKLOAD_COLUMNS_ORDER) || '[]');
  return columns.filter(c => c !== 'complete' && c !== 'delete');
};
const setWorkloadColumnsOrder = (value: string[]) =>
  localStorage.setItem(WORKLOAD_COLUMNS_ORDER, JSON.stringify(value.filter(c => c !== 'complete' && c !== 'delete')));

const getPlanSidebarCollapsed = () => {
  const collapsed = localStorage.getItem(PLAN_SIDEBAR_COLLAPSED);
  return JSON.parse(collapsed || 'true');
};
const setPlanSidebarCollapsed = (expanded: boolean) => localStorage.setItem(PLAN_SIDEBAR_COLLAPSED, expanded.toString());

const getDeviceType = () => localStorage.getItem('deviceType');

const getQuillDefaultValue = () => localStorage.getItem('quillDefaultValue') || '';

const getPlanBlockDDSelection = () => localStorage.getItem(PLAN_BLOCK_DD_SELECTION) as PileStateOptions;

const setPlanBlockDDSelection = (option: string) => localStorage.setItem(PLAN_BLOCK_DD_SELECTION, option);

const getCalendarView = () => localStorage.getItem(CALENDAR_VIEW);

const setCalendarView = (view: string) => localStorage.setItem(CALENDAR_VIEW, view);

const isAccessibilityOn = () => localStorage.getItem(ACCESSIBILITY_KEY) === 'true';
const setAccessibilityOn = (value: boolean) => localStorage.setItem(ACCESSIBILITY_KEY, value.toString());

const getCurrentVersion = () => localStorage.getItem(CURRENT_VERSION_KEY) || '';
const setCurrentVersion = (value: string) => localStorage.setItem(CURRENT_VERSION_KEY, value);

const getAppTheme = () => {
  const theme = localStorage.getItem(APP_THEME_KEY);
  if (!theme) {
    return null;
  }
  return JSON.parse(theme);
};
const setAppTheme = (theme: AppTheme) => localStorage.setItem(APP_THEME_KEY, JSON.stringify(theme));

const getMonthFilter = () => JSON.parse(localStorage.getItem(MONTH_FILTER_KEY) || 'null') || initialMonthFilterState;

const saveMonthFilter = (monthFilter: MonthFilterT) =>
  localStorage.setItem(MONTH_FILTER_KEY, JSON.stringify(monthFilter || {}));

const getPileFilter = () => JSON.parse(localStorage.getItem(PILE_FILTER_KEY) || 'null');
const savePileFilter = (filter: any) => localStorage.setItem(PILE_FILTER_KEY, JSON.stringify(filter || {}));

const getPileSort = () => JSON.parse(localStorage.getItem(PILE_SORT_KEY) || 'null');
const savePileSort = (sort: any) => localStorage.setItem(PILE_SORT_KEY, JSON.stringify(sort || {}));

const isCoursesWebsiteLinkShown = () => localStorage.getItem(COURSES_WEBSITE_LINK_SHOWN_KEY) === 'true';
const setCoursesWebsiteLinkShown = () => localStorage.setItem(COURSES_WEBSITE_LINK_SHOWN_KEY, 'true');

const storage: Storage = {
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  getUserId,
  setUserId,
  removeUserId,
  getDoNotShowWarningForTaskChange,
  setDoNotShowWarningForTaskChange,
  getCalendarSlatHeightConfig,
  setCalendarSlatHeightConfig,
  getCushionWarningPercentage,
  setCushionWarningPercentage,
  removeCushionWarningPercentage,
  getTheme,
  setTheme,
  getFcmToken,
  setFcmToken,
  removeFcmToken,
  getSidebarCollapsed,
  setSidebarCollapsed,
  getCushionSidebarCollapsed,
  setCushionSidebarCollapsed,
  getActivitiesExpanded,
  setActivitiesExpanded,
  getCoursesExpanded,
  setCoursesExpanded,
  getGoogleCalendarsExpanded,
  setGoogleCalendarsExpanded,
  getOnboardingBarExpanded,
  setOnboardingBarExpanded,
  getAwakeTimeValues,
  setAwakeTimeValues,
  getBadgeStepObviousMike,
  setBadgeStepObviousMike,
  clearBadgeStepObviousMike,
  getShowOnboardingBar,
  setShowOnboardingBar,
  clearShowOnboardingBar,
  getGroupWorkloadBy,
  setGroupWorkloadBy,
  getHiddenWorkloadColumns,
  setHiddenWorkloadColumns,
  getShowCompletedWorkload,
  setShowCompletedWorkload,
  getWorkloadColumnsWidth,
  setWorkloadColumnsWidth,
  getWorkloadColumnsOrder,
  setWorkloadColumnsOrder,
  getPlanSidebarCollapsed,
  setPlanSidebarCollapsed,
  getDeviceType,
  getQuillDefaultValue,
  getPlanBlockDDSelection,
  setPlanBlockDDSelection,
  getCalendarView,
  setCalendarView,
  isAccessibilityOn,
  setAccessibilityOn,
  getCurrentVersion,
  setCurrentVersion,
  getAppTheme,
  setAppTheme,
  getMonthFilter,
  saveMonthFilter,
  getPileFilter,
  savePileFilter,
  getPileSort,
  savePileSort,
  isCoursesWebsiteLinkShown,
  setCoursesWebsiteLinkShown
};

export default storage;
