import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getServiceWorkerData } from '../state/common/selectors';
import { RootState } from '@state/types';
import { openConfirmationDialog } from '../state/common/actions';
import { ServiceWorkerData } from '../state/common/types';
import t from '../i18n/t';
import { alert, NotificationMode } from './common/snackbarNotifications/Notifications';

//this.props.serviceWorkerData has
/*

  firstTime: boolean;
  newVersionAvailable: boolean;
  updateDone: boolean;
  registration?: ServiceWorkerRegistration | undefined;

  these above are props swData posseses
  firstTime: true when SW is installed for the first time (eg. someone first time opens tha app or deletes SW before opening,..)
  newVersionAvailable: true when there is new SW (<=> new app version) that wants to take over everything
  updateDone: ( DIDN"T TESTED THIS ONE :D ) should be true after new SW becames active (both first time active or replacing old one ),can be used to display 'what's new' message...
  registration: is provided  ONLY when newVersionAvailable is true... it is used to call .waiting?.postMessage method to force activation of the new SW

 */

type PropsFromState = {
  serviceWorkerData: ServiceWorkerData;
};

type Props = PropsFromState & { openConfirmationDialog: typeof openConfirmationDialog };

class NewVersionChecker extends PureComponent<Props> {
  render() {
    if (!this.props.serviceWorkerData.newVersionAvailable) {
      return null;
    }

    if (this.props.serviceWorkerData.updateDone) {
      // alert(t.UPDATE_SUCCESS, NotificationMode.INFO, 5000, false);
    }

    this.props.serviceWorkerData.registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });

    // this.props.openConfirmationDialog({
    //   // that will force new SW to became active, clear old cache and fetch new app files :)
    //   action: () => this.props.serviceWorkerData.registration?.waiting?.postMessage({ type: 'SKIP_WAITING' }),
    //   title: t.NEW_SHOVEL_V_TITLE,
    //   message: t.NEW_SHOVEL_V_MSG,
    //   understandCheck: false,
    //   okLabel: t.UPDATE,
    //   hideCancel: true
    // });
    return null;
  }
}
const mapStateToProps = (state: RootState) => ({
  serviceWorkerData: getServiceWorkerData(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openConfirmationDialog: openConfirmationDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionChecker);
