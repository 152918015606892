import { RootState } from '@state/types';
import { CourseState } from './types';

export const getCourseInfo = ({ course }: RootState): CourseState => course;

export const getCourseId = ({ course }: RootState) => {
  if (!course.id) {
    throw Error('Missing id in course state.');
  }
  return course.id;
};

export const getGradeState = ({ course }: RootState) => ({
  gradeWeightType: course.gradeWeightType,
  grade: course.grade
});

export const getTeachersState = ({ course }: RootState) => ({
  teachers: course.teachers
});

export const getCourseCorrelationId = ({ course }: RootState) => course.correlationId;

export const getCategories = ({ course }: RootState) => course.taskCategories;

export const getReadingSources = ({ course }: RootState) => course.readingSources;

export const getCourseDetailsDialog = ({ course }: RootState) => course.courseDetailsDialog;

export const getOpenedCourseId = ({ course }: RootState) => course.courseDetailsDialog.courseId;
