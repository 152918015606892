import React, { FC } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as AccessibilityIcon } from '@assets/icons/navigation/accessibility.svg';
import { RootState } from '@state/types';
import { isAccessibilityOn } from '@state/common/selectors';
import { commonActions } from '@state/common';
import TippyTooltip from '@components/common/tooltip/TippyTooltip';
import t from '@i18n/t';
import { NavIconWrapper } from '@components/navigation/partials/LogoutIcon';

type PropsFromState = {
  isAccessibilityOn: boolean;
};

type PropsFromDispatch = {
  toggleAccessibility: typeof commonActions.toggleAccessibility;
};

type Props = PropsFromState & PropsFromDispatch;

const Accessibility: FC<Props> = ({ isAccessibilityOn, toggleAccessibility }) => {
  return (
    <TippyTooltip
      target={
        <Wrapper onClick={() => toggleAccessibility()}>
          <AccessibilityIcon className={'custom-navigation-icon'} />
        </Wrapper>
      }
      content={t.ACCESSIBILITY}
      position={'right'}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isAccessibilityOn: isAccessibilityOn(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleAccessibility: commonActions.toggleAccessibility
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Accessibility));

const Wrapper = styled(NavIconWrapper)`
  margin: 0;
`;
