import { all, call, put, select, take, takeLatest, fork } from 'redux-saga/effects';
import { setDefaultMomentTimeZone } from 'shovel-lib/utils/timeUtils';
import { SemesterStatus } from 'shovel-lib/types';

import { callApi } from '../common/operations';
import * as actions from './actions';
import { getErrorMessage } from '../../api/network';
import { semesterApi } from '../../api';
import * as semesterSelectors from './selectors';
import { clearState, onAppStart } from '../common/actions';
import * as authOperations from '../auth/operations';
import { clearEvents, createEvent } from '../events/actions';
import * as calendarActions from '../calendar/actions';
import * as taskActions from '../task/actions';
import { getType } from 'typesafe-actions';
import { getSavedCalendars } from '../googleCalendar/actions';
import { eventNotificationsApi } from '@api/eventNotificationsApi';

function* createSemester(action: ReturnType<typeof actions.createSemester.request>) {
  const apiCall = call(semesterApi.createSemester);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.createSemester.success(response.data));
    yield call(eventNotificationsApi.changeUserActiveSemester, response.data.id);
    yield put(clearState());
    yield call(authOperations.refreshAccessToken);
    yield put(onAppStart({ location: { pathname: `/${response.data.id}/term`, state: action.payload } }));
  } else {
    yield put(actions.createSemester.failure(getErrorMessage(response)));
  }
}

function* getCurrent() {
  const apiCall = call(semesterApi.getCurrent);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    const tz = response.data.details?.timeZone || 'UTC';
    yield call(setDefaultMomentTimeZone, tz);
    yield call(eventNotificationsApi.setUserTimezone, tz);
    yield put(actions.getCurrent.success(response.data));
    if (
      response.data.user &&
      response.data.user.hasCalendars &&
      response.data.state !== SemesterStatus.CREATED &&
      response.data.state !== SemesterStatus.SEMESTER_SETUP_DONE
    ) {
      yield put(getSavedCalendars.request());
    }
  } else {
    yield put(actions.getCurrent.failure(getErrorMessage(response)));
  }
}

function* getDetailedSemester() {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(semesterApi.getSemesterDetails, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getDetailedSemester.success(response.data));
  } else {
    yield put(actions.getDetailedSemester.failure(getErrorMessage(response)));
  }
}

function* getUserSemesters() {
  const apiCall = call(semesterApi.getUserSemesters);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getUserSemesters.success(response.data));
  } else {
    yield put(actions.getUserSemesters.failure(getErrorMessage(response)));
  }
}

function* changeCurrentSemester(action: ReturnType<typeof actions.changeCurrentSemester.request>) {
  const apiCall = call(semesterApi.changeCurrentSemester, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.changeCurrentSemester.success(response.data));
    yield call(eventNotificationsApi.changeUserActiveSemester, action.payload.id);
    yield put(clearEvents());
    yield put(calendarActions.clearState());
    yield put(onAppStart({ location: { pathname: '/' } }));
  } else {
    yield put(actions.changeCurrentSemester.failure(getErrorMessage(response)));
  }
}

function* getOverallCushion() {
  const semesterId = yield select(semesterSelectors.getId);
  const apiCall = call(semesterApi.getOverallCushion, semesterId);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getOverallCushion.success(response.data));
  } else {
    yield put(actions.getOverallCushion.failure(getErrorMessage(response)));
  }
}

function* onStartCushionListener() {
  while (true) {
    const { type } = yield take([
      taskActions.saveManageTask.success,
      taskActions.setTasksStartAhead.success,
      taskActions.savePageRanges.success,
      taskActions.moveToTodo.success,
      taskActions.updateTaskStatus.success,
      taskActions.deleteTask.success,
      taskActions.createTasks.success,
      createEvent.success,
      actions.stopCushionListener
    ]);

    if (type === getType(actions.stopCushionListener)) {
      break;
    }

    yield put(actions.getOverallCushion.request());
  }
}

export default function*() {
  yield all([
    takeLatest(actions.createSemester.request, createSemester),
    takeLatest(actions.getCurrent.request, getCurrent),
    takeLatest(actions.getDetailedSemester.request, getDetailedSemester),
    takeLatest(actions.getUserSemesters.request, getUserSemesters),
    takeLatest(actions.changeCurrentSemester.request, changeCurrentSemester),
    takeLatest(actions.getOverallCushion.request, getOverallCushion),
    takeLatest(actions.startCushionListener, onStartCushionListener)
  ]);
}
