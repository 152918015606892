import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { createErrorMessageSelector, createLoadingSelector } from '../../state/common/selectors';
import { RootState } from '@state/types';

type State = {};

type OwnProps = {
  actions: string[];
};

type PropsFromState = {
  isLoading: boolean;
  error: string;
  children: any;
};

type Props = PropsFromState & OwnProps;

class WithLoadingComponent extends PureComponent<Props, State> {
  render() {
    const { isLoading, error, children } = this.props;
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>{error}</div>;
    }

    return <>{children}</>;
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isLoading: createLoadingSelector(ownProps.actions)(state.common),
  error: createErrorMessageSelector(ownProps.actions)(state.common)
});

export default connect(mapStateToProps, {})(WithLoadingComponent);
