import TimeNeededStepDialog from './steps/TimeNeededStepDialog';
import StartDateStepDialog from './steps/StartDateStepDialog';
import CushionStepDialog from './steps/CushionStepDialog';
import t from '@i18n/t';
import StepDialog from './StepDialog';
import { Substeps } from './helpers';

export const allSteps = {
  QUICK_SETUP_STEP: {
    number: '0',
    title: t.QUICK_SETUP_STEP_TITLE,
    component: StepDialog,
    video: undefined,
    substeps: {
      [Substeps.QUICK_SETUP]: t.QUICK_SETUP_STEP_DESCRIPTION
    }
  },
  SCHEDULE_STEP: {
    number: '1',
    title: t.SCHEDULE_STEP_TITLE,
    component: StepDialog,
    video: { id: 'wC9v0dEz_Ew' },
    substeps: {
      [Substeps.SCHEDULE_CLASS]: t.SCHEDULE_STEP_CLASS,
      [Substeps.SCHEDULE_ACTIVITY]: t.SCHEDULE_STEP_ACTIVITY
    }
  },
  CREATE_TASK_STEP: {
    number: '2',
    title: t.CREATE_TASK_STEP_TITLE,
    component: StepDialog,
    video: { id: 'fohs5PXkBcw' },
    substeps: {
      [Substeps.CREATE_TASK]: t.CREATE_TASK_STEP_TASK
    }
  },
  PLAN_TASK_STEP: {
    number: '3',
    title: t.PLAN_TASK_STEP_TITLE,
    component: StepDialog,
    video: { id: 'kyY19Y6HtsE' },
    substeps: {
      [Substeps.PLAN_TASK]: t.PLAN_TASK_STEP_DESCRIPTION
    }
  },
  TRACK_PROGRESS_STEP: {
    number: '4',
    title: t.TRACK_PROGRESS_STEP_TITLE,
    component: StepDialog,
    video: { id: 'a63IMhAOyCs' },
    substeps: {
      [Substeps.ADD_PROGRESS]: t.TRACK_PROGRESS_STEP_PROGRESS,
      [Substeps.COMPLETE_TASK]: t.TRACK_PROGRESS_STEP_COMPLETE
    }
  },
  AWAKE_TIME_STEP: {
    number: '5',
    title: t.AWAKE_TIME_STEP_TITLE,
    component: StepDialog,
    video: { id: 'NerGR-VzaMg' },
    substeps: {
      [Substeps.SET_AWAKE_TIME]: t.AWAKE_TIME_STEP_DESCRIPTION
    }
  },
  STUDY_TIME_STEP: {
    number: '6',
    title: t.STUDY_TIME_STEP_TITLE,
    component: StepDialog,
    video: { id: '4qPJItIb_14' },
    substeps: {
      [Substeps.SET_STUDY_TIME]: t.STUDY_TIME_STEP_DESCRIPTION
    }
  },
  TIME_NEEDED_STEP: {
    number: '7',
    title: t.TIME_NEEDED_STEP_TITLE,
    component: TimeNeededStepDialog,
    video: { id: 'a3jsJ-ja8aE' },
    substeps: {
      [Substeps.TIME_NEEDED_QUIZ]: t.TIME_NEEDED_STEP_DESCRIPTION
    }
  },
  START_AHEAD_STEP: {
    number: '8',
    title: t.START_AHEAD_STEP_TITLE,
    component: StartDateStepDialog,
    video: { id: 'O-Xpr93bzuw' },
    substeps: {
      [Substeps.START_AHEAD_QUIZ]: t.START_AHEAD_STEP_DESCRIPTION
    }
  },
  CUSHION_STEP: {
    number: '9',
    title: t.CUSHION_STEP_TITLE,
    component: CushionStepDialog,
    video: { id: '0k9DLACT5p4' },
    substeps: {
      [Substeps.CUSHION_QUIZ]: t.CUSHION_STEP_QUIZ
    }
  }
};

export const stepString = (steps: string[]) => {
  let stepString = '';
  steps.forEach((step, i) => {
    stepString += `${i !== 0 && i === steps.length - 1 ? ` ${t.AND}` : ''} ${allSteps[step].number}${
      steps.length - i > 2 ? ',' : ''
    }`;
  });
  return `${steps.length > 1 ? t.STEPS : t.STEP}${stepString}`;
};

export const badgeTooltip = (name: string, steps: string[], freeTrial: boolean) => {
  if (!steps || steps.length === 0) return '';

  let tooltip = t.BADGE_TOOLTIP;
  tooltip = tooltip.replace('${steps}', stepString(steps));
  tooltip = tooltip.replace('${name}', name);

  return `${tooltip}${freeTrial ? t.BADGE_TOOLTIP_EXTRA_DAY : ''}`;
};
