import { MonthFilterT } from '../settings/types';

export const isMonthFilterActive = ({
  tasks,
  courseCorrelationIds,
  customEvents,
  activityCorrelationIds,
  googleEvents
}: MonthFilterT) =>
  tasks ||
  customEvents ||
  googleEvents ||
  (courseCorrelationIds && courseCorrelationIds.length > 0) ||
  (activityCorrelationIds && activityCorrelationIds.length > 0);
