import { RootState } from '@state/types';
import { refreshGoogleCalendars } from './actions';
import { extractActionNameWithoutSuffix } from '../common/utils';

export const isGoogleRefreshing = ({ common }: RootState) => {
  const refreshGoogleCalendarsType = extractActionNameWithoutSuffix(refreshGoogleCalendars.request);
  return common.loaders[refreshGoogleCalendarsType];
};

export const hasGoogleEvents = ({ calendar }: RootState) => calendar.googleEvents.length > 0;

export const isConnectedWithGoogle = ({ semester, calendar }: RootState) => {
  return calendar.googleCalendars.length > 0 || semester?.info?.user?.hasCalendars;
};
