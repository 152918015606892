import { RootState } from '@state/types';

const getDatesContainingTasksMini = ({ weekCalendar: { datesContainingTasksMini } }: RootState) => {
  return {
    datesContainingTasksMini
  };
};

const getDateRange = ({ calendar: { startDate, endDate } }: RootState) => {
  return {
    startDate,
    endDate
  };
};

export { getDatesContainingTasksMini, getDateRange };
