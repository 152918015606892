import React from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT, toMomentDate, toMomentUtcDate } from 'shovel-lib/utils/timeUtils';
import { RootState } from '@state/types';
import { getSemesterRangeInfo } from '../../../../state/semester/selectors';
import { getManageError } from '../../../../state/task/selectors';
import { SemesterRangeInfo } from 'shovel-lib/types';
import styled from 'styled-components';
import colors from '../../../../utils/colors';
import TippyTooltip from '../../../common/tooltip/TippyTooltip';
import t from '../../../../i18n/t';
import DatePicker, { FooterRow } from '../../../common/inputs/datetime/DatePicker';
import { DAYS_TO_START_AHEAD_MAX } from '../../../../utils/constants/task';
import { NumberInput } from '../../../common/inputs';
import Popover from '../../../common/Popover';
import Button from '../../../common/buttons/Button';
import { Row, TextOverflowDiv } from '../../../common/layoutUtils';
import TextLink from '../../../common/TextLink';
import { openPersonalizationDialog } from '../../../../state/settings/actions';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';

type PropsFromState = {
  semesterInfo: SemesterRangeInfo | null;
  error: string;
};

type OwnProps = {
  dueDate?: string | Date | null;
  startAhead?: number;
  save: (data: { daysToStartAhead?: number }) => void;
  workload?: boolean;
  isCreateTask?: boolean;
  activeComponent?: any;
  emptyComponent?: any;
  calendarClassName?: string;
  pickerPosition?: string;
  openPersonalizationDialog?: typeof openPersonalizationDialog;
  isPersonalizationDialogOpen?: boolean;
  popperContainerId?: string;
};

type Props = PropsFromState & OwnProps;

type State = {
  startDaysAhead?: number;
  isOpen: boolean;
};

class ManageDueDate extends React.PureComponent<Props, State> {
  readonly state: State = {
    startDaysAhead: this.props.startAhead,
    isOpen: false
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.error || prevProps.startAhead !== this.props.startAhead) {
      this.setState({ startDaysAhead: this.props.startAhead });
    }
  }

  onOpen = () => {
    this.setState({ isOpen: true });
  };

  onClose = () => {
    const { startAhead, isPersonalizationDialogOpen } = this.props;
    // if (!isPersonalizationDialogOpen) {
    this.setState({ startDaysAhead: startAhead, isOpen: false });
    // }
  };

  save = () => {
    const { save, startAhead } = this.props;
    const { startDaysAhead } = this.state;
    // change if values are different
    if (startDaysAhead !== startAhead) {
      save({ daysToStartAhead: startDaysAhead });
    }
    this.onClose();
  };

  cancel = () => {
    const { startAhead } = this.props;
    const { startDaysAhead } = this.state;
    if (startDaysAhead !== startAhead) {
      this.setState({ startDaysAhead: startAhead });
    }
    this.onClose();
  };

  onChangeDate = value => {
    const { dueDate } = this.props;
    if (!dueDate) return;

    const startDaysAhead = toMomentDate(dueDate)
      .startOf('day')
      .diff(value, 'days');
    this.setState({ startDaysAhead });
  };

  customDayRender = (day, date: Date) => {
    const { dueDate } = this.props;
    if (!dueDate) return day;

    const utcDate = toMomentUtcDate(date);
    utcDate.subtract(date.getTimezoneOffset(), 'minutes');

    if (toMomentDate(dueDate).format(DATE_FORMAT) !== utcDate.format(DATE_FORMAT)) {
      return day;
    }

    return (
      <DueWrapper title={t.DUE_DATE}>
        <DueDateIndicator>{day}</DueDateIndicator>
        <EmojiPreview emoji={'🏁'} />
      </DueWrapper>
    );
  };

  renderInput = () => {
    const { openPersonalizationDialog, save } = this.props;
    const { startDaysAhead } = this.state;
    return (
      <div>
        <FooterRow style={{ padding: 10, flexDirection: 'column' }}>
          <Row style={{ justifyContent: 'flex-start', width: '100%' }}>
            <Input
              autofocus
              value={startDaysAhead}
              placeholder={'0'}
              required={false}
              min={0}
              max={DAYS_TO_START_AHEAD_MAX}
              onChange={e => this.setState({ startDaysAhead: Number(e.target.value) || 0 })}
              label={t.START_DAYS_AHEAD}
            />
            <Popover
              content={t.START_AHEAD_INFO}
              iconSize={20}
              outline={false}
              iconColor={colors.primaryPurple}
              iconStyle={{ marginBottom: 10 }}
            />
          </Row>
          {openPersonalizationDialog && (
            <Row style={{ justifyContent: 'flex-start', width: '100%', marginTop: 5 }}>
              <TextLink onClick={() => openPersonalizationDialog(({ daysAhead }) => save({ daysToStartAhead: daysAhead }))}>
                {t.SET_DEFAULT}
              </TextLink>
            </Row>
          )}
          <Row style={{ justifyContent: 'flex-end', width: '100%' }}>
            <Button withoutBorder size={'sm'} onClick={this.cancel}>
              Cancel
            </Button>
            <Button filled size={'sm'} onClick={this.save}>
              Done
            </Button>
          </Row>
        </FooterRow>
      </div>
    );
  };

  render() {
    const {
      workload,
      popperContainerId,
      isCreateTask,
      activeComponent,
      emptyComponent,
      semesterInfo,
      dueDate,
      startAhead
    } = this.props;
    const { startDaysAhead, isOpen } = this.state;

    if (!semesterInfo) return null;

    const startAheadDate = !dueDate
      ? undefined
      : toMomentDate(dueDate)
          .subtract(startDaysAhead, 'days')
          .toDate();

    let content;

    if (!dueDate) {
      content = (
        <TippyTooltip
          target={emptyComponent || <TextOverflowDiv> - </TextOverflowDiv>}
          content={t.SET_START_AHEAD_DISABLED}
          position={'top'}
        />
      );
    } else {
      content = (
        <DatePicker
          className={this.props.calendarClassName}
          value={startAheadDate}
          minDate={semesterInfo.starts}
          maxDate={dueDate || undefined}
          popperContainerId={popperContainerId}
          inputComponent={
            startAhead !== null && dueDate ? (
              activeComponent
            ) : (
              <span>
                <TippyTooltip target={emptyComponent || <div>&nbsp;</div>} content={t.SET_START_AHEAD} />
              </span>
            )
          }
          onChange={this.onChangeDate}
          onClose={this.onClose}
          onOpen={this.onOpen}
          required={false}
          isOpen={isOpen}
          customDayRender={this.customDayRender}
          footer={this.renderInput()}
          popperPlacement={this.props.pickerPosition}
          disabled={!dueDate}
          closeOnSelect={false}
        />
      );
    }

    return (
      <Wrapper disabled={!dueDate} workload={workload} isCreateTask={isCreateTask}>
        {content}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  semesterInfo: getSemesterRangeInfo(state),
  error: getManageError(state)
});

export default connect(mapStateToProps)(ManageDueDate);

const Wrapper = styled.div<{ disabled?: boolean; workload?: boolean; isCreateTask?: boolean }>`
  position: relative;
  ${props =>
    props.isCreateTask &&
    `
    position: absolute;
    inset: 0;
    .react-datepicker-wrapper, .react-datepicker__input-container {
      position: absolute;
      inset: 0;
    }
  `};
  width: ${props => (props.workload || props.isCreateTask ? '100%' : 'fit-content')};
  ${props =>
    props.disabled &&
    `
    ${TextOverflowDiv} {
      font-weight: bold;
      color: ${props.theme.textColor};
    }`};
  ${props =>
    props.workload &&
    `
    * {
      height: 100%;
    }
    .react-datepicker-wrapper {
      display: flex;
      ${TextOverflowDiv} {
        font-size: 12px;
        font-weight: bold;
        color: ${props.theme.textStrongColor};
      }
    }`};
  height: 100%;
  ${props =>
    props.disabled &&
    `
      *, *:hover, *:hover > svg path {
        cursor: not-allowed;
        border-color: ${props.theme.captures};
        fill: ${props.theme.captures} !important;
      }
 `}
`;

const DueWrapper = styled.div`
  position: relative;
  border-radius: 50%;
  background: ${props => props.theme.textStrongColor};
  .emoji-mart-emoji {
    position: absolute !important;
    right: -10px;
    top: -5px;
  }
`;

const DueDateIndicator = styled.span`
  color: ${props => props.theme.background};
`;

const Input = styled(NumberInput)<{ hasPersonalization?: boolean }>`
  ${props => props.hasPersonalization && 'margin: 0;'};
`;
