import React, { PureComponent } from 'react';
import { DateFormat, DayOfWeek, Formats, PersonalizationSettings } from 'shovel-lib/types';
import { ExtraLargeText, MediumText, MiniText, RegularText, SectionTitle, SmallText } from '@utils/typography';
import t from '@i18n/t';
import { Column, Row } from '../../common/layoutUtils';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import MaterialIcon from '../../common/icons/MaterialIcon';
import colors from '@utils/colors';
import { DatetimeInput } from '../../common/inputs';
import styled, { withTheme } from 'styled-components';
import RadioButton from '../../common/radioButton/RadioButton';
import NumberInput from '../../common/inputs/number/NumberInput';
import Toggle from '../../common/Toggle';
import { now } from 'shovel-lib/utils/timeUtils';
import { PERSONALIZATION_Z_INDEX } from './constants';
import FirefoxErrorBoundary from '@components/common/error/FirefoxErrorBoundary';
import InlineDropDownInput from '@components/common/inputs/dropdown/InlineDropDownInput';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';
import { calendarStylingOptions } from '@utils/calendar/calendarUtils';
import Preference from '@components/settings/preferences/Preference';

const dayOptions = [
  { value: DayOfWeek.TUESDAY, label: t.TUESDAY },
  { value: DayOfWeek.WEDNESDAY, label: t.WEDNESDAY },
  { value: DayOfWeek.THURSDAY, label: t.THURSDAY },
  { value: DayOfWeek.FRIDAY, label: t.FRIDAY },
  { value: DayOfWeek.SATURDAY, label: t.SATURDAY }
];

type Props = {
  values: any;
  setFieldValue: any;
  dayOfWeekInDropdown: any;
  isDialog?: boolean;
  onChange?: any;
  title?: string;
  theme: any;
};

type State = { dayOfWeekDropdownValue: DayOfWeek };

class PersonalizationContent extends PureComponent<Props, State> {
  readonly state = {
    dayOfWeekDropdownValue:
      !!this.props.values.firstDayOfWeek &&
      //@ts-ignore
      this.props.values.firstDayOfWeek !== DayOfWeek.SUNDAY &&
      this.props.values.firstDayOfWeek !== DayOfWeek.MONDAY
        ? this.props.values.firstDayOfWeek
        : DayOfWeek.SATURDAY
  };

  handleFieldChange = (fieldName, value) => {
    const { setFieldValue, isDialog, onChange, values } = this.props;
    setFieldValue(fieldName, value);
    if (!isDialog) {
      onChange?.(values);
    }
  };

  renderRadioButtonSections = () => {
    const { dayOfWeekDropdownValue } = this.state;
    const { values, isDialog, dayOfWeekInDropdown } = this.props;
    const dateFormatPreview = now();

    return (
      <RadioButtonSection>
        <RadioButtonWrapper isDialog={isDialog}>
          <SectionTitle>{t.TIME_FORMAT}</SectionTitle>
          <Radio
            label={`12 ${t.HOUR}`}
            onChange={() => this.handleFieldChange('time24Format', false)}
            checked={!values.time24Format}
          />
          <Radio
            label={`24 ${t.HOUR}`}
            onChange={() => this.handleFieldChange('time24Format', true)}
            checked={!!values.time24Format}
          />
        </RadioButtonWrapper>
        <RadioButtonWrapper isDialog={isDialog}>
          <SectionTitle>{t.DATE_FORMAT}</SectionTitle>
          {Object.values(DateFormat).map(value => (
            <Radio
              key={value}
              label={dateFormatPreview.format(value)}
              onChange={() => this.handleFieldChange('dateFormat', value)}
              checked={values.dateFormat === value}
            />
          ))}
        </RadioButtonWrapper>
        <RadioButtonWrapper isDialog={isDialog}>
          <SectionTitle>{t.START_WEEK_ON}</SectionTitle>
          <Radio
            label={t.SUNDAY}
            onChange={() => this.handleFieldChange('firstDayOfWeek', DayOfWeek.SUNDAY)}
            checked={values.firstDayOfWeek === DayOfWeek.SUNDAY}
          />
          <Radio
            label={t.MONDAY}
            onChange={() => this.handleFieldChange('firstDayOfWeek', DayOfWeek.MONDAY)}
            checked={values.firstDayOfWeek === DayOfWeek.MONDAY}
          />
          <RepeatOptionsWrapper>
            <Radio
              label={`Custom ${dayOfWeekInDropdown ? 'on' : ''}`}
              checked={dayOfWeekInDropdown}
              onChange={() => this.handleFieldChange('firstDayOfWeek', dayOfWeekDropdownValue)}
            />
            {dayOfWeekInDropdown && (
              <CustomInputWrapper>
                <InlineDropDownInput
                  initialValue={dayOfWeekDropdownValue}
                  onChange={value => {
                    this.setState({ dayOfWeekDropdownValue: value });
                    this.handleFieldChange('firstDayOfWeek', value);
                  }}
                  options={dayOptions}
                />
              </CustomInputWrapper>
            )}
          </RepeatOptionsWrapper>
        </RadioButtonWrapper>
      </RadioButtonSection>
    );
  };

  render() {
    const { values, isDialog, title, theme } = this.props;
    const calendarStyles = calendarStylingOptions(theme);

    return (
      <Container isDialog={isDialog}>
        {title && <Title>{title}</Title>}
        <Section>
          <SectionTitle>{t.CALENDAR_VIEWPORT}</SectionTitle>
          <Row>
            <RegularText style={{ marginRight: 5 }}>{t.TYPICAL_DAY_START}</RegularText>
            <FirefoxErrorBoundary>
              <DatetimeInput
                noErrorMargin
                inline
                autosizeFormat={() => Formats.LOWERCASE_TIME}
                value={values.startOfDay!}
                onChange={date => this.handleFieldChange('startOfDay', date)}
                showTimeOnly
              />
            </FirefoxErrorBoundary>
            <TippyTooltip
              target={
                <Row>
                  <MaterialIcon name={'help'} size={20} color={colors.primaryPurple} />
                </Row>
              }
              content={t.TYPICAL_DAY_START_TOOLTIP}
              preformattedText
              zIndex={PERSONALIZATION_Z_INDEX + 1}
            />
          </Row>
        </Section>
        <Section>
          <SectionTitle>{t.START_AHEAD_FOR_TASKS}</SectionTitle>
          <Row>
            <RegularText>{t.DEFAULT_START_AHEAD}</RegularText>
            <DaysAheadInput
              noErrorMargin
              inline
              autosize
              //@ts-ignore
              value={values.daysAhead}
              onChange={e => this.handleFieldChange('daysAhead', e.target.value)}
              min={0}
            />
            <TippyTooltip
              target={
                <Row>
                  <MaterialIcon name={'help'} size={20} color={colors.primaryPurple} />
                </Row>
              }
              content={t.DEFAULT_START_AHEAD_TOOLTIP}
              preformattedText
              zIndex={PERSONALIZATION_Z_INDEX + 1}
            />
          </Row>
        </Section>
        <Section>
          <FlagsToggle
            //@ts-ignore
            onChange={() => this.handleFieldChange('showFlags', !values.showFlags)}
            //@ts-ignore
            active={values.showFlags}
            alignBaseline
            customLabel={
              <MediumText className={'toggle-label'}>
                {t.SHOW_DEADLINE_FLAGS} <EmojiPreview emoji={'🏁'} size={13} />
              </MediumText>
            }
          />
        </Section>
        {this.renderRadioButtonSections()}
        <Section>
          <SectionTitle grayed>{t.CHOOSE_COLOR_SCHEME.toUpperCase()}</SectionTitle>
          <Preference />
        </Section>
        <Section>
          <SectionTitle grayed>{t.CALENDAR_STYLING}</SectionTitle>
          <Row style={{ marginTop: 10 }}>
            <EventType>
              <RegularText>
                {t.COURSE} {t.EVENTS}
              </RegularText>
              <Toggle
                onChange={() => this.handleFieldChange('courseEventStyle.sidebar', !values.courseEventStyle.sidebar)}
                active={values.courseEventStyle.sidebar}
                label={t.SIDEBAR}
              />
            </EventType>
            {Object.entries(calendarStyles).map(([key, option]) => (
              <OptionNameWrapper>
                <RegularText>{option.name}</RegularText>
                <CalendarEventSample
                  key={key}
                  color={'#1976D2'}
                  style={{ borderColor: 'rgba(25, 118, 210, 0.3)' }}
                  styles={option}
                >
                  <MiniText color={'#1976D2'}>3-6pm</MiniText>
                  <SmallText>{t.COURSE_EVENT_TITLE_EXAMPLE}</SmallText>
                  <CalendarRadio
                    checked={key === values.courseEventStyle.style}
                    onChange={() => this.handleFieldChange('courseEventStyle.style', key)}
                  />
                  {values.courseEventStyle.sidebar && <BorderLeft color={'#1976D2'} />}
                </CalendarEventSample>
              </OptionNameWrapper>
            ))}
          </Row>
          <Row>
            <EventType>
              <RegularText>
                {t.ACTIVITY} {t.EVENTS}
              </RegularText>
              <Toggle
                onChange={() => this.handleFieldChange('activityEventStyle.sidebar', !values.activityEventStyle.sidebar)}
                active={values.activityEventStyle.sidebar}
                label={t.SIDEBAR}
              />
            </EventType>
            {Object.entries(calendarStyles).map(([key, option]) => (
              <CalendarEventSample
                key={key}
                color={'#F57C00'}
                style={{ borderColor: 'rgba(245, 124, 0, 0.3)' }}
                styles={option}
              >
                <MiniText color={'#F57C00'}>3-6pm</MiniText>
                <SmallText>{t.ACTIVITY_EVENT_TITLE_EXAMPLE}</SmallText>
                <CalendarRadio
                  checked={key === values.activityEventStyle.style}
                  onChange={() => this.handleFieldChange('activityEventStyle.style', key)}
                />
                {values.activityEventStyle.sidebar && <BorderLeft color={'#F57C00'} />}
              </CalendarEventSample>
            ))}
          </Row>
          <Row>
            <EventType>
              <RegularText>{t.GOOGLE_EVENTS}</RegularText>
              <Toggle
                onChange={() => this.handleFieldChange('googleEventStyle.sidebar', !values.googleEventStyle.sidebar)}
                active={values.googleEventStyle.sidebar}
                label={t.SIDEBAR}
              />
            </EventType>
            {Object.entries(calendarStyles).map(([key, option]) => (
              <CalendarEventSample
                key={key}
                color={'#5873FF'}
                style={{ borderColor: 'rgba(88, 115, 255, 0.3)' }}
                styles={option}
              >
                <MiniText color={'#5873FF'}>3-6pm</MiniText>
                <SmallText>{t.GOOGLE_EVENT_TITLE_EXAMPLE}</SmallText>
                <CalendarRadio
                  checked={key === values.googleEventStyle.style}
                  onChange={() => this.handleFieldChange('googleEventStyle.style', key)}
                />
                <LockIcon name={'lock'} size={9} strong />
                {values.googleEventStyle.sidebar && <BorderLeft color={'#5873FF'} />}
              </CalendarEventSample>
            ))}
          </Row>
          <Row>
            <EventType>
              <RegularText>{t.PLANNED_TASKS}</RegularText>
              <Toggle
                onChange={() =>
                  this.handleFieldChange('plannedTaskEventStyle.sidebar', !values.plannedTaskEventStyle.sidebar)
                }
                active={values.plannedTaskEventStyle.sidebar}
                label={t.SIDEBAR}
              />
            </EventType>
            {Object.entries(calendarStyles).map(([key, option]) => (
              <CalendarEventSample
                key={key}
                color={'#1AB77F'}
                style={{ borderColor: 'rgba(26, 183, 127, 0.3)' }}
                styles={option}
              >
                <MiniText color={'#1AB77F'}>3-6pm</MiniText>
                <SmallText>{t.PLANNED_TASK_TITLE_EXAMPLE}</SmallText>
                <SmallText style={{ fontWeight: 500, lineHeight: 1 }}>{t.PLANNED_TASK_SUBTITLE_EXAMPLE}</SmallText>
                <CalendarRadio
                  checked={key === values.plannedTaskEventStyle.style}
                  onChange={() => this.handleFieldChange('plannedTaskEventStyle.style', key)}
                />
                {values.plannedTaskEventStyle.sidebar && <BorderLeft color={'#1AB77F'} />}
              </CalendarEventSample>
            ))}
          </Row>
        </Section>
      </Container>
    );
  }
}

export default withTheme(PersonalizationContent);

const Container = styled.div<{ isDialog?: boolean }>`
  flex: 1;
  ${props => (!props.isDialog ? `padding: 20px 30px` : `padding: 0px 45px`)};
  overflow: auto;
`;

const Section = styled(Column)<{ last?: boolean }>`
  border-top: 1px solid ${props => props.theme.borderColor};
  padding: 10px 0px 20px 0px;
  box-sizing: border-box;
  ${props => props.last && `border-bottom: 1px solid ${props => props.theme.borderColor}`};
`;

const Radio = styled(RadioButton)``;

const RepeatOptionsWrapper = styled(Row)`
  align-items: center;
  margin-bottom: 20px;
  ${Radio} > span {
    color: ${props => props.theme.textColor};
  }
`;

const CustomInputWrapper = styled.div`
  margin-left: 10px;
`;

const DaysAheadInput = styled(NumberInput)`
  margin-left: 5px;
`;

const FlagsToggle = styled(Toggle)`
  .toggle-label {
    text-transform: uppercase;
    margin: 10px 10px 0px 0px;
    font-size: 13px;
    font-weight: 600;
    color: ${props => props.theme.textColor};
    display: flex;
    align-items: center;

    .emoji-mart-emoji {
      margin-left: 5px;
      display: flex;
    }
  }
`;

const RadioButtonSection = styled(Row)`
  border-top: 1px solid ${props => props.theme.borderColor};
  padding: 10px 0px 20px 0px;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const RadioButtonWrapper = styled(Column)<{ isDialog?: boolean }>`
  > * {
    margin: 7px 0 0;
  }
  ${props =>
    !props.isDialog
      ? `margin-right: 50px;
     &:last-child {
       margin-right: 0px;
     }
    `
      : `width: 30%;
     &:last-child {
       width: 40%;
     } `}
`;

const Title = styled(ExtraLargeText)`
  color: ${props => props.theme.textStrongColor};
  margin-bottom: 20px;
  display: block;
`;

const CalendarEventSample = styled(Column)<{ styles: any; color: string }>`
  position: relative;
  width: 100px;
  height: 60px;
  border: 1px solid;
  border-radius: 3px;
  padding: 2px 5px;
  padding-left: 7px;
  box-sizing: border-box;
  box-shadow: ${props => `0 2px 2px ${props.theme.boxShadow}, 0 0 2px ${props.theme.boxShadow}`};
  background-color: ${props => props.styles.backgroundFunction(props.color)};
  margin: 20px;
  margin-right: 0;
`;

const CalendarRadio = styled(RadioButton)`
  position: absolute;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  background: ${props => props.theme.background};
  padding: 2px;
`;

const EventType = styled(Column)`
  width: 120px;
  ${RegularText} {
    text-transform: capitalize;
  }
  ${MediumText} {
    margin-left: 0;
    text-transform: capitalize;
  }
`;

const LockIcon = styled(MaterialIcon)`
  position: absolute;
  right: 1px;
  bottom: 1px;
`;

const BorderLeft = styled.div<{ color: string }>`
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 3px;
  background: ${props => props.color};
  border-radius: 3px 0 0 3px;
`;

const OptionNameWrapper = styled(Column)`
  position: relative;
  align-items: center;
  > ${RegularText} {
    position: absolute;
    top: -10px;
  }
`;
