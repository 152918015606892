import { RootState } from '@state/types';
import { getType } from 'typesafe-actions';
import * as settingsActions from './actions';
import { DateFormat } from 'shovel-lib/types';
import { initialUserSettings, initialOnboardingStatus } from './reducers';
import { now, timeDiffInDays, toMomentDate } from 'shovel-lib/utils/timeUtils';

const getSubscriptionInfo = (state: RootState) => {
  return state.settings.subscription;
};

const getSubscriptionType = (state: RootState) => {
  if (!state.settings.subscription) return undefined;
  return state.settings.subscription.type;
};

const getUserSettings = (state: RootState) => state.settings.userSettings || initialUserSettings;

const hasSettings = (state: RootState) => !!state.settings.userSettings;

const getPersonalizationSettings = (state: RootState) => {
  const {
    time24Format,
    dateFormat,
    startOfDay,
    firstDayOfWeek,
    daysAhead,
    showFlags,
    courseEventStyle,
    activityEventStyle,
    googleEventStyle,
    plannedTaskEventStyle
  } = state.settings.userSettings || initialUserSettings;
  return {
    time24Format,
    dateFormat,
    startOfDay,
    firstDayOfWeek,
    daysAhead,
    showFlags,
    courseEventStyle,
    activityEventStyle,
    googleEventStyle,
    plannedTaskEventStyle
  };
};

const getOnboardingStatus = ({ settings }: RootState) => settings.userSettings?.onboardingStatus || initialOnboardingStatus;

const getDateFormat = ({ settings }: RootState) => settings.userSettings?.dateFormat || DateFormat.MONTH_FIRST;

const getSettingsLoading = ({ common }: RootState) => {
  const getSettingsType = getType(settingsActions.getUserSettings.request).split('_REQUEST')[0];
  return common.loaders[getSettingsType];
};

const getHelpUsDialogState = (state: RootState) => state.settings.helpUsDialog;

const isPlannedTimeIncludedInCushion = ({ semester }: RootState) =>
  semester.info && semester.info.user ? semester.info.user.includePlannedInCushion : false;

const getDaysTrialLeft = (state: RootState) => {
  const subscription = state.settings?.subscription;
  if (!subscription) return null;

  const trial = subscription.trial;
  if (!trial) return null;

  const currentTime = now();

  if (toMomentDate(subscription.accessUntil!).isBefore(currentTime)) {
    return null;
  }

  return Math.ceil(timeDiffInDays(currentTime.toDate(), subscription.accessUntil!));
};

const getPaymentProcessor = (state: RootState) => {
  // prevent returning null
  return state.settings?.subscription?.paymentProcessor || undefined;
};

const getUserBadges = ({ settings }: RootState) => settings.badgesInfo;

const getStepDialogInfo = ({ settings }: RootState) => settings.stepDialogInfo;

const getBadgesObviousMike = ({ settings }: RootState) => settings.badgesObviousMike;

const getShowOnboardingBar = ({ settings }: RootState) => settings.showOnboardingBar;

const getBagdesSteps = ({ settings }: RootState) => settings.badgesInfo?.steps;

const getOrganizationEmailDomainName = (state: RootState) => {
  return state.settings.subscription?.organizationEmailDomainName;
};

const getDefaultDaysAhead = (state: RootState) => {
  const { daysAhead } = state.settings.userSettings || initialUserSettings;
  return daysAhead;
};

const getPersonalizationDialogInfo = (state: RootState) => {
  return {
    open: state.settings.personalizationDialog.open,
    info: state.settings.userSettings,
    callback: state.settings.personalizationDialog.callback
  };
};

const getPersonalizationDialogVisibility = (state: RootState) => {
  return state.settings.personalizationDialog.open;
};

export {
  getSubscriptionInfo,
  getSubscriptionType,
  getUserSettings,
  getPersonalizationSettings,
  getOnboardingStatus,
  getSettingsLoading,
  getDateFormat,
  getHelpUsDialogState,
  isPlannedTimeIncludedInCushion,
  getDaysTrialLeft,
  getPaymentProcessor,
  getUserBadges,
  getStepDialogInfo,
  getBadgesObviousMike,
  getShowOnboardingBar,
  getBagdesSteps,
  hasSettings,
  getOrganizationEmailDomainName,
  getDefaultDaysAhead,
  getPersonalizationDialogInfo,
  getPersonalizationDialogVisibility
};
