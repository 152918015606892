import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formats, PileToDoTask, TaskState } from 'shovel-lib/types';
import { closeTasksModal, openManageTaskDialog } from '../../state/task/actions';
import { getTasksDialogState } from '../../state/task/selectors';
import ModalDialog from '../common/ModalDialog';
import { RootState } from '@state/types';
import PileTask from '../pile/components/PileTask';
import styled from 'styled-components';
import t from '../../i18n/t';
import { taskActions } from '@state/task';

type PropsFromState = {
  isOpen: boolean;
  tasks: PileToDoTask[];
};

type PropsFromDispatch = {
  dismiss: typeof closeTasksModal;
  openManageTaskDialog: typeof openManageTaskDialog;
  setTasksPriority: typeof taskActions.setTasksPriority.request;
};

type Props = PropsFromState & PropsFromDispatch;

class TasksDialog extends React.PureComponent<Props> {
  handleClose = () => this.props.dismiss();

  render() {
    const { isOpen, tasks, openManageTaskDialog, setTasksPriority } = this.props;

    return (
      <ModalDialog
        lockScroll
        open={isOpen}
        closeOnDocumentClick={false}
        onClose={this.handleClose}
        title={t.TASKS_YOU_SHOULD_START_WORKING_ON}
        size={'md'}
      >
        <Container>
          {tasks.map(task => (
            <PileTask
              key={task.taskId}
              task={task}
              state={TaskState.ACTIVE}
              dueDateFormat={Formats.TASK_DATE_FORMAT}
              changePriority={priority => setTasksPriority({ taskIds: [task.taskId], priority })}
              onPress={() => {
                this.handleClose();
                openManageTaskDialog(task);
              }}
            />
          ))}
        </Container>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state: RootState) => getTasksDialogState(state);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      dismiss: closeTasksModal,
      openManageTaskDialog: openManageTaskDialog,
      setTasksPriority: taskActions.setTasksPriority.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TasksDialog);

const Container = styled.div`
  padding: 0 120px;
`;
