import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { getType } from 'typesafe-actions';
import Reactotron from 'reactotron-react-js';

import appReducer from '../state/rootReducer';
import rootSaga from '../state/rootSaga';
import { useReactotron } from './configConstants';
import { clearState } from '../state/common/actions';
import history from '../utils/history';
import { RootState } from '@state/types';

const rootReducer = (state: RootState | undefined, action: any) => {
  if (action.type === getType(clearState)) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default function configureStore(initialState = {}): Store<RootState> {
  let sagaMiddleware;
  let store;
  let middleware;
  const reactRouterMiddleware = routerMiddleware(history);
  if (useReactotron) {
    //@ts-ignore
    const sagaMonitor = Reactotron.createSagaMonitor!();
    sagaMiddleware = createSagaMiddleware({ sagaMonitor });
    middleware = [sagaMiddleware, reactRouterMiddleware];
    //@ts-ignore
    store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), Reactotron.createEnhancer!()));
  } else {
    sagaMiddleware = createSagaMiddleware();
    middleware = [sagaMiddleware, reactRouterMiddleware];
    store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
  }

  sagaMiddleware.run(rootSaga, store.dispatch);

  return store;
}
