import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { WeekdayStr } from 'rrule';
import styled from 'styled-components';
import { RegularText, SmallText } from '../../../utils/typography';
import colors from '../../../utils/colors';
import t from '../../../i18n/t';
import { getFirstTwoDayLetters } from '../../../utils/calendar/calendarUtils';
import { RootState } from '@state/types';
import { getPersonalizationSettings } from '../../../state/settings/selectors';
import { PersonalizationSettings } from 'shovel-lib/types';

type Props = PersonalizationSettings & {
  initialDate: Date | string | null;
  weekdays?: WeekdayStr[];
  onWeekdayClick: (weekdays?: WeekdayStr[]) => void;
  fontSize?: number;
};

type State = {
  weekdays: WeekdayStr[];
  weekdayOptions: WeekdayStr[];
};

class RepeatingDays extends PureComponent<Props, State> {
  initialWeekdays = () => {
    return this.props.weekdays && this.props.weekdays.length !== 0
      ? this.props.weekdays
      : this.props.initialDate
      ? [getFirstTwoDayLetters(this.props.initialDate)]
      : [];
  };

  state = {
    weekdays: this.initialWeekdays(),
    weekdayOptions: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] as WeekdayStr[]
  };

  rotateWeekdays = () => {
    const { firstDayOfWeek } = this.props;
    const { weekdayOptions } = this.state;
    const shiftedDays = weekdayOptions.splice(0, firstDayOfWeek);
    this.setState({ weekdayOptions: weekdayOptions.concat(shiftedDays) });
  };

  componentDidMount() {
    this.rotateWeekdays();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.firstDayOfWeek !== this.props.firstDayOfWeek) {
      this.rotateWeekdays();
    }
    if (
      (this.props.initialDate !== prevProps.initialDate || this.props.weekdays !== prevProps.weekdays) &&
      this.state.weekdays.length === 1
    ) {
      const weekdays = this.initialWeekdays();
      this.setState({ weekdays });
    }
  }

  handleWeekdayClick = (weekday: WeekdayStr) => {
    const index = this.state.weekdays.indexOf(weekday);
    const weekdays = [...this.state.weekdays];
    if (index !== -1) {
      if (this.state.weekdays.length === 1) return;
      weekdays.splice(index, 1);
    } else {
      weekdays.push(weekday);
    }
    this.setState({ weekdays });
    const data =
      this.props.initialDate && weekdays.length === 1 && weekdays[0] === getFirstTwoDayLetters(this.props.initialDate)
        ? undefined
        : weekdays;
    this.props.onWeekdayClick(data);
  };

  render() {
    const { weekdays, weekdayOptions } = this.state;
    return (
      <>
        <Label fontSize={this.props.fontSize}>{t.DAYS_REPEAT_ON}</Label>
        <WeekDaysWrapper>
          {weekdayOptions.map((option: WeekdayStr) => (
            <Weekday selected={weekdays.includes(option)} key={option} onClick={() => this.handleWeekdayClick(option)}>
              <SmallText strong color={weekdays.includes(option) ? colors.white : undefined}>
                {option.substr(0, 1)}
              </SmallText>
            </Weekday>
          ))}
        </WeekDaysWrapper>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => getPersonalizationSettings(state);

export default connect(mapStateToProps)(RepeatingDays);

const WeekDaysWrapper = styled.div`
  display: flex;
  margin: 5px 0 20px 0;
`;

const Weekday = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background-color: ${props => (props.selected ? colors.primaryPurple : props.theme.background)};
  width: 29px;
  height: 29px;
  border: 1px solid ${props => (props.selected ? colors.primaryPurple : props.theme.borderColor)};
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    ${props =>
      !props.selected
        ? `border-color: ${props.theme.textColor}`
        : `background-color: ${colors.primaryPurple}BF; border-color: ${colors.primaryPurple}BF`}
  }
`;

const Label = styled(RegularText)<{ fontSize?: number }>`
  ${props =>
    props.fontSize &&
    `font-size: ${props.fontSize}px;
     font-weight: ${props.fontSize === 13 ? '600' : 'bold'}
    `};
`;
