import { darkenColor, increaseColorBrightness } from '../colorUtils';
import { rawColors } from './rawColors';
import storage from '../storage';
import t from '../../i18n/t';
import { AppTheme } from './types';

export const appThemes: AppTheme[] = [
  {
    primary: rawColors.purple,
    primaryLight: rawColors.purpleLight,
    primaryDark: rawColors.darkPurple,
    name: t.LAVENDER
  },
  {
    primary: rawColors.flamingo,
    primaryLight: increaseColorBrightness(rawColors.flamingo),
    primaryDark: darkenColor(rawColors.flamingo, -15),
    name: t.FLAMINGO
  },
  {
    primary: rawColors.sapphire,
    primaryLight: increaseColorBrightness(rawColors.sapphire),
    primaryDark: darkenColor(rawColors.sapphire, -15),
    name: t.SAPPHIRE
  }
  // {
  //   primary: rawColors.tangerine,
  //   primaryLight: increaseColorBrightness(rawColors.tangerine),
  //   primaryDark: darkenColor(rawColors.tangerine, -15),
  //   name: t.TANGERINE
  // }
];

const setCssVariables = (primary: string, primaryLight: string) => {
  const bodyStyle = document.body.style;
  bodyStyle.setProperty('--color-primary', primary);
  bodyStyle.setProperty('--color-primary-light', primaryLight);
};

export let currentTheme = storage.getAppTheme() ?? appThemes[0];
setCssVariables(currentTheme.primary, currentTheme.primaryLight);

export const toggleNextAppTheme = () => {
  const currentIndex = appThemes.findIndex(v => v.primary === currentTheme.primary);
  const next = (currentIndex + 1) % appThemes.length;
  currentTheme = appThemes[next];
  storage.setAppTheme(currentTheme);
  window.location.reload();
};

export const setAppTheme = (name: string) => {
  currentTheme = appThemes.find(theme => theme.name === name)!;
  storage.setAppTheme(currentTheme);
  window.location.reload();
};

export const setCustomAppTheme = (theme: AppTheme) => {
  storage.setAppTheme(theme);
  window.location.reload();
};
