import React from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT, formatMomentDateAs, isSameDate, toMomentDate, toMomentUtcDate } from 'shovel-lib/utils/timeUtils';
import { RootState } from '@state/types';
import { getSemesterRangeInfo } from '../../../../state/semester/selectors';
import { getManageError } from '../../../../state/task/selectors';
import { Formats, SemesterRangeInfo, TaskType } from 'shovel-lib/types';
import styled from 'styled-components';
import colors from '../../../../utils/colors';
import DatetimePicker from '../../../common/inputs/datetime/DatetimePicker';
import TippyTooltip from '../../../common/tooltip/TippyTooltip';
import { getClassTimes } from '../../../../state/calendar/selectors';
import CheckBox from '../../../common/CheckBox';
import t from '../../../../i18n/t';
import { FooterRow } from '../../../common/inputs/datetime/DatePicker';

type PropsFromState = {
  semesterInfo: SemesterRangeInfo | null;
  error: string;
  classTimes: { colorHex?: string; isCourse?: boolean; classes: { [key: string]: (string | Date)[] } };
};

type OwnProps = {
  dueDate?: string | Date | null;
  save: (data: { dueDate?: string | Date | null; daysToStartAhead?: number }) => void;
  calendarClassName?: string;
  workload?: boolean;
  isManage?: boolean;
  courseId?: number;
  openToDate?: Date;
  activeComponent?: any;
  emptyComponent?: any;
  emptyTooltip?: string;
  className?: string;
  onOpen?: any;
  onClose?: any;
  isOpen?: boolean;
  customDayRender?: any;
  pickerPosition?: string;
  taskType: TaskType;
  popperContainerId?: string;
};

type Props = PropsFromState & OwnProps;

type State = {
  dueDate?: Date | string | null;
  isOpen: boolean;
};

class ManageDueDate extends React.PureComponent<Props, State> {
  readonly state: State = {
    dueDate: this.props.dueDate,
    isOpen: this.props.isOpen || false
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.error || prevProps.dueDate !== this.props.dueDate) {
      this.setState({ dueDate: this.props.dueDate });
    }
  }

  onOpen = () => {
    const { onOpen } = this.props;
    this.setState({ isOpen: true });
    if (onOpen) {
      onOpen();
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    this.setState({ isOpen: false });
    if (onClose) {
      onClose();
    }
  };

  onChange = (value: Date, timeClicked?: boolean) => {
    const { save, semesterInfo, classTimes } = this.props;
    const { dueDate } = this.state;

    if (!value) {
      save({ dueDate: value, daysToStartAhead: 0 });
      return;
    }

    let due: any = toMomentDate(value);
    const times = value ? classTimes.classes[due.format(DATE_FORMAT)] : undefined;

    if (times && (!dueDate || !isSameDate(due, toMomentDate(dueDate)))) {
      due = toMomentDate(times[0]);
    } else if (!dueDate && !timeClicked) {
      due = due.endOf('day').seconds(0);
    }

    if (due.isBefore(semesterInfo?.starts)) {
      due = semesterInfo?.starts;
    } else if (due.isAfter(semesterInfo?.ends)) {
      due = semesterInfo?.ends;
    } else {
      due = due.toDate();
    }

    // if (!dueDate) {
    save({ dueDate: due });
    // }
    this.setState({ dueDate: due });
  };

  getCalendarDayString = date => {
    const utcDateTime = toMomentUtcDate(date);
    utcDateTime.subtract(date.getTimezoneOffset(), 'minutes');
    return utcDateTime.format(DATE_FORMAT);
  };

  customDayRender = (day, date: Date) => {
    const { classes, colorHex } = this.props.classTimes;
    const dateString = this.getCalendarDayString(date);
    const times = classes[dateString];
    if (!times || times.length === 0) return day;
    return (
      <span style={{ position: 'relative' }}>
        <ClassIndicator color={colorHex} />
        {day}
      </span>
    );
  };

  renderClassTimes = () => {
    const { dueDate } = this.state;
    const { classTimes } = this.props;
    const times = dueDate ? classTimes.classes[toMomentDate(dueDate).format(DATE_FORMAT)] : undefined;
    if (!times || times.length === 0) return undefined;
    return (
      <div>
        <FooterRow>
          {times.map(value => {
            const classTime = toMomentDate(value);
            return (
              <CheckBox
                key={value.toString()}
                onChange={this.onChange}
                clickData={value}
                checked={classTime.isSame(dueDate)}
                label={`${classTimes.isCourse ? t.DUE_IN_CLASS_LABEL : t.DUE_AT_ACTIVITY_START_LABEL}${formatMomentDateAs(
                  classTime,
                  Formats.TIME
                )}`}
              />
            );
          })}
        </FooterRow>
      </div>
    );
  };

  render() {
    const { semesterInfo, popperContainerId, calendarClassName, workload, className, emptyTooltip, isManage } = this.props;
    const { dueDate, isOpen } = this.state;

    if (!semesterInfo) return null;
    const activeComponent = !!dueDate && this.props.activeComponent;
    const emptyComponent = !dueDate && this.props.emptyComponent;

    const picker = (
      <DatetimePicker
        popperContainerId={popperContainerId}
        timeIntervals={15}
        clearable={true}
        className={calendarClassName || 'manage-due-date'}
        value={dueDate}
        minDate={semesterInfo.starts}
        maxDate={semesterInfo.ends}
        onChange={this.onChange}
        onClose={this.onClose}
        onOpen={this.onOpen}
        required={false}
        isOpen={this.props.isOpen || isOpen}
        customDayRender={this.customDayRender}
        footer={this.renderClassTimes()}
        popperPlacement={this.props.pickerPosition}
        addMidnight
      />
    );

    let content;

    if (emptyComponent && emptyTooltip) {
      content = (
        <TippyTooltip target={<span className={'react-datepicker-wrapper'}>{picker}</span>} content={emptyTooltip} />
      );
    } else {
      content = picker;
    }

    return (
      <Wrapper
        inputHidden={!!emptyComponent}
        isWorkload={workload}
        className={className}
        isOpen={isOpen}
        isManage={isManage}
      >
        {activeComponent}
        {emptyComponent}
        {content}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  semesterInfo: getSemesterRangeInfo(state),
  error: getManageError(state),
  classTimes: getClassTimes(state, props)
});

export default connect(mapStateToProps)(ManageDueDate);

const Wrapper = styled.div<{ inputHidden?: boolean; isWorkload?: boolean; isOpen?: boolean; isManage?: boolean }>`
  position: relative;
  width: fit-content;
  height: 100%;
  .react-datepicker__close-icon {
    transition: all 0.2s ease;
    &:hover {
      &::after {
        color: ${colors.negative} !important;
      }
    }
  }

  ${props =>
    props.isWorkload &&
    `
    width: 100%;
    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    input {
      height: 100%;
    }


    .react-datepicker-wrapper {
      margin-top: -1px;
      margin-left: -1px;
      margin-right: -1px;
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      ${props.isOpen && `border-color ${colors.primaryPurple};`}
    }
  `};

  .react-datepicker__close-icon:after {
    opacity: 0;
    line-height: ${props => (props.isWorkload ? 1 : 'initial')};
    padding: 0px;
    color: ${props => props.theme.textColor};
    background-color: ${props => props.theme.background};
    font-family: 'Material Icons Round';
    content: 'cancel';
    font-weight: normal;
    font-style: normal;
    font-size: ${props => (props.isWorkload ? 16 : 12)}px;
  }

  &:hover {
    .react-datepicker__close-icon:after {
      opacity: 1;
    }
  }

  ${props =>
    !props.isWorkload &&
    `
    .react-datepicker-wrapper {
      opacity: ${props.inputHidden ? 0 : 1};

      ${(!props.isManage || props.inputHidden) &&
        `
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
      `}

      .react-datepicker__input-container,
      input {
        height: 100%;
        width: 100%;
      }
    }

    input {
      font-size: 12px !important;
      padding: 0 !important;
      padding-top: 17px !important;
      font-weight: bold !important;
    }

    .react-datepicker__close-icon {
      top: unset;
      bottom: 1px;
      height: auto;
      padding: 4px 0 0;
    }
  `}
`;

const ClassIndicator = styled.span<{ color?: string }>`
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${props => props.color};
  top: -4px;
  left: calc(50% - 2px);
`;
