import React, { PureComponent } from 'react';
import TaskCategorySection from '../../task/partials/TaskCategorySection';
import { FormFieldName } from '../../../utils/enums';
import TippyTooltip from '../../common/tooltip/TippyTooltip';
import ActionButton, { ButtonWrapper, ClearIcon, getColor } from './ActionButton';
import MaterialIcon from '../../common/icons/MaterialIcon';
import t from '../../../i18n/t';
import styled, { withTheme } from 'styled-components';
import { Row } from '../../common/layoutUtils';
import { SmallText } from '../../../utils/typography';
import { ManageDueDate, ManagePageRanges } from '../../task/manage/partials';
import { ReactComponent as StartAheadIcon } from '../../../assets/icons/startAhead.svg';
import { Formats, ReadingSourceInfoDto, TaskCategoryDto, TaskType, ThinActivityDto, ThinCourseDto } from 'shovel-lib/types';
import { RootState } from '@state/types';
import { connect } from 'react-redux';
import DropDownInputEditable from '../../common/inputs/dropdown/DropDownInputEditable';
import { bindActionCreators, Dispatch } from 'redux';
import { courseActions } from '../../../state/course';
import { getCategories, getReadingSources } from '../../../state/course/selectors';
import ReadingSourceSection from '../../task/partials/ReadingSourceSection';
import DurationInput from '../../common/inputs/duration/DurationInput';
import { formatMomentDateAs, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { minutesToDurationInHours, secondsToDurationInMinutes } from '../../../utils/filters';
import { mapCoursesAndActivitiesToDropdownOptions } from '../../../state/common/utils';
import { getDefaultDaysAhead, getPersonalizationDialogVisibility } from '../../../state/settings/selectors';
import { openPersonalizationDialog } from '../../../state/settings/actions';
import StartAhead from '../../task/manage/partials/StartAhead';
import { EmojiPreview } from '@components/common/emoji/EmojiPreview';
import { DEFAULT_TASK_CATEGORY_EMOJI } from '@utils/constants/task';
import CheckBox from '@components/common/CheckBox';
import colors from '@utils/colors';

type OwnProps = {
  leftAligned?: boolean;
  values: any;
  setFieldValue: any;
  initialValues: any;
  courses: ThinCourseDto[];
  activities: ThinActivityDto[];
  className?: any;
  examStyles?: any;
  appendPopupTo?: any;
  repeat?: {
    openCreatePatternDialog: any;
    isRepeatSet: boolean;
    clearRepeatOptions: any;
  };
  manageDialog?: boolean;
  noAutoPopupExam?: boolean;
};

type PropsFromState = {
  categories: TaskCategoryDto[];
  readingSources: ReadingSourceInfoDto[];
  theme: any;
  daysAhead?: number;
  isPersonalizationDialogOpen: boolean;
};

type PropsFromDispatch = {
  getCategoriesByCourse: typeof courseActions.getCategoriesByCourse.request;
  getReadingSourcesByCourse: typeof courseActions.getReadingSourcesByCourse.request;
  openPersonalizationDialog: typeof openPersonalizationDialog;
};

type State = {
  dueDateOpen: boolean;
};

type Props = OwnProps & PropsFromState & PropsFromDispatch;

class AddTaskButtons extends PureComponent<Props, State> {
  state: State = {
    dueDateOpen: false
  };

  examRef = React.createRef<any>();

  componentDidMount() {
    const { values, getCategoriesByCourse, getReadingSourcesByCourse } = this.props;
    if (values[FormFieldName.COURSE]) {
      getCategoriesByCourse(values[FormFieldName.COURSE]);
      getReadingSourcesByCourse(values[FormFieldName.COURSE]);
    }
  }

  renderActiveComponent = (name: string) => {
    const { values } = this.props;
    if (values[name]) {
      let text: any = '';
      switch (name) {
        case FormFieldName.DURATION:
          text = minutesToDurationInHours(values[name]);
          break;
        case FormFieldName.START_AHEAD:
          text = `${values[name]} ${values[name] === 1 ? t.DAY : t.DAYS} ${t.AHEAD}`;
          break;
        case FormFieldName.DUE_DATE:
          const showYear = !!values[name] && new Date().getFullYear() !== values[name].getFullYear();
          text = (
            <div style={{ color: 'transparent' }}>
              {formatMomentDateAs(
                toMomentDate(values[name]),
                `${Formats.MONTH_DAY_FORMAT}${showYear ? ' YY' : ''}, ${Formats.TIME}`
              )}
            </div>
          );
          break;
        case FormFieldName.ESTIMATE:
          text = minutesToDurationInHours(values[name]);
          break;

        default:
          text = values[name];
      }

      return <ActiveComponent>{text}</ActiveComponent>;
    }
    return null;
  };

  setEstimateForReadingSource = (pageRanges, readingSourceId) => {
    const readingSource = this.props.readingSources.find(c => c.id === readingSourceId);
    if (readingSource) {
      let sum = 0;
      pageRanges.forEach(page => (sum = page.from && page.to ? sum + (page.to - page.from + 1) : sum + page.pages));
      const estimate = (readingSource?.secondsPerPage / 60) * sum;
      this.props.setFieldValue(FormFieldName.ESTIMATE, estimate);
    }
  };

  getActionButtons = (appendToElement?: Element) => {
    const {
      leftAligned,
      initialValues,
      values,
      setFieldValue,
      daysAhead,
      openPersonalizationDialog,
      isPersonalizationDialogOpen,
      repeat,
      appendPopupTo,
      manageDialog
    } = this.props;

    let components: any[] = [];

    if (manageDialog) {
      components = [
        {
          icon: 'menu_book',
          tooltip: values[FormFieldName.READING_SOURCE] ? t.ADD_PAGE_RANGE : t.ADD_PAGE_RANGE_DISABLED,
          popup: {
            content: ManagePageRanges,
            placeholder: t.PAGE_RANGE,
            initialValues: { [FormFieldName.PAGE_RANGES]: values[FormFieldName.PAGE_RANGES] },
            field: FormFieldName.PAGE_RANGES,
            submit: value => setFieldValue(FormFieldName.PAGE_RANGES, value),
            info: t.ADD_PAGE_RANGE_INFO,
            appendTo: appendToElement || 'parent'
          },
          activeComponent: () =>
            values[FormFieldName.PAGE_RANGES] && values[FormFieldName.PAGE_RANGES].length > 0 ? (
              <ActiveComponent>
                <ManagePageRanges
                  pageRanges={values[FormFieldName.PAGE_RANGES]}
                  noInput
                  save={({ pageRanges }) => setFieldValue(FormFieldName.PAGE_RANGES, pageRanges)}
                  maxWidth={3}
                  small
                />
              </ActiveComponent>
            ) : null,
          disabled: !values[FormFieldName.READING_SOURCE]
        }
      ];
    } else {
      components = [
        {
          icon: 'menu_book',
          tooltip: values[FormFieldName.READING_SOURCE] ? t.ADD_PAGE_RANGE : t.ADD_PAGE_RANGE_DISABLED,
          popup: {
            content: ManagePageRanges,
            placeholder: t.PAGE_RANGE,
            contentProps: { minWidth: 150 },
            initialValues: { [FormFieldName.PAGE_RANGES]: values[FormFieldName.PAGE_RANGES] },
            field: FormFieldName.PAGE_RANGES,
            submit: value => {
              setFieldValue(FormFieldName.PAGE_RANGES, value);
              this.setEstimateForReadingSource(value, values[FormFieldName.READING_SOURCE]);
            },
            info: t.ADD_PAGE_RANGE_INFO,
            appendTo: appendToElement
          },
          activeComponent: () =>
            values[FormFieldName.PAGE_RANGES] && values[FormFieldName.PAGE_RANGES].length > 0 ? (
              <ActiveComponent>
                <ManagePageRanges
                  pageRanges={values[FormFieldName.PAGE_RANGES]}
                  noInput
                  save={({ pageRanges }) => {
                    setFieldValue(FormFieldName.PAGE_RANGES, pageRanges);
                    this.setEstimateForReadingSource(pageRanges, values[FormFieldName.READING_SOURCE]);
                  }}
                  maxWidth={3}
                  small
                />
              </ActiveComponent>
            ) : null,
          disabled: !values[FormFieldName.READING_SOURCE]
        },
        {
          icon: 'event',
          tooltip: t.SET_DUE_DATE,
          content: (
            <DueDate
              dueDate={values[FormFieldName.DUE_DATE]}
              calendarClassName={'workload-due-date'}
              save={({ dueDate }) => {
                if (!values[FormFieldName.DUE_DATE]) {
                  setFieldValue(FormFieldName.START_AHEAD, daysAhead);
                }
                setFieldValue(FormFieldName.DUE_DATE, dueDate);
              }}
              courseId={values[FormFieldName.COURSE]}
              onOpen={() => this.setState({ dueDateOpen: true })}
              onClose={() => this.setState({ dueDateOpen: false })}
              isOpen={this.state.dueDateOpen}
              hasValue={values[FormFieldName.DUE_DATE]}
              pickerPosition={leftAligned ? 'bottom-start' : 'bottom-end'}
              taskType={TaskType.SHOVEL}
              popperContainerId={appendPopupTo}
            />
          ),
          activeComponent: () => this.renderActiveComponent(FormFieldName.DUE_DATE),
          clear: () => {
            setFieldValue(FormFieldName.DUE_DATE, initialValues[FormFieldName.DUE_DATE]);
            setFieldValue(FormFieldName.START_AHEAD, initialValues[FormFieldName.START_AHEAD]);
          },
          clearTooltip: t.NO_DUE_DATE
        },
        {
          customIcon: <StartAheadIcon />,
          tooltip: values[FormFieldName.DUE_DATE] ? t.SET_START_AHEAD : undefined,
          content: (
            <StartAhead
              isCreateTask
              popperContainerId={appendPopupTo}
              calendarClassName={'workload-due-date'}
              dueDate={values[FormFieldName.DUE_DATE]}
              startAhead={values[FormFieldName.START_AHEAD]}
              save={({ daysToStartAhead }) => {
                setFieldValue(FormFieldName.START_AHEAD, daysToStartAhead);
              }}
              pickerPosition={leftAligned ? 'bottom-start' : 'bottom-end'}
              emptyComponent={<div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
              activeComponent={<div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
              openPersonalizationDialog={openPersonalizationDialog}
              isPersonalizationDialogOpen={isPersonalizationDialogOpen}
            />
          ),
          activeComponent: () => this.renderActiveComponent(FormFieldName.START_AHEAD),
          clear: () => setFieldValue(FormFieldName.START_AHEAD, initialValues[FormFieldName.START_AHEAD]),
          disabled: !values[FormFieldName.DUE_DATE]
        },
        {
          icon: 'hourglass_empty',
          tooltip: values[FormFieldName.READING_SOURCE]
            ? values[FormFieldName.PAGE_RANGES].length > 0
              ? t.ESTIMATE_TASK_DISABLED_FILLED
              : t.ESTIMATE_TASK_DISABLED
            : t.ESTIMATE_TASK,
          noMaterialClass: true,
          popup: {
            content: DurationInput,
            placeholder: t.TASK_ESTIMATE,
            position: 'top right',
            initialValues: { [FormFieldName.ESTIMATE]: values[FormFieldName.ESTIMATE] },
            field: FormFieldName.ESTIMATE,
            submit: (value, isPageBasedEst) => {
              setFieldValue(FormFieldName.ESTIMATE, value);
              setFieldValue(FormFieldName.AUTO_ESTIMATE, isPageBasedEst);
            },
            setField: true,
            info: t.TASK_ESTIMATE_INFO,
            appendTo: appendToElement
          },
          activeComponent: () => this.renderActiveComponent(FormFieldName.ESTIMATE),
          clear: values[FormFieldName.READING_SOURCE]
            ? undefined
            : () => setFieldValue(FormFieldName.ESTIMATE, initialValues[FormFieldName.ESTIMATE]),
          disabled: values[FormFieldName.READING_SOURCE]
        }
      ];
    }

    if (repeat) {
      components.push({
        icon: 'repeat',
        onClick: () => repeat.openCreatePatternDialog?.(values),
        tooltip: values[FormFieldName.DUE_DATE] ? t.REPEAT_TASK : t.CREATE_REPEAT_TASK_DISABLED,
        disabled: !values[FormFieldName.DUE_DATE],
        clear: () => repeat.clearRepeatOptions(),
        activeComponent: () => {
          if (repeat.isRepeatSet) {
            return <ActiveComponent>{t.DATES_SET}</ActiveComponent>;
          }
          return null;
        },
        clearTooltip: t.NO_REPEAT
      });
    }

    return components;
  };

  clear = fieldName => {
    const { setFieldValue, initialValues, manageDialog } = this.props;

    if (fieldName === FormFieldName.COURSE) {
      setFieldValue(FormFieldName.COURSE, initialValues[FormFieldName.COURSE]);
      // in manage dialog there is no form so we do not need to reset category and reading source
      if (!manageDialog) {
        setFieldValue(FormFieldName.CATEGORY, undefined);
        setFieldValue(FormFieldName.READING_SOURCE, undefined);
      }
    } else if (fieldName === FormFieldName.CATEGORY) {
      setFieldValue(FormFieldName.CATEGORY, initialValues[FormFieldName.CATEGORY]);
    } else {
      setFieldValue(FormFieldName.READING_SOURCE, initialValues[FormFieldName.READING_SOURCE]);
      setFieldValue(FormFieldName.AUTO_ESTIMATE, initialValues[FormFieldName.AUTO_ESTIMATE]);
      if (!manageDialog) {
        setFieldValue(FormFieldName.PAGE_RANGES, initialValues[FormFieldName.PAGE_RANGES]);
      }
      setFieldValue(FormFieldName.ESTIMATE, initialValues[FormFieldName.ESTIMATE]);
    }
  };

  renderCustomTarget = fieldName => {
    const { values, categories, readingSources, courses, activities, theme } = this.props;
    let icon = '';
    let text = '';
    let textColor = '';
    let outlinedIcon = false;
    let disabled = false;
    let tooltip = '';
    let emoji = '';
    let timePerPage = '';
    switch (fieldName) {
      case FormFieldName.COURSE: {
        if (values[fieldName]) {
          const course = [...courses, ...activities].find(course => course.id === values[fieldName]);
          text = course?.name || '';
          textColor = course?.colorHex || '';
        } else {
          icon = 'folder_open';
          tooltip = t.SELECT_COURSE_OR_ACTIVITY;
        }
        break;
      }
      case FormFieldName.CATEGORY: {
        if (values[fieldName]) {
          const category = categories.find(cat => cat.id === values[fieldName]);
          text = category?.name || '';
          emoji = category?.emoji || DEFAULT_TASK_CATEGORY_EMOJI;
        } else {
          icon = 'list';
          tooltip = values[FormFieldName.COURSE] && t.SELECT_CATEGORY;
        }
        disabled = !values[FormFieldName.COURSE];
        break;
      }
      default: {
        if (values[fieldName]) {
          const readingSource = readingSources.find(cat => cat.id === values[fieldName]);
          text = readingSource?.name || '';
          timePerPage = secondsToDurationInMinutes(readingSource?.secondsPerPage || 0);
        } else {
          icon = 'class';
          outlinedIcon = true;
          tooltip = values[FormFieldName.COURSE] && t.SELECT_READING_SOURCE;
        }
        disabled = !values[FormFieldName.COURSE];
      }
    }
    const color = getColor(disabled, !!text, theme);
    const target = (
      <CustomTargetButton btnSize={28} isActive={!!text} textColor={textColor} isDisabled={disabled} color={color}>
        {icon ? (
          <MaterialIcon name={icon} size={16} outline={outlinedIcon} />
        ) : (
          <TextOverflow color={textColor} strong bigger={!!timePerPage}>
            {emoji && <EmojiPreview emoji={emoji} />}
            <span className={'text-wrapper'}>{text}</span>
            {timePerPage && <span className={'time-per-page'}>{` (${timePerPage})`}</span>}
          </TextOverflow>
        )}
        {text && (
          <TippyTooltip
            target={
              <ClearIcon
                onClick={e => {
                  this.clear(fieldName);
                  e.stopPropagation();
                }}
              >
                <MaterialIcon name={'cancel'} size={14} />
              </ClearIcon>
            }
            content={t.CLEAR}
          />
        )}
      </CustomTargetButton>
    );
    if (tooltip) {
      return <TippyTooltip target={target} content={tooltip} />;
    }
    return target;
  };

  render() {
    const {
      values,
      setFieldValue,
      getReadingSourcesByCourse,
      getCategoriesByCourse,
      categories,
      readingSources,
      initialValues,
      courses,
      activities,
      className,
      appendPopupTo,
      examStyles,
      manageDialog,
      noAutoPopupExam
    } = this.props;

    const appendToElement: any = appendPopupTo ? document.getElementById(appendPopupTo) : undefined;

    return (
      <ActionButtons className={className}>
        {!manageDialog && (
          <ExamWrapper checked={values.exam} style={examStyles}>
            <CheckBox
              label={t.EXAM}
              checked={values.exam}
              onChange={() => {
                setFieldValue('exam', !values.exam);
                if (this.examRef.current && !noAutoPopupExam) {
                  this.examRef.current.click();
                }
              }}
            />
            <ActionButton
              buttonRef={this.examRef}
              icon={'timer'}
              size={'mini'}
              tooltip={!values.exam ? t.SET_DURATION_NOT_EXAM : t.SET_EXAM_DURATION}
              //@ts-ignore
              popup={{
                content: DurationInput,
                placeholder: `${t.EXAM} ${t.DURATION.toLowerCase()}`,
                position: 'top right',
                initialValues: { [FormFieldName.DURATION]: values[FormFieldName.DURATION] },
                field: FormFieldName.DURATION,
                submit: value => setFieldValue(FormFieldName.DURATION, value),
                setField: true,
                appendTo: appendToElement
              }}
              wrappedActiveComponent={() => this.renderActiveComponent(FormFieldName.DURATION)}
              clear={() => setFieldValue(FormFieldName.DURATION, initialValues[FormFieldName.DURATION])}
              disabled={!values.exam}
            />
          </ExamWrapper>
        )}
        <DropDownInputEditable
          initialValue={values[FormFieldName.COURSE]}
          options={mapCoursesAndActivitiesToDropdownOptions(courses, activities)}
          onChange={(id, value) => {
            // in manage dialog there is no form so we do not need to reset category and reading source
            if (values[FormFieldName.COURSE] !== id && !manageDialog) {
              setFieldValue(FormFieldName.CATEGORY, undefined);
              setFieldValue(FormFieldName.READING_SOURCE, undefined);
            }
            setFieldValue(FormFieldName.COURSE, id);
            getCategoriesByCourse(id);
            getReadingSourcesByCourse(id);
          }}
          customTarget={this.renderCustomTarget(FormFieldName.COURSE)}
          remove={() => this.clear(FormFieldName.COURSE)}
          small
          categorized
        />
        <TaskCategorySection
          categories={categories}
          onChange={value => setFieldValue(FormFieldName.CATEGORY, value)}
          courseId={values[FormFieldName.COURSE]}
          customTarget={<div id={'catPopup'}>{this.renderCustomTarget(FormFieldName.CATEGORY)}</div>}
          isManageDialog={manageDialog}
          small
        />
        <ReadingSourceSection
          readingSources={readingSources}
          customTarget={<div id={'rsPopup'}>{this.renderCustomTarget(FormFieldName.READING_SOURCE)}</div>}
          onChange={value => {
            setFieldValue(FormFieldName.READING_SOURCE, value);
            setFieldValue(FormFieldName.AUTO_ESTIMATE, true);
            if (!manageDialog) {
              if (values[FormFieldName.PAGE_RANGES]) {
                this.setEstimateForReadingSource(values[FormFieldName.PAGE_RANGES], value);
              } else {
                setFieldValue(FormFieldName.ESTIMATE, initialValues[FormFieldName.ESTIMATE]);
              }
            }
          }}
          disabled={!values[FormFieldName.COURSE]}
          remove={() => this.clear(FormFieldName.READING_SOURCE)}
          courseId={values[FormFieldName.COURSE]}
          updateTimePerPage={({ readingSourceId, secondsPerPage }) => {
            if (readingSourceId === values[FormFieldName.READING_SOURCE]) {
              let sum = 0;
              values[FormFieldName.PAGE_RANGES].forEach(
                page => (sum = page.from && page.to ? sum + (page.to - page.from + 1) : sum + page.pages)
              );
              const estimate = (secondsPerPage / 60) * sum;
              setFieldValue(FormFieldName.ESTIMATE, estimate);
            }
          }}
          isManageDialog={manageDialog}
          small
        />
        {this.getActionButtons(appendToElement).map((button, index) => {
          if (button.icon) {
            return (
              <ActionButton
                key={index}
                icon={button.icon}
                size={'mini'}
                //@ts-ignore
                onClick={button.onClick}
                tooltip={button.tooltip}
                content={button.content}
                //@ts-ignore
                noClassMaterialIcon={button.noMaterialClass}
                //@ts-ignore
                popup={button.popup}
                wrappedActiveComponent={button.activeComponent}
                clear={button.clear}
                clearTooltip={button.clearTooltip}
                disabled={button.disabled}
              />
            );
          }
          return (
            <ActionButton
              key={index}
              size={'mini'}
              //@ts-ignore
              onClick={button.onClick}
              tooltip={button.tooltip}
              //@ts-ignore
              popup={button.popup}
              content={button.content}
              wrappedActiveComponent={button.activeComponent}
              clear={button.clear}
              //@ts-ignore
              clearTooltip={button.clearTooltip}
              disabled={button.disabled}
            >
              {button.customIcon}
            </ActionButton>
          );
        })}
      </ActionButtons>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  categories: getCategories(state),
  readingSources: getReadingSources(state),
  daysAhead: getDefaultDaysAhead(state),
  isPersonalizationDialogOpen: getPersonalizationDialogVisibility(state)
});

const mapStateToDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCategoriesByCourse: courseActions.getCategoriesByCourse.request,
      getReadingSourcesByCourse: courseActions.getReadingSourcesByCourse.request,
      openPersonalizationDialog: openPersonalizationDialog
    },
    dispatch
  );

export default connect(mapStateToProps, mapStateToDispatch)(withTheme(AddTaskButtons));

const ActionButtons = styled(Row)`
  flex: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin-bottom: 10px;
  > div {
    margin-top: 3px;
  }
  > div:not(last-child) {
    margin-right: 5px;
  }
`;

const ActiveComponent = styled(SmallText)`
  box-sizing: border-box;
  color: ${props => props.theme.textStrongColor};
  margin-left: 5px;
`;

const CustomTargetButton = styled(ButtonWrapper)<{ textColor?: any }>`
  ${props => props.isActive && `padding: 0 10px;`};
  &:hover {
    span {
      color: ${props => props.textColor || props.theme.textColor} !important;
    }
  }
`;

const TextOverflow = styled(SmallText)<{ bigger?: boolean }>`
  display: flex;
  align-items: center;
  .emoji-mart-emoji {
    margin-right: 3px;
    display: flex;
  }
  .time-per-page {
    color: ${props => props.theme.textColor};
    white-space: pre;
    flex-shrink: 0;
  }
  .text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
  max-width: ${props => (props.bigger ? 130 : 100)}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DueDate = styled(ManageDueDate)<{ hasValue?: boolean }>`
  position: absolute;
  width: 100%;
  input {
    padding: 0 !important;
    padding-left: ${props => (props.hasValue ? 40 : 28)}px !important;
    font-size: 11px !important;
  }
  .react-datepicker__close-icon {
    display: none;
  }
`;

const ExamWrapper = styled(Row)<{ checked?: boolean }>`
  border-radius: 5px;
  background-color: ${props => (props.checked ? `${colors.primaryPurple}1A` : props.theme.backgroundDark)};
  > *:first-child {
    margin-right: 15px;
  }
`;
