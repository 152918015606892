import React, { PureComponent } from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { RootState } from '@state/types';
import { getActionErrors, getActionLoaders } from '../../../state/common/selectors';
import { connect } from 'react-redux';
import styled from 'styled-components';
import colors from '../../../utils/colors';
import Button from './Button';
import { extractActionNameWithoutSuffix } from '../../../state/common/utils';
import { AUTOSAVE_INDICATOR_TIMEOUT } from '../../../utils/taskUtils';

type OwnProps = {
  action?: any;
  color?: string;
  textColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  filled?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  withoutBorder?: boolean;
  text: string | any;
  callback?: Function;
  type?: 'button' | 'submit' | 'reset';
  minWidth?: number;
  style?: any;
  buttonRef?: any;
};

type PropsFromState = {
  loading?: boolean;
  error?: string | boolean;
};

type Props = OwnProps & PropsFromState;
type State = { show: boolean; clicked: boolean };

class ButtonWithLoader extends PureComponent<Props, State> {
  readonly state = { show: false, clicked: false };
  timer: any = undefined;

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { loading, callback, error } = this.props;
    const { clicked } = this.state;

    if (prevProps.loading && !loading && clicked) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.setState({ show: true });
      this.timer = setTimeout(() => {
        this.setState({ show: false, clicked: false });
        if (callback && clicked && !error) {
          callback();
        }
      }, AUTOSAVE_INDICATOR_TIMEOUT);
    }
  }

  render() {
    const {
      loading,
      error,
      textColor,
      color = colors.primaryPurple,
      onClick = () => {},
      className,
      disabled = false,
      filled = false,
      size = 'md',
      text,
      style,
      withoutBorder,
      type = 'button',
      minWidth = 76,
      buttonRef
    } = this.props;

    const { show, clicked } = this.state;

    if (!loading) {
      const buttonProps = error
        ? {
            color: !filled ? colors.lightNegative : colors.negative,
            icon: 'clear',
            iconColor: !filled ? colors.negative : colors.white
          }
        : {
            color: !filled ? colors.primaryLight : color,
            icon: 'done',
            iconColor: !filled ? colors.primaryPurple : `${colors.white} !important`
          };
      return (
        <StyledButton
          style={style}
          buttonRef={buttonRef}
          textColor={textColor}
          filled={show || filled}
          icon={show && clicked ? buttonProps.icon : undefined}
          color={show && clicked ? buttonProps.color : color}
          className={className}
          withoutBorder={withoutBorder}
          size={size}
          onClick={() => {
            if (show) return;
            this.setState({ clicked: true });
            onClick();
          }}
          disabled={show ? false : disabled}
          type={type}
          iconColor={buttonProps.iconColor}
        >
          {!show && text}
        </StyledButton>
      );
    }

    const loaderSize = getLoaderSize(size);
    return (
      <LoaderWrapper className={`${className || ''} button-loader-wrapper`} size={loaderSize} width={minWidth}>
        <Loader type="Oval" color={colors.primaryPurple} height={22} width={22} />
      </LoaderWrapper>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  if (!props.action) return {};

  const actionName = extractActionNameWithoutSuffix(props.action.request);
  const loaders = getActionLoaders(state);
  const errors = getActionErrors(state);
  return {
    loading: loaders[actionName],
    error: errors[actionName]
  };
};

export default connect(mapStateToProps, null)(ButtonWithLoader);

const StyledButton = styled(Button)`
  padding: ${props => (props.size === 'sm' ? '6px 10px' : props.size === 'md' ? '8px 15px' : '15px 30px')} !important;
`;

const LoaderWrapper = styled.div<{ size: number; width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => props.width}px;

  > div {
    height: ${props => `${props.size}px`};
    display: flex;
    align-items: center;
  }
`;

const buttonVerticalPadding = new Map([
  ['sm', 12],
  ['md', 16],
  ['lg', 30]
]);

const getLoaderSize = (buttonSize: string) => {
  const fontSize = 13;
  const lineHeight = buttonSize === 'sm' ? 1 : 1.5;
  //@ts-ignore
  return buttonVerticalPadding.get(buttonSize) + Math.floor(fontSize * lineHeight) + 2; // 2 is for border
};
