import React, { PureComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { DayOfWeek, PersonalizationSettings } from 'shovel-lib/types';

import ModalDialog, { DialogActions } from '../../common/ModalDialog';
import { RootState } from '@state/types';
import { getPersonalizationDialogInfo } from '@state/settings/selectors';
import { closePersonalizationDialog, saveUserSettings } from '@state/settings/actions';
import t from '@i18n/t';
import PersonalizationContent from './PersonalizationContent';
import Button from '@components/common/buttons/Button';
import { PERSONALIZATION_Z_INDEX } from './constants';

type PropsFromState = {
  dialog: {
    open: boolean;
    info: any;
    callback?: any;
  };
};

type PropsFromDispatch = {
  close: typeof closePersonalizationDialog;
  saveUserSettings: typeof saveUserSettings.request;
};

type Props = PropsFromState & PropsFromDispatch;

class PersonalizationDialog extends PureComponent<Props> {
  render() {
    const {
      dialog: { open, info, callback },
      close,
      saveUserSettings
    } = this.props;
    if (!info) return null;
    return (
      <ModalDialog
        open={open}
        onClose={close}
        size={'lg'}
        title={t.PERSONALIZATION}
        contentStyle={{ height: '90%' }}
        // zIndex added to dialog be over datepicker tippy
        zIndex={PERSONALIZATION_Z_INDEX}
        fullWidth
      >
        <Formik
          initialValues={info}
          enableReinitialize={true}
          onSubmit={values => {
            saveUserSettings(values);
            callback?.(values);
            close();
          }}
        >
          {({ values, setFieldValue, handleSubmit, dirty }: FormikProps<PersonalizationSettings>) => {
            const dayOfWeekInDropdown =
              values.firstDayOfWeek !== DayOfWeek.SUNDAY && values.firstDayOfWeek !== DayOfWeek.MONDAY;
            return (
              <>
                <PersonalizationContent
                  values={values}
                  setFieldValue={setFieldValue}
                  dayOfWeekInDropdown={dayOfWeekInDropdown}
                  isDialog
                />
                <DialogActions position={'flex-end'}>
                  <Button withoutBorder onClick={close}>
                    {t.CANCEL}
                  </Button>
                  <Button filled onClick={handleSubmit} disabled={!dirty}>
                    {t.SAVE}
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  dialog: getPersonalizationDialogInfo(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: closePersonalizationDialog,
      saveUserSettings: saveUserSettings.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizationDialog);
