import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Tooltip from '../../tooltip/Tooltip';
import { Row } from '../../layoutUtils';
import MaterialIcon from '../../icons/MaterialIcon';
import colors from '@utils/colors';
import { cancelBadgesObviousMike, openStepDialog } from '@state/settings/actions';
import { allSteps } from '../steps';
import t from '@i18n/t';

type Props = {
  stepName: string;
  completed: boolean;
  active: boolean;
  badgesObviousMike: boolean;
  openStepDialog: typeof openStepDialog;
  cancelBadgesObviousMike: typeof cancelBadgesObviousMike;
};

type State = {
  pulsing: boolean;
};

class StepIcon extends PureComponent<Props, State> {
  readonly state = { pulsing: false };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!prevProps.completed && this.props.completed) {
      this.setState({ pulsing: true });
      setTimeout(() => this.setState({ pulsing: false }), 5000);
    }
  }

  renderCompletedStep = () => (
    <CompletedContainer pulsing={this.state.pulsing}>
      <CompletedStep name={'check_circle_outline'} onClick={() => this.props.openStepDialog(this.props.stepName)} />
    </CompletedContainer>
  );

  renderIncompleteStep = step => {
    const { stepName, active, badgesObviousMike, cancelBadgesObviousMike, openStepDialog } = this.props;
    const obviousMike = badgesObviousMike && step.number === '1';

    return (
      <div style={{ position: 'relative' }}>
        <Step
          active={active}
          onClick={() => {
            obviousMike && cancelBadgesObviousMike();
            openStepDialog(stepName);
          }}
        />
        {obviousMike && <ObviousMike>👇</ObviousMike>}
      </div>
    );
  };

  render() {
    const { stepName, completed } = this.props;
    const step = allSteps[stepName];
    return (
      <Tooltip
        key={stepName}
        target={completed ? this.renderCompletedStep() : this.renderIncompleteStep(step)}
        content={`${t.CLICK_TO_VIEW_STEP}${step.number}`}
        position={'top center'}
      />
    );
  }
}

export default StepIcon;

const Step = styled.div<{ active?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => (props.active ? props.theme.onBoardingStepActiveColor : props.theme.onBoardingStepColor)};
  &:hover {
    background: ${props => (props.active ? props.theme.onBoardingStepColor : props.theme.onBoardingStepActiveColor)};
  }
  margin: 0 6px;
  cursor: pointer;
`;

const CompletedStep = styled(MaterialIcon)`
  color: ${colors.positive};
  font-size: 18px;
  margin: 0 3px;
  cursor: pointer;
  z-index: 1;
`;

const CompletedContainer = styled(Row)<{ pulsing: boolean }>`
  position: relative;
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    20% {
      opacity: 1;
    }
    80%,
    100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }

  ${CompletedStep} {
    ${props => props.pulsing && 'animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;'}
  }

  ${props =>
    props.pulsing &&
    `
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: ${colors.positive};
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      margin: 0 5px;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: ${props.theme.background};
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
  `}
`;

const ObviousMike = styled.span`
  position: absolute;
  top: -40px;
  left: -6px;
  font-size: 30px;
  animation: obviousMike 1s infinite;

  @keyframes obviousMike {
    0% {
      top: -35px;
    }
    50% {
      top: -40px;
    }
    100% {
      top: -35px;
    }
  }
`;
