import { opacityCode } from './constants/opacity';

export const increaseColorBrightness = (hex: string, percent: number = 80) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  // return `rgba(${r}, ${g}, ${b}, 0.3)`;

  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
};

export const darkenColor = (color: string, percent: number = -60) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const getOpacityCode = (opacity: string) => {
  return opacityCode.get(opacity);
};

export const pickerColors = [
  '#F47373',
  '#F06292',
  '#F06DE7',
  '#9575CD',
  '#7986CB',
  '#9ABFE7',
  '#6ECBF4',
  '#54D1E1',
  '#4DB6AC',
  '#81C784',
  '#AED581',
  '#DCE775',
  '#FFD54F',
  '#FFB74D',
  '#FF8A65',
  '#D27979',
  '#A1887F',
  '#90A4AE',

  '#F44336',
  '#E91E63',
  '#F32EE5',
  '#9052FF',
  '#5E76FF',
  '#5E8FFF',
  '#2FB8F7',
  '#00C6DF',
  '#02CCB9',
  '#00C708',
  '#76DB00',
  '#CDDE21',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#FF5A5A',
  '#795548',
  '#607D8B',

  '#D32F2F',
  '#C2185B',
  '#B54BAD',
  '#512DA8',
  '#303F9F',
  '#3976FF',
  '#0288D1',
  '#0097A7',
  '#00796B',
  '#388E3C',
  '#689F38',
  '#AFB42B',
  '#FFA000',
  '#F57C00',
  '#E64A19',
  '#BF4040',
  '#5D4037',
  '#455A64'
];

export const editorTextColorPalette = ['#3F4E61', '#798799', '#C4CDD8', '#E4E8EC', '#F4F5F6', '#FFFFFF'];

export const editorBgColorPalette = ['#20272D', '#798799', '#C4CDD8', '#E4E8EC', '#F4F5F6', '#FFFFFF'];

export const getRandomPickerColor = () => pickerColors[Math.floor(Math.random() * pickerColors.length)];
