import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { callApi } from '../common/operations';
import { getErrorMessage } from '../../api/network';
import { alert, NotificationMode } from '@components/common/snackbarNotifications/Notifications';
import t from '../../i18n/t';
import { googleCalendarApi } from '../../api/googleCalendarApi';
import storage from '../../utils/storage';
import { googleAuth2SignOut } from '../../components/common/googleCalendar/useGoogleApi';
import { reconnectGoogleCalendar } from '../../components/common/googleCalendar/utils';
import { calendarActions } from '../calendar';
import * as googleCalendarSelectors from './selectors';
import { getType } from 'typesafe-actions';
import { getGoogleCalendars } from '../calendar/selectors';
import { openConfirmationDialog } from '../../state/common/actions';

function* getGoogleAccess(action: ReturnType<typeof actions.getGoogleAccess.request>) {
  const userId = yield call(storage.getUserId);
  const apiCall = call(googleCalendarApi.authorize, action.payload.code, Number(userId));
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getGoogleAccess.success());
    // fetch google calendars
    yield put(actions.getSavedCalendars.request());
    if (action.payload.reconnect) {
      yield call(refreshGoogleEventsAndTasks);
    }
  } else {
    yield put(actions.getGoogleAccess.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* getSavedCalendars() {
  const apiCall = call(googleCalendarApi.getSavedCalendars);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getSavedCalendars.success(response.data));
    const hasGoogleEvents = yield select(googleCalendarSelectors.hasGoogleEvents);
    if (!hasGoogleEvents) {
      yield call(refreshGoogleEventsAndTasks);
    }
  } else {
    yield put(actions.getSavedCalendars.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* toggleGoogleCalendar(action: ReturnType<typeof actions.toggleGoogleCalendar.request>) {
  const apiCall = call(googleCalendarApi.toggleGoogleCalendar, action.payload);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield call(refreshGoogleEventsAndTasks);
    const googleCalendars = yield select(getGoogleCalendars);
    const isUnselect = googleCalendars.find(gc => gc.id === action.payload)?.selected;
    if (isUnselect) {
      alert(t.UNSELECT_GOOGLE_CALENDAR_NOTIFICATION, NotificationMode.SUCCESS);
    } else {
      alert(t.SELECT_GOOGLE_CALENDAR_NOTIFICATION, NotificationMode.SUCCESS);
    }
    yield put(actions.toggleGoogleCalendar.success(action.payload));
  } else {
    yield put(actions.toggleGoogleCalendar.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* refreshGoogleCalendars() {
  const apiCall = call(googleCalendarApi.refreshGoogleCalendars);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield call(refreshGoogleEventsAndTasks);
    yield put(actions.refreshGoogleCalendars.success(response.data));
  } else {
    yield put(actions.refreshGoogleCalendars.failure(getErrorMessage(response)));
    if (response.data.message.includes('invalid_grant')) {
      yield put(
        openConfirmationDialog({
          renderCustomOkButton: () => reconnectGoogleCalendar(),
          title: t.GOOGLE_CAL_ERROR,
          message: t.GOOGLE_CAL_ERROR_MSG,
          warning: true,
          okLabel: t.RECONNECT
        })
      );
    } else {
      alert(t.SOMETHING_WENT_WRONG);
    }
  }
}

function* unsyncGoogleCalendar() {
  const apiCall = call(googleCalendarApi.unsyncGoogleCalendar);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield call(refreshGoogleEventsAndTasks);
    yield put(actions.unsyncGoogleCalendar.success());
    yield call(googleAuth2SignOut);
  } else {
    yield put(actions.unsyncGoogleCalendar.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

function* getGoogleEvents() {
  const apiCall = call(googleCalendarApi.getGoogleEvents);
  const { ok, ...response } = yield call(callApi, apiCall);
  if (ok) {
    yield put(actions.getGoogleEvents.success(response.data));
  } else {
    yield put(actions.getGoogleEvents.failure(getErrorMessage(response)));
    alert(t[getErrorMessage(response)]);
  }
}

export function* refreshGoogleEventsAndTasks() {
  // note: calls must go in this order
  yield put(actions.getGoogleEvents.request());
  // wait get google events to finish so tasks are synced
  yield take(getType(actions.getGoogleEvents.success));
  yield put(calendarActions.calendarData.request());
}

export default function*() {
  yield all([
    takeLatest(actions.getGoogleAccess.request, getGoogleAccess),
    takeLatest(actions.getSavedCalendars.request, getSavedCalendars),
    takeLatest(actions.toggleGoogleCalendar.request, toggleGoogleCalendar),
    takeLatest(actions.refreshGoogleCalendars.request, refreshGoogleCalendars),
    takeLatest(actions.unsyncGoogleCalendar.request, unsyncGoogleCalendar),
    takeLatest(actions.getGoogleEvents.request, getGoogleEvents)
  ]);
}
