import React, { FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import colors from '../../../utils/colors';
import { notificationActions } from '../../../state/notifications';
import MaterialIcon from '../../common/icons/MaterialIcon';
import { MediumText, MiniText } from '../../../utils/typography';
import { Column, Row, textOverflowStyle } from '../../common/layoutUtils';
import TextLink from '../../common/TextLink';
import t from '../../../i18n/t';
import * as routes from '../../../utils/constants/routes';
import { now, toMomentDate } from 'shovel-lib/utils/timeUtils';
import { NotificationState } from '../../../state/notifications/types';
import { RootState } from '@state/types';
import { getNotificationState } from '../../../state/notifications/selectors';
import { NotificationT } from 'shovel-lib/types';
import Tooltip from '../../common/tooltip/Tooltip';

type Props = RouteComponentProps<{}> & {
  history: any;
  theme: any;
} & PropsFromState &
  PropsFromDispatch;

type PropsFromState = NotificationState;

type PropsFromDispatch = {
  getNotifications: typeof notificationActions.getNotifications.request;
  clearNotifications: typeof notificationActions.clearNotifications.request;
  countUnseenNotifications: typeof notificationActions.countUnseenNotifications.request;
  openNotification: typeof notificationActions.openNotification;
};

const MAX_ICONS_PREVIEW = 6;

const NotificationsMenu: FC<Props> = ({
  history,
  theme,
  notifications,
  unseenCount,
  getNotifications,
  clearNotifications,
  countUnseenNotifications,
  openNotification
}) => {
  useEffect(() => {
    countUnseenNotifications();
  }, []);

  const isNotificationsRoute = history.location.pathname.startsWith(routes.NOTIFICATIONS);

  return (
    <Tooltip
      target={
        <Toggler
          onClick={() => history.push(routes.NOTIFICATIONS)}
          backgroundColor={isNotificationsRoute ? colors.primaryPurple : 'transparent'}
          isActive={isNotificationsRoute}
        >
          <MaterialIcon
            name={'notifications_none'}
            size={20}
            color={isNotificationsRoute ? colors.white : theme.textColor}
            outline
          />
          {unseenCount > 0 && <NotificationsBadge>{unseenCount}</NotificationsBadge>}
        </Toggler>
      }
      position={'right center'}
      content={t.NOTIFICATIONS}
      width={'max-content'}
    />
  );

  // return (
  //   <Popup
  //     trigger={open => (
  //       <div>
  //         <Tooltip
  //           target={
  //             <Toggler backgroundColor={isNotificationsRoute ? colors.primaryPurple : 'transparent'}>
  //               <MaterialIcon
  //                 name={'notifications_none'}
  //                 size={20}
  //                 color={isNotificationsRoute ? theme.backgroundLight : open ? theme.textStrongColor : theme.textColor}
  //               />
  //               {unseenCount > 0 && <NotificationsBadge>{unseenCount}</NotificationsBadge>}
  //             </Toggler>
  //           }
  //           width={'max-content'}
  //           content={t.NOTIFICATIONS_TOOLTIP}
  //           position={'right center'}
  //         />
  //       </div>
  //     )}
  //     arrow={false}
  //     position="right top"
  //     on="click"
  //     closeOnDocumentClick
  //     contentStyle={notificationsDropdownStyle}
  //   >
  //     {close => (
  //       <NotificationsPopup
  //         notifications={notifications}
  //         getNotifications={getNotifications}
  //         clearNotifications={clearNotifications}
  //         openNotification={openNotification}
  //         close={close}
  //         seeAll={() => history.push(routes.NOTIFICATIONS)}
  //       />
  //     )}
  //   </Popup>
  // );
};

type PopupProps = {
  close: Function;
  seeAll: Function;
  notifications: NotificationT[];
  getNotifications: typeof notificationActions.getNotifications.request;
  clearNotifications: typeof notificationActions.clearNotifications.request;
  openNotification: typeof notificationActions.openNotification;
};

const NotificationsPopup = ({
  close,
  seeAll,
  notifications,
  getNotifications,
  clearNotifications,
  openNotification
}: PopupProps) => {
  useEffect(() => {
    getNotifications();
  }, []);

  const currentTime = now();

  return (
    <Container>
      <Header>
        <MediumText>{t.NOTIFICATIONS.toUpperCase()}</MediumText>
        <TextLink
          onClick={() => {
            clearNotifications();
          }}
        >
          {t.CLEAR_ALL}
        </TextLink>
      </Header>
      {notifications.length === 0 ? (
        <EmptyMessage>
          <MediumText dangerouslySetInnerHTML={{ __html: t.NOTIFICATION_EMPTY_MESSAGE }} />
        </EmptyMessage>
      ) : (
        <Content>
          {notifications.slice(0, MAX_ICONS_PREVIEW).map(notification => (
            <Notification
              key={notification.id}
              seen={notification.seen}
              onClick={() => {
                openNotification(notification);
                close();
              }}
            >
              <NotificationTitle strong>{notification.title}</NotificationTitle>
              <NotificationBody strong>{notification.body}</NotificationBody>
              <MiniText>{toMomentDate(notification.timestamp).from(currentTime)}</MiniText>
            </Notification>
          ))}
        </Content>
      )}
      <Footer>
        <TextLink
          onClick={() => {
            seeAll();
            close();
          }}
        >
          {t.SEE_ALL_NOTIFICATIONS}
        </TextLink>
      </Footer>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => getNotificationState(state);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getNotifications: notificationActions.getNotifications.request,
      clearNotifications: notificationActions.clearNotifications.request,
      countUnseenNotifications: notificationActions.countUnseenNotifications.request,
      openNotification: notificationActions.openNotification
    },
    dispatch
  );

// @ts-ignore
export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu)));

const Toggler = styled.div<{ backgroundColor: string; isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  padding: 6px;
  border-radius: 5px;
  margin: 10px 0px;
  background-color: ${props => props.backgroundColor};
  &:hover {
    background: ${props => props.theme.backgroundDark};
    > i {
      color: ${props => !props.isActive && props.theme.primary};
    }
  }
`;

const NotificationsBadge = styled(MiniText)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 18px;
  min-width: 18px;
  font-size: 11px;
  border-radius: 50%;
  color: ${colors.white};
  background-color: ${colors.negative};
  position: absolute;
  top: -7px;
  right: -7px;
`;

const Container = styled(Column)`
  border-radius: 5px;
  z-index: 100;
  background-color: ${({ theme }) => theme.background};
  flex: 1;
  min-width: 0;
`;

const Content = styled(Column)`
  flex: 1;
`;

const EmptyMessage = styled(Column)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 50px;
  text-align: center;
`;

const Notification = styled(Column)<{ seen: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${({ seen, theme }) => (!seen ? theme.backgroundDark : theme.background)};
  overflow: hidden;
`;

const NotificationTitle = styled(MediumText)`
  ${textOverflowStyle}
`;

const NotificationBody = styled(MediumText)`
  ${textOverflowStyle}
`;

const Header = styled(Row)`
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  padding: 10px 20px;
`;

const Footer = styled(Row)`
  justify-content: center;
  padding: 10px 20px;
`;
